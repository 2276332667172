import { ReactElement, useState, useRef, useEffect } from 'react';
import { Box, Typography, TypographyProps, styled, useTheme } from '@vouch/ui';
import { toggleClosed, toggleOpen } from './style';

export type ToggleableTextProps = TypographyProps & {
  truncatedLines: number;
};

function isNumericString(str: string): boolean {
  return !isNaN(Number(str)) && !isNaN(Number.parseFloat(str));
}

function hasValidUnits(str: string): boolean {
  const validSuffixes: string[] = ['px', 'rem'];
  let hasValidSuffix = false;
  let suffixLength = 0;
  validSuffixes.forEach((suffix) => {
    if (str.slice(-suffix.length) === suffix) {
      hasValidSuffix = true;
      suffixLength = suffix.length;
    }
  });

  return hasValidSuffix && !isNaN(Number(str.slice(0, -suffixLength)));
}

function isValidFormat(lineHeight: number | string): boolean {
  return (
    typeof lineHeight === 'number' ||
    (typeof lineHeight === 'string' && (isNumericString(lineHeight) || hasValidUnits(lineHeight)))
  );
}

function addUnitsIfNone(lineHeight: number | string, fontSize: string): string {
  if (
    typeof lineHeight === 'number' ||
    (typeof lineHeight === 'string' && isNumericString(lineHeight))
  ) {
    return `${fontSize} * ${lineHeight}`;
  }

  return lineHeight;
}

export const ToggleableText = (props: ToggleableTextProps): ReactElement => {
  const theme = useTheme();
  let lineHeight = theme.typography['body1'].lineHeight!;
  if (!isValidFormat(lineHeight)) {
    // ToggleableText only supports line height values specified as numbers, px values, or rem values. Falling back to 1.5rem default...
    lineHeight = '1.5rem';
  }
  lineHeight = addUnitsIfNone(lineHeight, theme.typography['body1'].fontSize as string);

  const truncatedHeight = `calc(${lineHeight} * ${props.truncatedLines})`;

  const [truncateDescription, setTruncate] = useState(true);
  const Toggleable = styled(Typography)({
    lineHeight: `${lineHeight}`,
    maxHeight: `${truncateDescription ? `${truncatedHeight}` : 'none'}`,
    overflow: 'hidden',
  });

  const [fitsWithNoTruncate, setFitsWithNoTruncate] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const text = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const textContainer = useRef<any>(null);
  useEffect(() => {
    if (textContainer.current !== null && text.current !== null) {
      const maxHeight = Number.parseFloat(window.getComputedStyle(textContainer.current).maxHeight);
      const textHeight = Number(text.current.offsetHeight);
      if (textHeight <= maxHeight) {
        setFitsWithNoTruncate(true);
      }
    }
  }, []);

  if (fitsWithNoTruncate) {
    return (
      <Typography variant="body1" ref={textContainer}>
        <span ref={text}>{props.children}</span>
      </Typography>
    );
  } else {
    return (
      <Box position={'relative'}>
        <Toggleable variant="body1" ref={textContainer}>
          <span ref={text}>{props.children}</span>{' '}
          {!truncateDescription && (
            <Typography
              sx={toggleClosed}
              variant="body1"
              component="span"
              onClick={() => setTruncate(true)}
            >
              Show Less
            </Typography>
          )}
        </Toggleable>
        {truncateDescription && (
          <Typography
            sx={toggleOpen}
            variant="body1"
            component="span"
            onClick={() => setTruncate(false)}
          >
            ...Show More
          </Typography>
        )}
      </Box>
    );
  }
};
