import { ReactElement } from 'react';
import { AppBar, Container } from '@vouch/ui';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { packageOrder } from 'features/packages/packagesSlice';
import { applicationReferralPartner, getSessionInfo } from 'features/session/sessionSlice';
import BannerComponent from 'components/molecules/banners';
import { BLOCKED_PATH } from 'shared/services/router';

import { getBannerType, shouldDisplayBanner } from 'components/organisms/header/helpers';
import { Outlet } from 'react-router-dom';

const Header = (): ReactElement => {
  const location = useLocation();
  const onBlocked = location.pathname === BLOCKED_PATH;
  const packagesOrder = useSelector(packageOrder);
  const referralPartner = useSelector(applicationReferralPartner);
  const { market } = useSelector(getSessionInfo);
  const bannerType = getBannerType({
    referralPartner,
    firstPackage: packagesOrder[0],
  });
  const displayBanner = shouldDisplayBanner({
    bannerType,
    market,
  });

  const banner =
    (bannerType && displayBanner && <BannerComponent bannerType={bannerType} />) || undefined;

  if (onBlocked) {
    return (
      <Container
        sx={(theme) => ({ backgroundColor: theme.designTokens.colorWhite, minWidth: '100vw' })}
      >
        <AppBar sx={(theme) => ({ backgroundColor: theme.designTokens.colorWhite })} />
        {banner}
        <Outlet />
      </Container>
    );
  }

  return (
    <>
      <AppBar position={'relative'} />
      {banner}
      <Outlet />
    </>
  );
};

export default Header;
