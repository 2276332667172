import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '2910a301-efbc-4a9e-a01b-43b87a6e4adf',
  clientToken: import.meta.env.VITE_FIREBIRD_DD_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'firebird',
  env: import.meta.env.VITE_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  sessionReplaySampleRate: import.meta.env.VITE_ENV === 'prod' ? 100 : 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();
