import { FC, MouseEventHandler, ReactElement } from 'react';
import { Box, Grid, Typography, PrimaryButton, keyframes } from '@vouch/ui';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { applicationReferralPartner } from 'features/session/sessionSlice';
import { packageOrder } from 'features/packages/packagesSlice';
import { getBannerType } from 'components/organisms/header/helpers';
import { BannerConfig } from 'shared/ui/tokenNames';
import { InfoTooltip, TaxTooltip } from 'components/atoms/tooltip';
import { QuotePricing } from 'features/quotes/quotes.types';
import { formatCents, getTotalPremiumWithTaxesandFees } from 'shared/helpers';
import { partner, totalNumbers } from './style';
import { TransactionFeeDisclosure } from 'components/atoms/buttons/text/transactionFeeDisclosure';
import { space } from 'shared/styles/Basegrid';
import VouchLoadingSm from 'components/atoms/icons/VouchLoadingSm';
import { hasAcceptance } from 'features/coverages/coveragesSlice';

type PricingProps = {
  handleCheckout: MouseEventHandler;
  hasChanges: boolean;
  pricing: QuotePricing;
  chargeTransactionFees?: boolean;
  requestReprice: () => void;
  loading: boolean;
};

const PricingSection: FC<PricingProps> = (props: PricingProps): ReactElement => {
  const { pricingSummaryPartnerDiscount } = useFlags();
  const referralPartner = useSelector(applicationReferralPartner);
  const packagesOrder = useSelector(packageOrder);
  const hasAcceptedCoverages = useSelector(hasAcceptance);
  const bannerType = getBannerType({
    referralPartner,
    firstPackage: packagesOrder[0],
  });
  const { handleCheckout, hasChanges, pricing, chargeTransactionFees, requestReprice, loading } =
    props;

  const totalWithTaxesandFees = getTotalPremiumWithTaxesandFees(pricing);
  const { annual, monthly } = pricing;
  const { adjustments, discounts, fees, partnerDiscount, taxes } = annual;
  const annualTotal = annual.totalPremiumCents;
  const subtotal = annualTotal - discounts - adjustments;
  let monthlyTotalWithTaxesFees;
  if (monthly) {
    monthlyTotalWithTaxesFees = monthly.duePerTimeUnit + monthly.taxes + monthly.fees;
  }

  const showPartnerText = referralPartner && partnerDiscount < 0 && bannerType;
  const useOrangeFont = !referralPartner || partnerDiscount <= 0 ? 'partner' : null;

  const formatPriceDisplay = (amount: number): string => {
    if (hasChanges) return '...';
    return formatCents(amount);
  };

  const lineItems = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    whiteSpace: 'nowrap',
    '&.total-numbers': {
      marginLeft: space.Xsmall,
    },
    '& h6': {
      alignItems: 'center',
      display: 'flex',
      height: '30px',
      vouchTooltip: {
        marginRight: '-4px',
      },
    },
  };

  return (
    <Grid item>
      {partnerDiscount < 0 && (
        <div className="partner-offer-notification">
          {bannerType ? referralPartner : 'PARTNER'} OFFER APPLIED
        </div>
      )}
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: space.Xsmall,
          }}
        >
          <Box
            sx={{
              ...lineItems,
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="subtitle2" fontWeight="light">
              Subtotal:
            </Typography>
            <Typography variant="subtitle2" fontWeight="light" whiteSpace="normal">
              Taxes & Other Fees:
              {!hasChanges && <TaxTooltip taxes={taxes} fees={fees} />}
            </Typography>
            <Typography variant="subtitle2" fontWeight="light" whiteSpace="normal">
              Adjustments:
              <InfoTooltip content='Includes rounding to state required "minimum premiums" for Vouch policies and underwriter adjustments.' />
            </Typography>
            {pricingSummaryPartnerDiscount && showPartnerText ? (
              <>
                <Typography variant="subtitle2" fontWeight="bold" className={`${useOrangeFont}`}>
                  Vouch Savings:
                </Typography>
                <Typography
                  variant="subtitle2"
                  whiteSpace="normal"
                  fontWeight="bold"
                  className="partner"
                >
                  {BannerConfig[bannerType].name} Offer:
                  <InfoTooltip content={BannerConfig[bannerType].tooltip} />
                </Typography>
              </>
            ) : (
              <Typography variant="subtitle2" fontWeight="bold">
                Total Savings:
              </Typography>
            )}
          </Box>
          <Box className="total-numbers" sx={lineItems}>
            <Typography variant="subtitle2" fontWeight="light">
              {formatPriceDisplay(subtotal)}
            </Typography>
            <Typography variant="subtitle2" fontWeight="light">
              {formatPriceDisplay(taxes + fees)}
            </Typography>
            <Typography variant="subtitle2" fontWeight="light">
              {formatPriceDisplay(adjustments)}
            </Typography>
            {pricingSummaryPartnerDiscount && showPartnerText ? (
              <>
                <Typography variant="subtitle2" fontWeight="bold" className={`${useOrangeFont}`}>
                  {formatPriceDisplay(discounts - partnerDiscount)}
                </Typography>
                <Typography variant="subtitle2" fontWeight="bold" sx={partner}>
                  {formatPriceDisplay(partnerDiscount)}
                </Typography>
              </>
            ) : (
              <Typography variant="subtitle2" fontWeight="bold">
                {formatPriceDisplay(discounts)}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={totalNumbers}>
          <Typography variant="h4">{formatPriceDisplay(totalWithTaxesandFees)}</Typography>
          <Typography variant="subtitle2" fontWeight="normal">
            PER YEAR
          </Typography>
          {monthlyTotalWithTaxesFees && (
            <Typography variant="subtitle2" fontWeight="light" data-testid="monthly-price">
              {formatPriceDisplay(monthlyTotalWithTaxesFees)} per month
              <br />
              (Save more by paying annually)
            </Typography>
          )}
        </Box>
        <PrimaryButton
          className="proceed-to-checkout"
          onClick={hasChanges ? requestReprice : handleCheckout}
          data-testid="proceed-to-checkout"
          disabled={!hasAcceptedCoverages}
          sx={(theme) => ({
            ...(hasChanges && {
              boxShadow: `0 0 0 0 ${theme.designTokens.colorPrimaryLighter}`,
              animation: `${keyframes`
                0% {
                  transform: scale(.95);
                }
                70% {
                  transform: scale(1);
                  box-shadow: 0 0 0 20px ${theme.designTokens.colorWhite};
                }
                100% {
                  transform: scale(.95);
                  box-shadow: 0 0 0 0 ${theme.designTokens.colorWhite};
                }
              `} 1.5s infinite`,
              '&:hover': {
                animation: 'none',
              },
            }),
            width: '100%',
          })}
        >
          {loading ? <VouchLoadingSm /> : hasChanges ? 'Update Quote' : 'Continue to Payment'}
        </PrimaryButton>
        {chargeTransactionFees && (
          <Box display={'flex'} justifyContent={'center'} pt={1}>
            <TransactionFeeDisclosure content={'Transaction fees calculated in next step'} />
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default PricingSection;
