import { FC, ReactElement } from 'react';
import { Backdrop, Box, MuiModal, styled } from '@vouch/ui';

type ModalProps = {
  children: ReactElement;
  displayHeader?: boolean;
  isLoading: boolean;
  modalOpen: boolean;
  modalClose?: () => void;
};

const StyledModal = styled(MuiModal)({
  display: 'flex',
  padding: '150px 0 15px 0',
  overflow: 'scroll',
});
const ModalContents = styled(Box)({
  position: 'relative',
  // "safe" centering. Content that overflows the top or left
  // edges of the screen can still be accessed via scrolling.
  margin: 'auto',
  outline: 'none', // a tabindex is being added to the box
});

const VouchModal: FC<ModalProps> = ({
  children,
  displayHeader = true,
  isLoading = false,
  modalOpen,
  modalClose = () => {
    return;
  },
}: ModalProps): ReactElement => {
  return (
    <StyledModal
      className={`vouch-modal-container ${isLoading ? 'editing-disabled' : ''}`}
      open={modalOpen}
      onClose={(event: React.MouseEvent<unknown, MouseEvent>, reason) => {
        if (reason === 'backdropClick') {
          event.stopPropagation();
        }
        modalClose();
      }}
      BackdropComponent={Backdrop}
    >
      {displayHeader ? (
        <ModalContents>{children}</ModalContents>
      ) : (
        <ModalContents>{children}</ModalContents>
      )}
    </StyledModal>
  );
};

export default VouchModal;
