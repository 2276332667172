import { ReactElement, useState } from 'react';
import { Button, ButtonProps, SxProps, Theme, useTheme } from '@mui/material';

const BaseButton = (props: ButtonProps): ReactElement => {
  const [isActive, setIsActive] = useState(false);
  const { sx } = props;
  const theme = useTheme();

  let mergedSx: SxProps<Theme> = [];
  if (sx) {
    mergedSx = sx instanceof Array ? [...sx] : [sx];
  }
  mergedSx = [...mergedSx, { textTransform: theme.designTokens.buttonTextCase }];

  const activeClass = isActive ? 'active-button' : '';

  const className = props.className ? `${props.className} ${activeClass}` : activeClass;

  return (
    <Button
      {...props}
      className={className}
      disableRipple
      onMouseDown={() => setIsActive(true)}
      onMouseUp={() => setIsActive(false)}
      sx={mergedSx}
    >
      {props.children}
    </Button>
  );
};

export default BaseButton;
