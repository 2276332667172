import { bannerMap, BannerConfig } from 'shared/ui/tokenNames';

interface GetBannerPropTypes {
  referralPartner: string | undefined;
  firstPackage: string;
}

export const getBannerType = (data: GetBannerPropTypes): 'brex-10k' | 'stripe' | 'wework' => {
  const { firstPackage, referralPartner } = data;
  let bannerType;
  if (referralPartner === 'stripe') {
    bannerType = 'stripe';
  } else if (referralPartner && referralPartner === 'wework') {
    bannerType = 'wework';
  } else {
    bannerType = bannerMap[firstPackage];
  }
  return bannerType;
};

const DISCOUNT_EXCLUDED_MARKETS = ['WA', 'NY'];

export const shouldDisplayBanner = ({
  bannerType,
  market,
}: {
  market?: string;
  bannerType: string;
}): boolean => {
  const isDiscountExludedMarket =
    market && BannerConfig[bannerType]?.isDiscount && DISCOUNT_EXCLUDED_MARKETS.includes(market);

  return !isDiscountExludedMarket;
};
