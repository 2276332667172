import { useChat } from '@vouch/third-party/zendesk/widget';
import { FC, useState } from 'react';
import { chatWidgetClicked } from 'features/eventTracking/eventTracking.actions';
import { useDispatch } from 'react-redux';
import { PrimaryButton, useTheme } from '@vouch/ui';

const ChatButtonContainer: FC = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { hide, show } = useChat({
    tracking: (path = '', openViaLink = false) => {
      dispatch(chatWidgetClicked({ path, openViaLink }));
    },
    theme,
  });

  const handleClick = () => {
    if (open) {
      setOpen(false);
      show();
    }
    if (!open) {
      setOpen(true);
      hide();
    }
  };

  return (
    <PrimaryButton onClick={handleClick} data-testid={'chat-button'}>
      Speak to an Advisor
    </PrimaryButton>
  );
};

export default ChatButtonContainer;
