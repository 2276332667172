import { CoverageGroup, Product } from 'features/quotes/quotes.types';
import { FC } from 'react';
import CoverageFull from './variants/FullCard';
import SummaryCard from './variants/SummaryCard';
import { Coverage } from 'features/coverages/coverages.types';

export type CoverageCardProps = {
  coverages: Coverage[];
  group: CoverageGroup;
  hasActiveDiscretion: boolean;
  limitChangeAction: (props: {
    amount: number;
    coverageToken: string;
    frequency: string;
    type: string;
  }) => void;
  loading?: boolean;
  missingDependencies: string | JSX.Element;
  packageSlug: string;
  product: Product;
  toggleCoverage: () => void;
  variant?: 'default' | 'full';
};

const CoverageCard: FC<CoverageCardProps> = (props: CoverageCardProps) => {
  const { variant = 'default' } = props;

  switch (variant) {
    case 'full':
      return <CoverageFull {...props} />;
    default:
      return <SummaryCard {...props} />;
  }
};

export default CoverageCard;
