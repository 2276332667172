import EditIcon from '@mui/icons-material/Edit';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box, styled } from '@mui/material';

export const ReviewListItemGrid = styled('div')(({ theme }) => ({
  backgroundColor: theme.designTokens.colorWhite,
  cursor: 'pointer',
  fontFamily: theme.typography.body1.fontFamily,
  padding: '0.5rem 0.75rem',
  '&:hover': {
    background: theme.designTokens.colorSecondaryLighter,
  },
  '&:active': {
    background: theme.designTokens.colorSecondaryDarker,
  },
  '&.error': {
    borderBottomColor: theme.designTokens.colorError,
    '> * > div': {
      color: theme.designTokens.colorError,
    },
  },
  '&.disabled': {
    backgroundColor: theme.designTokens.colorNeutralLight,
    borderBottomColor: theme.designTokens.colorSecondary,
    cursor: 'auto',
    '> * > div': {
      color: theme.designTokens.colorSecondaryDarker,
    },
  },
}));

interface ReviewListItemProps {
  error?: boolean;
  isMobile?: boolean;
  disabled?: boolean;
  leftLabel?: string;
  onClick?: () => void;
  rightLabel?: string;
  bottomRightLabelPrefix?: string;
  bottomRightLabel?: string;
}

export const ReviewListItem = ({
  disabled,
  error,
  isMobile = false,
  leftLabel,
  onClick,
  rightLabel,
  bottomRightLabelPrefix = "LAST YEAR'S ANSWER",
  bottomRightLabel,
}: ReviewListItemProps) => (
  <ReviewListItemGrid
    onClick={disabled ? undefined : onClick}
    className={`${error ? 'error' : ''} ${disabled ? 'disabled' : ''}`}
  >
    <Box
      sx={(theme) => ({
        alignItems: 'flex-start',
        columnGap: '1rem',
        display: 'flex',
        flexDirection: 'row',
        borderBottomColor: theme.designTokens.colorSecondary,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        paddingBottom: '0.5rem',
      })}
    >
      <Box
        sx={(theme) => ({
          color: theme.designTokens.colorNeutralDark,
          fontSize: '0.75rem',
          paddingTop: '0.2rem',
          letterSpacing: '0.06em',
          textTransform: 'uppercase',
          whiteSpace: !isMobile ? 'nowrap' : 'normal',
        })}
      >
        {leftLabel}
      </Box>
      <Box
        sx={(theme) => ({ color: theme.designTokens.colorBlack })}
        flexGrow={1}
        fontSize="1rem"
        textAlign="right"
      >
        {rightLabel}
      </Box>
      <Box
        sx={(theme) => ({ color: theme.designTokens.colorBlack })}
        fontSize="1.25rem"
        width="1.25rem"
      >
        {disabled ? <LockOutlinedIcon /> : <EditIcon />}
      </Box>
    </Box>
    {bottomRightLabel && (
      <Box
        paddingTop="0.5rem"
        display="flex"
        flexDirection="row"
        justifyContent="right"
        paddingRight="2.25rem"
      >
        <Box
          sx={(theme) => ({
            color: theme.designTokens.colorSecondaryDarker,
            textAlign: 'right',
            fontSize: '0.75rem',
            paddingRight: '0.25rem',
            whiteSpace: 'nowrap',
            lineHeight: '1.1rem',
            letterSpacing: '1.5px',
          })}
        >
          {bottomRightLabelPrefix}
        </Box>
        <Box
          sx={(theme) => ({
            color: theme.designTokens.colorSecondaryDarker,
            textAlign: 'right',
            fontSize: '0.875rem',
            lineHeight: '1rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          })}
        >
          {bottomRightLabel}
        </Box>
      </Box>
    )}
  </ReviewListItemGrid>
);
