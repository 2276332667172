import { criticalStateSelector } from 'features/errors/errorsSlice';
import { sendToCrumbs } from 'features/errors/errors.actions';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Crumbs from 'Pages/crumbs/CrumbsPage';

const CrumbsContainer: FC = () => {
  const dispatch = useDispatch();
  const criticalState = useSelector(criticalStateSelector);
  useEffect(() => {
    if (criticalState) dispatch(sendToCrumbs());
  }, [criticalState, dispatch]);

  return <Crumbs />;
};

export default CrumbsContainer;
