import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Cancel, InfoOutlined } from '@vouch/ui';
import { get } from 'lodash';
import InfoCard from 'components/molecules/cards/InfoCard';
import LimitCard from 'components/molecules/cards/LimitCard';
import { toggleDefinitionPanel } from 'features/eventTracking/eventTracking.actions';
import './LimitOrInfoCard.scss';
import { limitDescriptions } from 'shared/ui/limitDescriptions';

import { space } from 'shared/styles/Basegrid';
import { styled } from '@vouch/ui';

export type LimitOrInfoCardProps = {
  amount: number;
  available: number[];
  coverageToken: string;
  customerEditable: boolean;
  disabled: boolean;
  frequency: string;
  label: string;
  handleChange: (
    coverageToken: string,
    type: string
  ) => (frequency: string) => (amount: number) => void;
  productToken: string;
  type: string;
};

const LimitOrInfoCard: FC<LimitOrInfoCardProps> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const { coverageToken, frequency, label, productToken, type } = props;
  const [showInfo, toggleShowInfo] = useState<boolean>(false);
  const [fade, setFade] = useState<boolean>(false);
  const toggle = () => {
    dispatch(toggleDefinitionPanel({ toggleDefinitionPanel: coverageToken, label }));
    toggleShowInfo(!showInfo);
    setFade(true);
  };
  const { handleChange, ...limitCardProps } = props;
  const description = get(limitDescriptions, [productToken, label, frequency]);

  const CardContainer = styled(Box)({
    width: '100%',
  });
  const ButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: space.Small,
    paddingRight: space.Small,
    width: '100%',
  });

  if (showInfo) {
    return (
      <CardContainer
        className={`${fade ? 'fade' : ''}`}
        data-testid="info-container"
        onAnimationEnd={() => setFade(false)}
        onClick={toggle}
      >
        <ButtonContainer>
          <Cancel sx={{ fontSize: 24, color: 'grey', cursor: 'pointer' }} onClick={toggle} />
        </ButtonContainer>
        <InfoCard description={description} />
      </CardContainer>
    );
  } else {
    return (
      <CardContainer
        className={`${fade ? 'fade' : ''}`}
        data-testid="limit-container"
        onAnimationEnd={() => setFade(false)}
      >
        {description && (
          <ButtonContainer>
            <InfoOutlined
              sx={{ fontSize: 24, color: 'grey', cursor: 'pointer' }}
              className="info-button"
              data-testid="info-button"
              onClick={toggle}
            />
          </ButtonContainer>
        )}

        <LimitCard
          {...limitCardProps}
          handleChange={(amt: number) => handleChange(coverageToken, type)(frequency!)(amt)}
        />
      </CardContainer>
    );
  }
};

export default LimitOrInfoCard;
