import { Theme } from '@mui/material';

const MuiLinearProgress = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.designTokens.colorSecondary,
    }),
  },
};

export default MuiLinearProgress;
