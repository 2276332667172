import { Theme } from '@mui/material';

const MuiDayCalendar = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      weekDayLabel: {
        color: theme.designTokens.colorNeutralDark,
        fontSize: '0.75rem',
      },
    }),
  },
};

export default MuiDayCalendar;
