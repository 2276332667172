import { FC, ReactElement } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@vouch/ui';
import MPIcon from 'shared/assets/icons/mp_icon.svg';
import { space } from 'shared/styles/Basegrid';

export const MultiPolicyDiscountBanner: FC = (): ReactElement => {
  return (
    <Grid
      container
      justifySelf="start"
      justifyContent="center"
      sx={{ width: { sm: 525, md: 710, lg: 710 } }}
    >
      <Grid item xs={12} md={12} xl={12}>
        <Card
          sx={(theme) => ({
            background: 'linear-gradient(180deg, #692D42 0%, #421625 100%)',
            color: theme.designTokens.colorSecondary,
            width: '100%',
            marginBottom: space.Xsmall,
            marginTop: space.Xsmall,
          })}
          variant="outlined"
        >
          <CardContent>
            <Grid container justifyContent="left">
              <Grid item container xs={1} md={1} mr={2}>
                <img
                  alt="Multi Policy Icon"
                  src={MPIcon}
                  style={{
                    marginRight: space.Medium,
                    paddingTop: space.Xsmall,
                  }}
                />
              </Grid>
              <Grid item xs={10} xl={8}>
                <Typography variant={'subtitle2'}>Multi-policy Discount</Typography>
                <Typography variant={'h4'}>
                  Buy coverages from
                  <Box
                    component="span"
                    sx={(theme) => ({ color: theme.designTokens.colorAttention })}
                  >
                    {' '}
                    two or more Vouch policies{' '}
                  </Box>
                  to save more!
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
