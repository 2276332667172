import { errorsActions } from 'features/errors/errorsSlice';
export const LEVEL_DEBUG = 'debug';
export const LEVEL_INFO = 'info';
export const LEVEL_WARN = 'warn';
export const LEVEL_ERROR = 'error';
export const LEVEL_CRITICAL = 'critical';

/**
 * Rollbar event handling
 * Params: {
 *    level: One of [LEVEL_CRITICAL, LEVEL_ERROR, LEVEL_WARN, LEVEL_INFO, LEVEL_DEBUG]
 *    message: Human readable informative error message
 *    extraContext: Optional object of any properties useful for debugging
 * }
 */
export const CATCH_ERROR_ACTION = 'errors/catchError';
export const CRUMBS_ACTION = 'errors/sendToCrumbs';
export const { catchError, sendToCrumbs } = errorsActions;
