import { FC, ReactElement, useEffect, useState } from 'react';
import 'date-fns';
import { AdapterDateFns, EditIcon, LocalizationProvider, DatePicker } from '@vouch/ui';
import './DateField.scss';
import { availableEffectiveDateType } from 'features/quotes/quotes.types';
import { createDateInCurrentTimeZone } from 'shared/utils/date-utils';

type DateProps = {
  changeHandler: (date: Date | null, value?: Date | null) => void;
  availableEffectiveDate: availableEffectiveDateType;
};

const EffectiveDateField: FC<DateProps> = (props: DateProps): ReactElement => {
  const { availableEffectiveDate, changeHandler } = props;
  const { defaultDate, earliestDate, latestDate, locked } = availableEffectiveDate;
  const currentEffectiveDate = createDateInCurrentTimeZone(defaultDate);
  const minDate = createDateInCurrentTimeZone(earliestDate);
  const maxDate = createDateInCurrentTimeZone(latestDate);
  const [date, setDate] = useState(currentEffectiveDate || new Date());
  useEffect(() => {
    if (date) {
      return;
    }
    setDate(currentEffectiveDate);
  }, [currentEffectiveDate, date]);

  const handleChange = (newDate: Date | null) => {
    if (!newDate) {
      // Date is not defined
      return;
    }
    if (newDate === currentEffectiveDate) return;
    changeHandler(newDate);
    setDate(newDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="VouchDatePicker">
        <strong>Effective Date:</strong>
        <DatePicker
          disabled={locked}
          value={currentEffectiveDate}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
          readOnly={locked}
          format="MMM dd, yyyy"
          slots={{ openPickerIcon: EditIcon }}
          slotProps={{
            inputAdornment: {
              className: locked ? 'hidden-button' : '',
            },
            textField: { variant: 'standard', InputProps: { style: { fontWeight: 'bold' } } },
          }}
        />
      </div>
    </LocalizationProvider>
  );
};

export default EffectiveDateField;
