import { Theme } from '@mui/material';

const MuiFilledInput = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.designTokens.colorSecondaryLighter,
      '&:before': {
        borderBottomColor: theme.designTokens.colorNeutralDark,
        borderBottomWidth: 2,
      },

      '&.Mui-disabled': {
        backgroundColor: theme.designTokens.colorNeutralLight,
      },
      '&.Mui-disabled:before': {
        borderBottomStyle: 'solid',
      },
      '&:not(.MuiInputBase-colorWarning):not(.Mui-error):after': {
        borderBottomColor: theme.designTokens.colorPrimaryDarker,
      },
      '&.Mui-focused': {
        backgroundColor: theme.designTokens.colorSecondary,
      },
      '&:hover': {
        backgroundColor: theme.designTokens.colorSecondary,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottomColor: theme.designTokens.colorNeutralDark,
        borderBottomWidth: 2,
      },
    }),
    input: {
      paddingRight: '12px',
      borderRadius: '4px 4px 0 0',
      '&:-webkit-autofill': {
        transition: 'background-color 600000s 0s, color 600000s 0s;',
      },
    },
  },
};

export default MuiFilledInput;
