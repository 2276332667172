import { customersUrl } from 'environment';

export type ProgramVersion = '1.0' | '2.0';

function subRoute(programVersion: ProgramVersion) {
  return programVersion === '2.0' ? 'application' : 'getquote';
}

export function getOnboardingFunnelURL(cardId: string, programVersion: ProgramVersion) {
  return new URL(`${customersUrl}/${subRoute(programVersion)}/${cardId}`);
}
