import { Box, Grid, TextField, Typography, useTheme } from '@vouch/ui';
import { useChat } from '@vouch/third-party/zendesk/widget';
import './style.scss';
import { chatWidgetClicked } from 'features/eventTracking/eventTracking.actions';
import { useState } from 'react';
import TextButtonBase from 'components/atoms/buttons/text/text-button-base';
import { ApolloError } from '@apollo/client';
import { useDispatch } from 'react-redux';

type DiscretionFormProps = {
  onClick: (comment: string) => void;
  loading: boolean;
  error?: ApolloError;
};

function DiscretionForm({ onClick, loading, error }: DiscretionFormProps): JSX.Element {
  const dispatch = useDispatch();
  const tracking = (path = '', openViaLink = false) => {
    dispatch(chatWidgetClicked({ path, openViaLink }));
  };
  const theme = useTheme();
  const [updated, setUpdated] = useState(false);
  const [comment, setComment] = useState('');

  const { show } = useChat({ tracking, theme });

  const updateField = (e: string) => {
    if (!updated) setUpdated(true);
    setComment(e);
  };

  const onSubmit = (e: string) => {
    setComment('');
    setUpdated(false);
    onClick(e);
  };

  // add a data-testid to the actual note-to-underwriter-field input element for testing purposes
  const inputProps = {
    'data-testid': 'note-to-underwriter-field-input',
  };
  return (
    <Grid className="discretion-form" data-testid={'discretion-form'}>
      <Box pb={1.5}>
        <Typography sx={{ color: theme.designTokens.colorBlack }} variant="detail">
          REQUEST A REVIEW
        </Typography>
      </Box>

      <Box pb={2}>
        <Typography variant="h2" fontWeight="bold">
          Need something else?
        </Typography>
      </Box>

      <Box pb={2}>
        <Typography variant="body1">
          Require higher limits, one of our unavailable coverages, or add-ons? Tell us what you need
          below and a Vouch underwriter will review your application, provide coverage options, and
          recommend limit ranges.
        </Typography>
      </Box>

      <Box pb={6}>
        <Typography sx={{ color: theme.designTokens.colorBlack }} variant="detail">
          NOTE TO UNDERWRITER
        </Typography>
        <br />
        <TextField
          className="description-input"
          error={updated && comment.length === 0}
          id="note-to-underwriter-field"
          multiline
          onChange={(e) => updateField(e.target.value)}
          placeholder="Provide description"
          rows="4"
          value={comment}
          inputProps={inputProps}
        />
        {error && (
          <Box>
            <Typography
              sx={{
                color: theme.designTokens.colorError,
              }}
              className="error-message"
              variant="subtitle2"
            >
              Submission failed. Please try again or
              <Typography onClick={show} className="error-underline" variant="subtitle2">
                contact us
              </Typography>
              .
            </Typography>
          </Box>
        )}
      </Box>

      <Grid alignItems="flex-start" direction="column" container>
        <TextButtonBase
          variant="contained"
          className="submit"
          onClick={() => onSubmit(comment)}
          disabled={comment.length === 0 || loading}
          displayTextOnMobile={true}
          isLoading={loading}
        >
          Submit Request
        </TextButtonBase>
      </Grid>

      <Box pt={3}>
        <Typography variant="subtitle2" fontWeight="normal">
          We will respond to your request within four business days. You will not be able to check
          out in the meantime, but you can cancel your request after you submit it.
        </Typography>
      </Box>
      <br />
      <Grid alignItems="flex-start" direction="row" container>
        <Typography variant="subtitle2" fontWeight="normal">
          Have a question?
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight="normal"
          onClick={show}
          className="chat-link"
          sx={{
            paddingLeft: '0.25rem',
            color: theme.designTokens.colorPrimaryDarker,
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          Chat with us
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DiscretionForm;
