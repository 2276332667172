import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { useRef } from 'react';

const Link = ReactQuill.Quill.import('formats/link');
Link.sanitize = function (url: string) {
  if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};

export default function WysiwygEditor({
  showToolbar = true,
  placeholder = '',
  onChange,
  content,
}: {
  showToolbar?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
  content: string;
}) {
  const quillRef = useRef<ReactQuill>(null);

  const isQuillEmpty = () => {
    const quill = quillRef.current?.getEditor();
    if (!quill) return false;

    if ((quill.getContents()['ops'] || []).length !== 1) {
      return false;
    }
    return quill.getText().trim().length === 0;
  };
  const handleChange = (value: string) => {
    onChange(isQuillEmpty() ? '' : value);
  };

  return (
    <ReactQuill
      className={showToolbar ? 'toolbar-editor' : 'plain-editor'}
      theme={showToolbar ? 'snow' : 'bubble'}
      value={content}
      onChange={handleChange}
      modules={
        showToolbar ? WysiwygEditor.modules : { clipboard: { matchVisual: false }, toolbar: false }
      }
      placeholder={placeholder}
      ref={quillRef}
    />
  );
}

WysiwygEditor.modules = {
  clipboard: {
    matchVisual: false,
  },
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [false, 'center', 'right'] }],
    ['link'],
  ],
};
