import { Theme, SxProps } from '@vouch/ui';
import { space } from 'shared/styles/Basegrid';

const partnerOrange = '#e49114';

export const partner: SxProps<Theme> = () => ({
  color: partnerOrange,
  '& .infoIcon': {
    color: partnerOrange,
  },
});

export const totalNumbers: SxProps<Theme> = () => ({
  marginLeft: '18px',
  marginBottom: space.Xsmall,
  textAlign: 'right',
});
