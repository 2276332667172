import { AnyAction, MiddlewareAPI } from 'store';
import { Dispatch } from 'redux';
import { get } from 'lodash';
import LogRocket from 'logrocket';
import SegmentEventNames from 'features/eventTracking/segmentEventNames';
import rollbar from '@vouch/third-party/rollbar';
import { CATCH_ERROR_ACTION, CRUMBS_ACTION } from 'features/errors/errors.actions';
import { envName } from 'environment';

const vouchTelemetry =
  (storeAPI: MiddlewareAPI) =>
  (next: Dispatch) =>
  (action: AnyAction): AnyAction => {
    // Do anything here: pass the action onwards with next(action),
    // or restart the pipeline with storeAPI.dispatch(action)
    // Can also use storeAPI.getState() here
    try {
      const state = storeAPI.getState();
      const {
        applicationId,
        companyId,
        email,
        friendlyName,
        isRenewal,
        isVouchUserInProd,
        segmentTestingEnabled,
      } = state.session;
      const { selectedPackage } = state.packages;
      const { experience } = state.session;
      const { error, payload, type } = action;
      const baseProperties = {
        companyId,
        companyName: friendlyName,
        selectedPackage,
        activeQuoteId: get(state.packages, ['bySlug', selectedPackage, 'activeQuoteId'], ''),
        isRenewal,
      };

      const logRocketSession = LogRocket.sessionURL?.includes('Must call LogRocket')
        ? 'Unavailable'
        : LogRocket.sessionURL;

      // Error Handling
      if (type === CATCH_ERROR_ACTION || error) {
        // The LogRocket sessionUrl is unavailable in local environments
        if (error) {
          // Error occurred in async action or reducer
          // Don't pass full payload to Rollbar because payload is unknown
          rollbar.error(`${type}: ${error.message}`, {
            ...baseProperties,
            logRocketSession,
            experience,
          });
        } else {
          const { level, message, extraContext = {} } = payload;
          rollbar[level](message, {
            ...baseProperties,
            ...extraContext,
            logRocketSession,
            experience,
          });
        }
        // Log sanitized state
        LogRocket.log({ ...state, session: {} });
        LogRocket.log(action);
      }

      // Segment  Tracking
      const trackedEvent = SegmentEventNames[type];
      if (trackedEvent && (!isVouchUserInProd || segmentTestingEnabled)) {
        const trackingProperties: Record<string, string | undefined | null> = {
          applicationId,
          leadId: email,
          currentUserEmail: email,
          lrSessionUrl: logRocketSession,
        };

        if (type === CRUMBS_ACTION) {
          trackingProperties.error = state.errors.error;
        }

        if (envName === 'local') {
          // eslint-disable-next-line no-console
          console.log(`ANALYTICS: Tracking ${trackedEvent} event.`);
        }

        // Explicitly tracked events can pass full payload
        // because payload contents are known
        analytics.track(trackedEvent, {
          ...baseProperties,
          ...trackingProperties,
          ...payload,
        });
      }
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rollbar.error(`Telemetry Middleware Error: ${(e as any).message}`);
    }
    return next(action);
  };

export default vouchTelemetry;
