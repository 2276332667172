import { Theme } from '@mui/material';

const MuiAppBar = {
  variants: [
    {
      props: { variant: 'quote' },
    },
  ],
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      boxShadow: 'none',
      borderBottom: `1px solid ${theme.designTokens.colorSecondary}`,
      fontWeight: 400,
      textTransform: 'none',
      '.MuiToolbar-root': {
        padding: '12px 20px',
        minHeight: 0,
      },
      '& .MuiButton-startIcon': {
        marginRight: 0,
      },
    }),
  },
};

export default MuiAppBar;
