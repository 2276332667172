import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Box, Button, ButtonProps } from '@vouch/ui';
import VouchLoadingSm from 'components/atoms/icons/VouchLoadingSm';

export type TextButtonProps = ButtonProps & {
  icon?: ReactNode;
  isLoading: boolean;
  displayTextOnMobile?: boolean;
  loadingIcon?: JSX.Element;
};

const displayIcon = (isLoading: boolean, icon: ReactNode, loadingIcon: JSX.Element | undefined) => {
  if (isLoading) {
    return loadingIcon ? loadingIcon : <VouchLoadingSm />;
  } else {
    return icon;
  }
};

const TextButtonBase = (props: PropsWithChildren<TextButtonProps>): ReactElement => {
  const {
    isLoading,
    icon,
    children,
    displayTextOnMobile = false,
    loadingIcon,
    sx,
    ...buttonProps
  } = props;

  const sxOverrides = {
    ...(icon && !displayTextOnMobile
      ? {
          minWidth: '0rem',
        }
      : {}),
  };

  return (
    <Button
      variant="text"
      disableRipple={true}
      startIcon={displayIcon(isLoading, icon, loadingIcon)}
      {...buttonProps}
      sx={Array.isArray(sx) ? [...sx, sxOverrides] : sx ? [sx, sxOverrides] : sxOverrides}
    >
      {displayTextOnMobile ? (
        children
      ) : (
        <Box sx={{ display: { xs: 'none', sm: 'block' }, marginLeft: 1 }}>{children}</Box>
      )}
    </Button>
  );
};
export default TextButtonBase;
