import { Theme } from '@mui/material';

const MuiRadioGroup = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      color: theme.designTokens.colorBlack,
    }),
  },
};

export default MuiRadioGroup;
