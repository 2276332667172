import { createContext, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const EXPERIENCES = {
  VANTA: 'vanta',
  VOUCH: 'vouch',
} as const;

export type Experience = (typeof EXPERIENCES)[keyof typeof EXPERIENCES];

const isExperience = (val: string): val is Experience => {
  return Object.values(EXPERIENCES).includes(val as Experience);
};

const parseExperience = (val: string): Experience => {
  return isExperience(val) ? val : EXPERIENCES.VOUCH;
};

export const ExperienceContext = createContext<{
  experience: Experience;
}>({
  experience: 'vouch',
});

/**
 *
 * - If no param is provided default to vouch
 * - If invalid value is provided default to vouch
 * - Persist value across navigations (blank value after initial render)
 *
 */
const ExperienceProvider = ({ children }: { children: JSX.Element }) => {
  const [experience, setExperience] = useState<Experience>(EXPERIENCES.VOUCH);

  const [searchParams] = useSearchParams();
  const experienceParam = searchParams.get('experience')?.toLowerCase();
  const parsedExperience = parseExperience(experienceParam || '');
  if (experienceParam && experience !== parsedExperience) {
    setExperience(parsedExperience);
  }

  return <ExperienceContext.Provider value={{ experience }}>{children}</ExperienceContext.Provider>;
};

function useExperience() {
  const context = useContext(ExperienceContext);
  if (context === undefined) {
    throw new Error('useExperience must be used within a ExperienceProvider');
  }
  return context;
}

export { ExperienceProvider, useExperience };
