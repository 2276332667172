import { Box, InfoOutlined, Tooltip } from '@vouch/ui';
import { FC, ReactElement } from 'react';

type infoTooltipProps = { content: string | ReactElement };
export const InfoTooltip: FC<infoTooltipProps> = (props) => {
  return (
    <Box ml={0.75} mt={0.5}>
      <Tooltip title={props.content}>
        <InfoOutlined sx={{ fontSize: 20 }} />
      </Tooltip>
    </Box>
  );
};
