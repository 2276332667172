export const CoverageDescriptions: Record<string, string | JSX.Element> = {
  general_liability:
    'General Liability offers baseline protection against the common risks of running a company, such as injuries on your property or a lawsuit brought against you for trademark infringement.',
  business_personal_property: (
    <span>
      Business Property insurance is crafted to cover repairing or replacing equipment your business
      owns like laptops, desks, chairs, and other hardware.
      <br />
      <br />
      While warranty products like AppleCare cover equipment breakdown, this insurance covers
      property in the event of theft, accidents, weather damage, and more.
    </span>
  ),
  cpp_general_liability:
    'General Liability offers baseline protection against the common risks of running a company, such as injuries on your property or a lawsuit brought against you for trademark infringement.',
  cpp_prop_contents: (
    <span>
      Business Property insurance is crafted to cover repairing or replacing equipment your business
      owns like laptops, desks, chairs, and other hardware.
      <br />
      <br />
      While warranty products like AppleCare cover equipment breakdown, this insurance covers
      property in the event of theft, accidents, weather damage, and more.
    </span>
  ),
  cpp_gl_hnoa: (
    <span>
      Hired and Non-owned Auto is designed to protect your company against a lawsuit if an employee
      is in an accident using a rental car (including rideshares) or the employee&apos;s own vehicle{' '}
      <i>so long as</i> the employee is on company business. This coverage sits in excess to any
      personal car insurance.
      <br />
      <br />
      <b>Note:</b> this coverage generally <b>excludes vehicles used for delivery</b> as part of
      your business, like cars used for food delivery as part of a food delivery startup. It also
      excludes independent contractors.
    </span>
  ),
  cpp_gl_ebl: (
    <span>
      Not to be confused with Employment Practices Liability, this kind of insurance provides
      coverage for mistakes <i>in the administration</i> of your business’s employee benefit
      programs.
      <br />
      <br />
      Common examples include failing to enroll an employee in a group health care plan, providing
      bad advice to an employee during the selection process, or miscalculating a retiree’s pension
      benefits.
    </span>
  ),
  cpp_gl_damages_prem_rented: (
    <span>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
      been the industrys standard dummy text ever since the 1500s
      <br />
      <br />
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
      been the industrys standard dummy text ever since the 1500s
    </span>
  ),
  gl_med_pay: (
    <span>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
      been the industrys standard dummy text ever since the 1500s
      <br />
      <br />
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
      been the industrys standard dummy text ever since the 1500s
    </span>
  ),
  gl_pai: (
    <span>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
      been the industrys standard dummy text ever since the 1500s
      <br />
      <br />
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
      been the industrys standard dummy text ever since the 1500s
    </span>
  ),
  employee_benefits_liability: (
    <span>
      Not to be confused with Employment Practices Liability, this kind of insurance provides
      coverage for mistakes <i>in the administration</i> of your business’s employee benefit
      programs.
      <br />
      <br />
      Common examples include failing to enroll an employee in a group health care plan, providing
      bad advice to an employee during the selection process, or miscalculating a retiree’s pension
      benefits.
    </span>
  ),
  hired_and_nonowned_auto: (
    <span>
      Hired and Non-owned Auto is designed to protect your company against a lawsuit if an employee
      is in an accident using a rental car (including rideshares) or the employee&apos;s own vehicle{' '}
      <i>so long as</i> the employee is on company business. This coverage sits in excess to any
      personal car insurance.
      <br />
      <br />
      <b>Note:</b> this coverage generally <b>excludes vehicles used for delivery</b> as part of
      your business, like cars used for food delivery as part of a food delivery startup. It also
      excludes independent contractors.
    </span>
  ),
  ny_directors_and_officers:
    'Directors and Officers insurance helps protect the personal assets of your founders and your startup from lawsuits associated with their decisions.',
  directors_and_officers:
    'Directors and Officers insurance helps protect the personal assets of your founders and your startup from lawsuits associated with their decisions.',
  employment_practices_liability:
    'Helps cover actual and frivolous allegations of wrongdoing by and between managers and employees.',
  ny_employment_practices_liability:
    'Helps cover actual and frivolous allegations of wrongdoing by and between managers and employees.',
  errors_and_omissions:
    'Help cover when a mistake in your products or services causes a financial loss to a customer or partner.',
  ny_errors_and_omissions:
    'Help cover when a mistake in your products or services causes a financial loss to a customer or partner.',
  cem_errors_and_omissions:
    'Help cover when a mistake in your products or services causes a financial loss to a customer or partner.',
  cyber: 'Cyber Coverage',
  ny_cyber: 'Cyber Coverage',
  first_party_cyber: 'Helps cover the costs of cyber incidents on your systems.',
  third_party_cyber:
    "Provides coverage for cyber incidents that affect your clients or other third-parties when you're the host or otherwise responsible for maintaining or manipulating their data.",
  fiduciary: (
    <span>
      While Employee Benefits Liability provides coverage for mistakes in the <i>administration</i>
       of your business’s benefit programs, Fiduciary Liability helps cover mistakes in the 
      <i>management</i> of programs by anyone with discretionary authority at your business.
      <br />
      <br />
      This helps protect your company against a lawsuit alleging “breach of fiduciary duties” that
      involve discretionary judgements, like poor investment management of retirement funds in a
      401(k) plan.
    </span>
  ),
  ny_fiduciary: (
    <span>
      While Employee Benefits Liability provides coverage for mistakes in the <i>administration</i>
       of your business’s benefit programs, Fiduciary Liability helps cover mistakes in the 
      <i>management</i> of programs by anyone with discretionary authority at your business.
      <br />
      <br />
      This helps protect your company against a lawsuit alleging “breach of fiduciary duties” that
      involve discretionary judgements, like poor investment management of retirement funds in a
      401(k) plan.
    </span>
  ),
  employee_dishonesty: 'Helps pay for losses due to employee theft.',
  business_income_interruption: 'Business Income Interruption',
  computer_fraud:
    'Helps pay for losses due to fraudulent data entry and fraudulent instructions to a financial institution to transfer money.',
  counterfeit_money: 'Counterfeit Money',
  forgery_and_alteration:
    'Helps pay for losses due to forgery or alteration of things such as checks and promissory notes.',
  fraudulent_impersonation:
    ' Helps pay for losses due to fraudulent transfers issued by an employee.',
  crime: 'Protection from employee theft, forgery, and fraud.',
};
