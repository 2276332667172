import { Theme } from '@mui/material';

const MuiPopover = {
  styleOverrides: {
    root: {
      '.MuiBackdrop-root': {
        backgroundColor: 'initial',
      },
    },
    paper: ({ theme }: { theme: Theme }) => ({
      boxShadow: `${theme.designTokens.colorNeutralDark} 0 2px 4px 0`,
    }),
  },
};

export default MuiPopover;
