import { Theme } from '@mui/material';

const MuiFormControlLabel = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      color: theme.designTokens.colorBlack,
      '&.radio-group-form-control-label': {
        '.MuiFormControlLabel-label': {
          fontSize: '1.25rem',
          fontWeight: 700,
          marginLeft: '0.125rem',
        },
      },
      '.Mui-checked + .Mui-disabled': {
        color: theme.designTokens.colorBlack,
      },
    }),
  },
};

export default MuiFormControlLabel;
