import { ReactElement } from 'react';
import './style.scss';
import { SvgIcon } from '@vouch/ui';

const TextBubble = (): ReactElement => (
  <div className="text-bubble-svg">
    <SvgIcon />
  </div>
);

export default TextBubble;
