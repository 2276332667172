const MuiIcon = {
  defaultProps: {
    color: 'inherit',
  },
};

export const MuiSvgIcon = {
  defaultProps: {
    fontSize: 'inherit',
    color: 'inherit',
  },
};

export default MuiIcon;
