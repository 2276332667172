import { Theme } from '@mui/material';

const MuiMenuItem = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      '&:hover': {
        background: theme.designTokens.colorPrimaryLightest,
      },
      '&.Mui-selected': {
        background: theme.designTokens.colorPrimaryLightest,
      },
    }),
  },
};

export default MuiMenuItem;
