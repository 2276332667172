import { FC, ReactElement, useEffect } from 'react';
import { isBasic } from 'Pages/package/PackagesConfig';
import { Box, Grid } from '@vouch/ui';
import { COVERAGE_ORDER, getLayoutFor } from 'shared/ui/layoutUtils';
import PolicySection from 'components/organisms/policy-section';
import { runningListOfUiTokens } from 'shared/ui/tokenNames';
import { isEmpty } from 'lodash';
import EmptyCard from 'components/molecules/cards/CoverageCard/variants/EmptyCard';
import { meetsDependencyRequirements } from './quote-helpers';
import CoverageCardContent from 'components/molecules/cards/CoverageCard/components/CoverageCardContent';
import ExpandedCard from 'containers/ExpandedCardContainer/ExpandedCard';
import { useSelector } from 'react-redux';
import { coverageGroupAcceptance, idToUiTokens } from 'features/coverageGroups/coverageGroupsSlice';
import { activeQuoteBySlug } from 'features/quotes/quotesSlice';
import { selectedPackage } from 'features/packages/packagesSlice';
import {
  getHasActiveDiscretion,
  sessionExpirationDate,
  getIsEligibleForAICoverage,
} from 'features/session/sessionSlice';
import { noOp } from 'shared/utils/utility';
import './style.scss';
import TitleSection from 'components/organisms/title-section';
import { Coverage } from 'features/coverages/coverages.types';
import { AICoverageBanner, MultiPolicyDiscountBanner } from '../../components/molecules/cards';
import { useFlags } from 'launchdarkly-react-client-sdk';

const PrintableQuotePage: FC = (): JSX.Element => {
  const packageSlug = useSelector(selectedPackage);
  const quote = useSelector(activeQuoteBySlug(packageSlug!));
  const groupIdToTokenMap = useSelector(idToUiTokens);
  const groupAcceptance = useSelector(coverageGroupAcceptance);
  const isEligibleForAICoverage = useSelector(getIsEligibleForAICoverage);
  const hasActiveDiscretion = useSelector(getHasActiveDiscretion);
  const basicPackage = isBasic[packageSlug!];
  const sections: ReactElement[] = [];
  const { aiCoverageBanner } = useFlags();
  const layout = getLayoutFor(quote.products);
  const useLayout = basicPackage ? layout.slice(0, 1) : layout;
  useLayout.forEach(({ productToken, coverageGroupsLayout }) => {
    const groupLayout = basicPackage ? ['basic_group'] : coverageGroupsLayout;

    const currentProduct = quote.products[productToken];
    sections.push(
      <PolicySection
        key={productToken}
        coverageList={groupLayout.flatMap((groupToken) => {
          if (!currentProduct || isEmpty(currentProduct.coverageGroups)) {
            return <EmptyCard key={groupToken} cardTitle={runningListOfUiTokens[groupToken]} />;
          }

          const currentGroup = currentProduct.coverageGroups[groupToken];
          let missingDependencies: string | JSX.Element = '';
          if (!basicPackage && !isEmpty(groupAcceptance)) {
            missingDependencies = meetsDependencyRequirements(
              currentGroup.anyRequiredGroupIds,
              currentGroup.allRequiredGroupIds,
              groupIdToTokenMap,
              groupAcceptance
            );
          }
          const coverageTokens = COVERAGE_ORDER[groupToken] || Object.keys(currentGroup.elements);
          const coverageMap = coverageTokens
            .map((token) => currentGroup.elements[token])
            .filter((elt: Coverage | undefined): elt is Coverage => elt !== undefined);

          return coverageMap.map((coverage, index) => {
            const { offered, accepted, implicitElements, outOfPockets, limits } = coverage;
            return (
              <ExpandedCard
                key={coverage.uiToken}
                accepted={accepted}
                coverageTokens={coverageTokens}
                groupToken={groupToken}
                implicitElements={implicitElements}
                limits={coverage.limits}
                outOfPockets={outOfPockets}
                productToken={productToken}
                selectedCoverageIndex={index}
                sharedLimit={currentProduct.sharedLimit}
                tabAction={noOp}
                locationSpecific={false}
                locationCoverageDetails={{}}
              >
                <Box p={2}>
                  <CoverageCardContent
                    groupToken={groupToken}
                    missingDependencies={missingDependencies}
                    cardTitle={runningListOfUiTokens[groupToken]}
                    offered={offered}
                    accepted={accepted}
                    hasActiveDiscretion={hasActiveDiscretion}
                    limits={limits}
                    pricing={currentGroup.pricing}
                    toggleCoverage={noOp}
                  />
                </Box>
              </ExpandedCard>
            );
          });
        })}
      />
    );
  });

  useEffect(() => {
    if (quote.id !== '') window.print();
  }, [quote.id]);

  return (
    <Grid container className="quote-page printer-version">
      <TitleSection
        availableEffectiveDate={quote.availableEffectiveDate}
        quoteExpirationString={quote.metadata.expiresOn}
        applicationExpirationString={useSelector(sessionExpirationDate)}
      />

      {basicPackage ? (
        sections.slice(0, 1)
      ) : (
        <>
          {sections.slice(0, 2)}
          <Grid container justifyContent="center" md={8} xl={8}>
            {aiCoverageBanner && isEligibleForAICoverage ? (
              <AICoverageBanner />
            ) : (
              <MultiPolicyDiscountBanner />
            )}
          </Grid>
          {sections.slice(2)}
        </>
      )}
    </Grid>
  );
};

export default PrintableQuotePage;
