export const auth0Domain = import.meta.env.VITE_AUTH0_CUSTOM_DOMAIN as string;
export const auth0ClientId = import.meta.env.VITE_AUTH0_CLIENT_ID as string;
export const accountUrl = import.meta.env.VITE_ACCOUNT_URL as string;
export const apiBaseUrl = import.meta.env.VITE_PA_SHIM_URL as string;
export const gatewayDomain = import.meta.env.VITE_GATEWAY_URL as string;
export const customersUrl = import.meta.env.VITE_CUSTOMERS_URL as string;
export const intakeClientUrl = import.meta.env.VITE_INTAKE_CLIENT_URL as string;
export const rendererUrl = import.meta.env.VITE_RENDERER_URL as string;
export const loginPortalUrl = `${customersUrl}/loginportal/send`;
export const logoutPortalUrl = `${customersUrl}/loginportal/logout` as string;
export const launchDarklyId = (import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID as string) || '';
export const logRocketAppId = (import.meta.env.VITE_LOGROCKET_APP_ID as string) || '';
export const envName = import.meta.env.VITE_ENV || import.meta.env.NODE_ENV;
