import { ReactElement } from 'react';
import './style.scss';
import { SvgIcon } from '@vouch/ui';

const DiscountTag = (): ReactElement => (
  <div className="discount-tag-svg">
    <SvgIcon />
  </div>
);

export default DiscountTag;
