import Colors from '../../components/theme/colors.module.scss';

type Props = { fill?: string } & React.SVGProps<SVGSVGElement>;

export const Lock = ({ fill = Colors.grey700, ...rest }: Props) => (
  <svg
    width="9"
    height="11"
    viewBox="0 0 9 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7.78467 3.77905H7.29663V2.77905C7.29663 1.39905 6.20343 0.279053 4.85645 0.279053C3.50946 0.279053 2.41626 1.39905 2.41626 2.77905V3.77905H1.92822C1.39138 3.77905 0.952148 4.22905 0.952148 4.77905V9.77905C0.952148 10.3291 1.39138 10.7791 1.92822 10.7791H7.78467C8.32151 10.7791 8.76075 10.3291 8.76075 9.77905V4.77905C8.76075 4.22905 8.32151 3.77905 7.78467 3.77905ZM3.39234 2.77905C3.39234 1.94905 4.04631 1.27905 4.85645 1.27905C5.66659 1.27905 6.32056 1.94905 6.32056 2.77905V3.77905H3.39234V2.77905ZM7.78467 9.77905H1.92822V4.77905H7.78467V9.77905ZM4.85645 8.27905C5.39329 8.27905 5.83252 7.82905 5.83252 7.27905C5.83252 6.72905 5.39329 6.27905 4.85645 6.27905C4.31961 6.27905 3.88037 6.72905 3.88037 7.27905C3.88037 7.82905 4.31961 8.27905 4.85645 8.27905Z"
      fill={fill}
    />
  </svg>
);
