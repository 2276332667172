import { Theme } from '@mui/material';

const MuiInputLabel = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      fontSize: theme.typography.caption.fontSize,
      marginLeft: 0,
      color: theme.designTokens.colorNeutralDark,
      '&:not(.Mui-focused):not(.MuiInputLabel-shrink)': {
        fontSize: '1.125rem',
      },
      '&.Mui-focused:not(.MuiFormLabel-colorWarning):not(.Mui-error)': {
        color: theme.designTokens.colorPrimaryDarker,
      },
      '&.Mui-error:not(.Mui-focused)': {
        color: theme.designTokens.colorError,
      },
    }),
    asterisk: {
      display: 'none',
    },
  },
};

export default MuiInputLabel;
