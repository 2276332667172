import { Box, Card, Close, Grid, Typography, useMediaQuery, useTheme } from '@vouch/ui';
import { PropsWithChildren, ReactElement } from 'react';
import { CloseButton } from 'components/atoms/buttons/icon';
import {
  CoverageTabs,
  SubcoverageSection,
  getSubcoverageDisplayKeys,
} from 'components/molecules/cards/CoverageCard/components/components';
import { CoverageDescriptions } from 'shared/ui/coverageDescriptions';
import { LimitSection } from 'components/templates/limit-section';
import { ExpandedCardProps } from 'containers/ExpandedCardContainer/ExpandedCard';
import LocationCard from 'components/molecules/cards/LocationCard/LocationCard';
import { ToggleableText } from 'components/atoms/buttons/toggle';

export type DefaultExpandedCardProps = ExpandedCardProps & {
  simpleLimitOrOutOfPocketHandler: (
    coverageToken: string,
    type: string,
    frequency: string,
    amount: number
  ) => void;
};
const DefaultExpandedCard = ({
  accepted,
  children,
  closeModal,
  coverageTokens,
  groupToken,
  implicitElements,
  limits,
  outOfPockets,
  productToken,
  selectedCoverageIndex,
  sharedLimit,
  simpleLimitOrOutOfPocketHandler,
  tabAction,
  unsupportedLocations,
  locationSpecific,
  locationCoverageDetails,
}: PropsWithChildren<DefaultExpandedCardProps>): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (_e: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    tabAction(newValue);
  };

  // TODO: clean up this handler and how its being passed to limit selector
  // Likely gonna change regardless because of linked Cyber limits
  const handleLimitOrOutOfPocketChange =
    (coverageToken: string, type: string) => (frequency: string) => (amount: number) => {
      simpleLimitOrOutOfPocketHandler(coverageToken, type, frequency, amount);
    };

  const hasKeyHighlights =
    !locationSpecific && getSubcoverageDisplayKeys(implicitElements).length > 0;

  return (
    <Card data-testid="default-expanded-card" className="expanded-coverage-card">
      {closeModal && (
        <CloseButton
          className="close-button-header"
          icon={<Close className="close-icon" data-testid="coverage-card-close-btn" />}
          onClick={closeModal}
        />
      )}
      <Grid container>
        <Grid item xs={12} sm={4} className="coverage-card-section">
          <Box>{children}</Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          borderLeft={{ xs: 'none', sm: `1px solid ${theme.designTokens.colorSecondary}` }}
        >
          <Box mt={1} />
          <CoverageTabs
            coverageTokens={coverageTokens}
            selectedCoverageIndex={selectedCoverageIndex}
            handleTabChange={handleTabChange}
          />
          {isMobile && !locationSpecific && (
            <LimitSection
              accepted={accepted}
              coverageTokens={coverageTokens}
              limits={limits}
              outOfPockets={outOfPockets}
              productToken={productToken}
              selectedCoverageIndex={selectedCoverageIndex}
              sharedLimit={sharedLimit}
              handleLimitOrOutOfPocketChange={handleLimitOrOutOfPocketChange}
            />
          )}
          <Box p={2} className="coverage-description">
            <ToggleableText truncatedLines={hasKeyHighlights ? 2 : 20}>
              {CoverageDescriptions[coverageTokens[selectedCoverageIndex]]}
            </ToggleableText>
          </Box>
          {hasKeyHighlights && (
            <Box ml={2}>
              <Typography
                variant="subtitle2"
                fontWeight="normal"
                sx={{ color: theme.designTokens.colorNeutralDark }}
              >
                Key Highlights
              </Typography>
            </Box>
          )}
          <SubcoverageSection closeModal={closeModal} implicitElements={implicitElements} />
          {locationSpecific && locationCoverageDetails && (
            <LocationCard
              groupToken={groupToken}
              locationDetails={locationCoverageDetails}
              unsupportedLocations={unsupportedLocations}
            />
          )}
        </Grid>
        {!isMobile && !locationSpecific && (
          <LimitSection
            accepted={accepted}
            coverageTokens={coverageTokens}
            limits={limits}
            outOfPockets={outOfPockets}
            productToken={productToken}
            selectedCoverageIndex={selectedCoverageIndex}
            sharedLimit={sharedLimit}
            handleLimitOrOutOfPocketChange={handleLimitOrOutOfPocketChange}
          />
        )}
      </Grid>
    </Card>
  );
};

export default DefaultExpandedCard;
