import { Box, Card, Close, Grid, Link, Typography, useTheme, useMediaQuery } from '@vouch/ui';
import { PropsWithChildren, ReactElement } from 'react';
import { get } from 'lodash';
import { CloseButton } from 'components/atoms/buttons/icon';
import {
  Coverage,
  CYBER_COVERAGES,
  FIRST_PARTY_CYBER,
  THIRD_PARTY_CYBER,
} from 'features/coverages/coverages.types';
import { LEVEL_DEBUG, LEVEL_ERROR } from 'features/errors/errors.actions';
import { CoverageDescriptions } from 'shared/ui/coverageDescriptions';
import { ExpandedCardProps } from 'containers/ExpandedCardContainer/ExpandedCard';
import LimitOrInfoCard from 'components/molecules/cards/LimitOrInfoCard';
import { LimitSection } from 'components/templates/limit-section';
import {
  CoverageTabs,
  SubcoverageSection,
  getSubcoverageDisplayKeys,
} from 'components/molecules/cards/CoverageCard/components/components';
import LocationCard from 'components/molecules/cards/LocationCard/LocationCard';
import { ToggleableText } from 'components/atoms/buttons/toggle';

type CyberExpandedCardProps = ExpandedCardProps & {
  cyberCoverages: Coverage[];
  debugLogger: (
    level: string,
    message: string,
    extraContext?: Record<string, Coverage | string | number>
  ) => void;
  updateCyberLimit: (targetCoverageToken: string, requestedAmount: number) => void;
  simpleLimitOrOutOfPocketHandler: (
    coverageToken: string,
    type: string,
    frequency: string,
    amount: number
  ) => void;
};

const CyberExpandedCard = (props: PropsWithChildren<CyberExpandedCardProps>): ReactElement => {
  const {
    accepted,
    children,
    closeModal,
    coverageTokens,
    cyberCoverages,
    debugLogger,
    groupToken,
    implicitElements,
    limits,
    outOfPockets,
    tabAction,
    productToken,
    selectedCoverageIndex,
    sharedLimit,
    simpleLimitOrOutOfPocketHandler,
    updateCyberLimit,
    locationSpecific,
    locationCoverageDetails,
  } = props;
  const isCEM = productToken === 'CEM';
  const footnoteTabToggle = () => (selectedCoverageIndex === 0 ? tabAction(1) : tabAction(0));
  const [cyberFirst, cyberThird] = cyberCoverages;

  const handleCEMCyber = (targetCoverageToken: string, requestedAmount: number): void => {
    const firstPartyLimit = get(cyberFirst, ['limits', 'in_aggregate']);
    const thirdPartyLimit = get(cyberThird, ['limits', 'in_aggregate']);
    debugLogger(LEVEL_DEBUG, `Changing ${targetCoverageToken}`);
    switch (targetCoverageToken) {
      case FIRST_PARTY_CYBER:
        debugLogger(
          LEVEL_DEBUG,
          `Requested first party limit is less than or equal to third: ${
            requestedAmount <= thirdPartyLimit.amount
          }`
        );
        if (requestedAmount <= thirdPartyLimit.amount) {
          // If the REQUESTED FIRST party amount is LESS OR EQUAL then CURRENT THIRD party amount,
          // update FIRST party limit to the REQUESTED amount
          updateCyberLimit(FIRST_PARTY_CYBER, requestedAmount);
        } else {
          // If the THIRD party amount must be INCREASED
          const { amount, available } = thirdPartyLimit;
          const currentAmountIndex = available.indexOf(amount);
          // If the CURRENT THIRD party amount is not the MAX available amount,
          // update FIRST party limit to the REQUESTED amount and THIRD party limit to the next GREATER amount,
          // otherwise do nothing
          // TODO: Potential bug -- this assumes that the amount in the next index fulfills requirement;
          //  can be addressed by checking the amount and indexing further is requirement is not met,
          //  but I don't want to over engineer for a case we'll never see
          if (currentAmountIndex < available.length - 1) {
            updateCyberLimit(FIRST_PARTY_CYBER, requestedAmount);
            updateCyberLimit(THIRD_PARTY_CYBER, available[currentAmountIndex + 1]);
          } else {
            // TODO: BUGFIX: limit selector component still looks like it updated even thought we didn't update state
            // maybe make a callback? or just refactor all of this cyber stuff
            debugLogger(
              LEVEL_ERROR,
              'Tried to toggle up first party limit, but hit third party max',
              {
                targetCoverageToken,
                requestedAmount,
                cyberFirst,
                cyberThird,
              }
            );
          }
        }
        break;
      case THIRD_PARTY_CYBER:
      default:
        debugLogger(
          LEVEL_DEBUG,
          `Requested third party limit is greater than first: ${
            requestedAmount <= thirdPartyLimit.amount
          }`
        );
        if (firstPartyLimit.amount <= requestedAmount) {
          // If the REQUESTED THIRD party amount is GREATER than CURRENT FIRST party amount,
          // update THIRD party limit to the REQUESTED amount
          updateCyberLimit(THIRD_PARTY_CYBER, requestedAmount);
        } else {
          // If the FIRST party amount must be DECREASED
          const { amount, available } = firstPartyLimit;
          const currentAmountIndex = available.indexOf(amount);
          // If the CURRENT FIRST party amount is not the MIN available amount,
          // update THIRD party limit to the REQUESTED amount and FIRST party limit to the next LESSER amount,
          // otherwise do nothing
          // TODO: Potential bug -- this assumes that the amount in the next index fulfills requirement;
          //  can be addressed by checking the amount and indexing further is requirement is not met,
          //  but I don't want to over engineer for a case we'll never see
          if (currentAmountIndex > 0) {
            updateCyberLimit(THIRD_PARTY_CYBER, requestedAmount);
            updateCyberLimit(FIRST_PARTY_CYBER, available[currentAmountIndex - 1]);
          } else {
            debugLogger(
              LEVEL_ERROR,
              'Tried to toggle down third party limit, but hit first party min',
              {
                targetCoverageToken,
                requestedAmount,
                cyberFirst,
                cyberThird,
              }
            );
          }
        }
        break;
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    tabAction(newValue);
  };
  const handleLimitOrOutOfPocketChange =
    (coverageToken: string, type: string) => (frequency: string) => (amount: number) => {
      if (isCEM && CYBER_COVERAGES.includes(coverageToken)) {
        handleCEMCyber(coverageToken, amount);
      } else {
        simpleLimitOrOutOfPocketHandler(coverageToken, type, frequency, amount);
      }
    };

  return (
    <Card data-testid="cyber-expanded-card" className="expanded-coverage-card">
      {closeModal && (
        <CloseButton
          data-testid="expanded-coverage-card-close-btn"
          className="close-button-header"
          icon={<Close className="close-icon" />}
          onClick={closeModal}
        />
      )}
      <Grid container>
        <Grid item xs={12} sm={4} className="coverage-card-section">
          <Box>{children}</Box>
          {sharedLimit && (
            <Box
              sx={{
                backgroundColor: theme.designTokens.colorSecondaryLighter,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <LimitOrInfoCard
                amount={sharedLimit.amount}
                available={[]}
                customerEditable={sharedLimit.customerEditable}
                frequency={sharedLimit.frequency}
                type={'limits'}
                label={sharedLimit.label}
                disabled={!accepted}
                coverageToken={coverageTokens[selectedCoverageIndex]}
                handleChange={handleLimitOrOutOfPocketChange}
                productToken={productToken}
              />
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          borderLeft={{ xs: 'none', sm: `1px solid ${theme.designTokens.colorSecondary}` }}
        >
          <Box mt={1} />
          <CoverageTabs
            coverageTokens={coverageTokens}
            selectedCoverageIndex={selectedCoverageIndex}
            handleTabChange={handleTabChange}
          />
          {isMobile && (
            <LimitSection
              accepted={accepted}
              limits={limits}
              coverageTokens={coverageTokens}
              outOfPockets={outOfPockets}
              productToken={productToken}
              selectedCoverageIndex={selectedCoverageIndex}
              handleLimitOrOutOfPocketChange={handleLimitOrOutOfPocketChange}
            />
          )}
          <Box p={2} className="coverage-description">
            <ToggleableText truncatedLines={2}>
              {CoverageDescriptions[coverageTokens[selectedCoverageIndex]]}
            </ToggleableText>
          </Box>
          {!locationSpecific && getSubcoverageDisplayKeys(implicitElements).length > 0 && (
            <Box ml={2}>
              <Typography variant="subtitle2" sx={{ color: theme.designTokens.colorNeutralDark }}>
                Key Highlights
              </Typography>
            </Box>
          )}
          <SubcoverageSection closeModal={closeModal} implicitElements={implicitElements} />
          {locationSpecific && locationCoverageDetails && (
            <LocationCard groupToken={groupToken} locationDetails={locationCoverageDetails} />
          )}
        </Grid>
        {!isMobile && (
          <LimitSection
            accepted={accepted}
            limits={limits}
            coverageTokens={coverageTokens}
            outOfPockets={outOfPockets}
            productToken={productToken}
            selectedCoverageIndex={selectedCoverageIndex}
            handleLimitOrOutOfPocketChange={handleLimitOrOutOfPocketChange}
          />
        )}
        {isCEM && (
          <Grid item xs={12}>
            <Box
              sx={{
                textAlign: 'center',
                borderTop: `1px solid ${theme.designTokens.colorSecondary}`,
              }}
              width="100%"
              p={2}
            >
              <Typography variant="subtitle2" className="text-light">
                Your first party and third party cyber limits are connected.
                <Link
                  sx={{
                    color: theme.designTokens.colorPrimary,
                    marginLeft: '0.25rem',
                  }}
                  underline="hover"
                  onClick={footnoteTabToggle}
                >
                  Check the other tab!
                </Link>
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default CyberExpandedCard;
