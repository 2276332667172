// product uitoken tracking

export const runningListOfUiTokens: Record<string, string> = {
  // product names
  CPP: 'Commercial Package Policy',
  BOP: 'Business Owners Policy',
  CRIME: 'Crime Policy',
  MPL: 'Management & Professional Liability Policy',
  CEM: 'Cyber and Errors & Omissions Policy for Technology',
  NY_D_AND_O: 'Directors & Officers for New York Policy',
  NY_TPL: 'Technology Company Operations Liability Policy',
  // coverage groups
  basic_group: 'General Liability & Business Property',
  hired_and_nonowned_auto_group: 'Hired & Non-Owned Auto',
  employee_benefits_liability_group: 'Employee Benefits Liability',
  directors_and_officers_group: 'Directors & Officers',
  employment_practices_liability_group: 'Employment Practices Liability',
  ny_cyber_group: 'Cyber Coverage',
  ny_directors_and_officers_group: 'Directors & Officers',
  ny_employment_practices_liability_group: 'Employment Practices Liability',
  ny_errors_and_omissions_group: 'Errors & Omissions',
  ny_fiduciary_group: 'Fiduciary Liability',
  errors_and_omissions_group: 'Errors & Omissions',
  cyber_group: 'Cyber Coverage',
  fiduciary_group: 'Fiduciary Liability',
  crime_group: 'Crime Coverage',
  // coverages
  bop_bpp_storage_units: 'Business Property in Storage Units',
  business_income_interruption: 'Business Income Interruption',
  business_personal_property: 'Business Property',
  cem_errors_and_omissions: 'Errors & Omissions',
  computer_fraud: 'Computer Funds & Transfer Fraud',
  counterfeit_money: 'Counterfeit Money',
  crime: 'Crime Coverage',
  cyber: 'Cyber Coverage',
  directors_and_officers: 'Directors & Officers',
  employee_benefits_liability: 'Employee Benefits Liability',
  employee_dishonesty: 'Employee Dishonesty',
  employment_practices_liability: 'Employment Practices Liability',
  errors_and_omissions: 'Errors & Omissions',
  fiduciary: 'Fiduciary Coverage',
  first_party_cyber: 'First-party',
  forgery_and_alteration: 'Forgery & Alteration',
  fraudulent_impersonation: 'Fraudulent Impersonation',
  general_liability: 'General Liability',
  hired_and_nonowned_auto: 'Hired & Non-owned Auto',
  third_party_cyber: 'Third-party',
  // sub coverages
  med_pay: 'Medical Liability Pay',
  breach_response: 'Breach Response Costs',
  cap_table_sublimit_status: 'Cap Table Disputes Coverage',
  cem_public_relations_costs: 'Reputational Harm Coverage',
  cem_breach_response_costs: 'Breach Response Costs',
  cem_bus_interrupt_security_failure: 'Broad Business Interruption Coverage',
  cem_bus_interrupt_system_failure: 'Broad Business Interruption Coverage',
  cem_cont_bus_interrupt_security_failure: 'Broad Third-Party Business Interruption Coverage',
  cem_cont_bus_interrupt_system_failure: 'Broad Third-Party Business Interruption Coverage',
  cem_cyber_crime: 'Cyber Crime Coverage',
  cem_cyber_extortion: 'Cyber Extortions Coverage',
  cem_data_incident_restoration_costs: 'Data Incident Restoration Costs',
  cem_eo_ai_endorsement: 'AI Coverage',
  cem_media_liability: 'Media Liability',
  cem_network_security: 'Network Security Liability',
  cem_pci_violations: 'PCI Violations',
  cem_privacy_liability: 'Privacy Liability',
  cem_privacy_regulatory: 'Privacy Regulatory Liability',
  contractors_coverage: "Contractor's Coverage",
  cyber_crime: 'Cyber Crime Coverage',
  mpl_exclusion_major_shareholder: 'Major Shareholder Inclusion',
  damage_rented_premise: 'Damage to Rented Premises',
  digital_access: 'Digital Accessibility Coverage Expansion',
  ip_sublimit_status: 'IP Claims Defense Costs',
  ny_directors_and_officers: 'Directors & Officers',
  ny_employment_practices_liability: 'Employment Practices Liability',
  ny_fiduciary: 'Fiduciary Coverage',
  ny_cyber: 'Cyber Coverage',
  ny_errors_and_omissions: 'Errors & Omissions',
  ny_d_and_o_entity: 'Entity',
  ny_d_and_o_individual: 'Individual',
  ny_d_and_o_odl: 'Outside Directorship Liability',
  ny_d_and_o_reimbursement: 'Reimbursement',
  ny_d_and_o_tria: 'Tria',
  off_premise_business_property: 'Off Premise Business Property',
  pci_violations: 'PCI Violations',
  personal_and_advertising_injury: 'Personal and Advertising Injury',
  product_and_completed_ops: 'Product and Completed Operations Liability',
  reputational_harm: 'Reputational Harm Coverage',
  tria: '',
  wage_hour_sublimit_status: 'Wage and Hour Claims',
  bop_work_from_anywhere: 'Work From Anywhere',
  eo_speciality_insurance: 'Insurance Agents & Brokers E&O',
  eo_speciality_investment: 'Investment Advisors E&O',
  eo_speciality_lending: 'Lender Liability E&O',
  eo_speciality_real_estate: 'Real Estate Agents E&O',

  wind_hail: 'Wind and Hail Coverage',
  // pricing???
  off_premise_money: 'Off Premise Money',
  on_premise_money: 'Theft of Money & Securities',
  minimum_premium: 'Minimum Premium',
  schedule_rating: 'Schedule Rating',
  rounding: 'Rounding Adjustment',
  partnership_discount: 'Partnership Discount',
  risk_mitigation_discount: 'Risk Mitigation Discount',
  shared_limit_discount: 'Shared Limit Discount',
  bundling_discount: 'Bundling Discount',
  package_discount: 'Package Discount',

  cpp_general_liability: 'General Liability',
  cpp_prop_contents: 'Business Property',
  cpp_prop_work_anywhere: 'Work From Anywhere',
  cpp_gl_damages_prem_rented: 'Damage of Premises Rented to You',
  cpp_gl_digital_access: 'Digital Accessibility Coverage Expansion',
  cpp_gl_med_pay: 'Medical Payments',
  cpp_gl_pai: 'Personal and Advertising Injury',
  cpp_gl_limit_PAI: 'Personal and Advertising Injury Limited',
  cpp_gl_prem_ops: 'Premises and Operations',
  cpp_gl_prod_cops: 'Products and Completed Operations',
  cpp_gl_hnoa: 'Hired & Non-Owned Auto',
  cpp_prop_wind: 'Wind and Hail Coverage',
  cpp_prop_bii: 'Business Income Interruption and Extra Expense',
};

export const limitFrequency = {
  in_aggregate: 'maximum in aggregate',
  calculated_in_aggregate: 'maximum in aggregate',
  per_crime_occurrence: 'per occurrence',
  shared_in_aggregate: 'maximum in aggregate',
  per_occurrence: 'maximum per occurrence',
  occurrence: 'maximum per occurrence',
  per_claim: 'maximum per claim',
  per_employee: 'maximum per employee',
};

export const subCoverageFrequency = {
  in_aggregate: 'in aggregate',
  per_occurrence: 'per occurrence',
  occurrence: 'per occurrence',
  per_claim: 'per claim',
};

export const hiddenElements = {
  bop_bpp_storage_units: true,
  ny_d_and_o_entity: true,
  ny_d_and_o_individual: true,
  ny_d_and_o_odl: true,
  ny_d_and_o_reimbursement: true,
  ny_d_and_o_tria: true,
  counterfeit_money: true,
  off_premise_money: true,
};

export const displayIfAcceptedElements = {
  eo_speciality_insurance: true,
  eo_speciality_investment: true,
  eo_speciality_lending: true,
  eo_speciality_real_estate: true,
};

export const packageTitles = {
  basic: ['Basic Package'],
  requested_coverages: ['Basic Package'],
  recommended: ['Complete Package'],
  renewal: ["Last Year's Package"],
  recommended_renewal: ['Recommended Package'],
  brex_10k_points_basic: ['Basic Package'],
  brex_10k_points_recommended: ['Customizable Package'],
  svb_credit_solutions_basic: ['Basic Package', 'Basic Package'],
  svb_credit_solutions_recommended: ['Customizable Package'],
  svb_credit_solutions_ineligible: ['Customizable Package'],
  svb_edge_basic: ['Basic Package'],
  svb_edge_recommended: ['Customizable Package'],
  vanta_compliant: ['Compliance'],
  vanta_complete: ['Complete'],
  vanta_recommended: ['Complete'],
  carta_basic: ['Carta Investor Basics'],
  carta_recommended: ['Carta Startup Complete'],
  pulley_basic: ['Pulley Investor Basics'],
  pulley_recommended: ['Pulley Startup Complete'],
  // pay_when_you_raise_basic: 'YC Startup Package',
};

export const BannerConfig = {
  'brex-10k': {
    isDiscount: true,
    name: 'Brex',
    title: 'BREX OFFER AVAILABLE',
    subTitle: 'Get up to a 5% discount and 10K Brex Points by using your Brex card at checkout.',
    tooltip:
      'After payment is settled, Brex will add 10,000 points to your account within 45 days.',
  },
  stripe: {
    isDiscount: true,
    name: 'Stripe',
    title: 'STRIPE OFFER AVAILABLE',
    subTitle: 'Save up to 5% on your business insurance policies.',
    tooltip: '',
  },
  wework: {
    isDiscount: true,
    name: 'WeWork',
    title: 'WEWORK INSURANCE ESSENTIALS AVAILABLE',
    subTitle: 'Coverage designed to meet your membership requirements.',
    tooltip: '',
  },
};

export const bannerMap = {
  brex_10k_points_basic: 'brex-10k',
  brex_10k_points_recommended: 'brex-10k',
  // referralPartner values
  stripe: 'stripe',
  wework: 'wework',
};

export const footerText =
  'Vouch employees may also be eligible for additional compensation depending upon a number of factors including premium and policy volume, losses and profitability.';

const brexFooterText =
  'Offer excludes partner coverages. Brex Points will be added to your account within 45 days. Insurance services provided by Vouch Insurance Services, LLC.  Descriptions are general in nature only. Please refer to the terms and conditions of any policies offered or purchased. Insurance products offered are subject to application and underwriting requirements. Not all products available in all states.  Pricing depends on a variety of factors including policyholder location.  Not all discounts available in all states.';

export const footerMap = {
  brex_10k_points_basic: brexFooterText,
  brex_10k_points_recommended: brexFooterText,
};

export const partnerTitleConfig = {
  wework: {
    slug: {
      basic: 'WeWork Insurance Essentials Package',
    },
  },
};
