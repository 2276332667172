/* eslint-disable quotes */
import { packageTitles } from 'shared/ui/tokenNames';

export const isBasic = {
  basic: true,
  requested_coverages: false,
  recommended: false,
  renewal: false,
  recommended_renewal: false,
  brex_10k_points_basic: true,
  brex_10k_points_recommended: false,
  svb_credit_solutions_basic: true,
  svb_credit_solutions_recommended: false,
  svb_credit_solutions_ineligible: false,
  svb_edge_basic: true,
  svb_edge_recommended: false,
  vanta_compliant: false,
  vanta_complete: false,
  vanta_recommended: false,
  carta_basic: false,
  carta_recommended: false,
  pulley_basic: false,
  pulley_recommended: false,
};

const basic = {
  quoteName: packageTitles['basic'],
  description: 'The minimum coverage we recommend to all startups.',
  custom: false,
};

const requested_coverages = {
  quoteName: packageTitles['requested_coverages'],
  description: 'A customizable insurance package with your requested coverages.',
  custom: true,
};

const recommended = {
  quoteName: packageTitles['recommended'],
  description: "A customizable insurance package tailored to meet your startup's risks.",
  recommended: true,
  custom: true,
};

const renewal = {
  quoteName: packageTitles['renewal'],
  description: 'Renew with the same coverages currently active.',
  recommended: false,
  custom: true,
};

const recommended_renewal = {
  quoteName: packageTitles['recommended_renewal'],
  description: "Updated recommendations tailored to meet your startup's new risks",
  recommended: true,
  custom: true,
};

const brex_10k_points_basic = {
  quoteName: packageTitles['brex_10k_points_basic'],
  description: 'The minimum coverage we recommend to all startups.',
  recommended: false,
  custom: false,
};

const brex_10k_points_recommended = {
  quoteName: packageTitles['brex_10k_points_recommended'],
  description: "A customizable insurance package tailored to meet your startup's risks",
  recommended: true,
  custom: true,
};

const svb_credit_solutions_basic = {
  quoteName: packageTitles['svb_credit_solutions_basic'],
  description: 'The minimum coverage we recommend to all startups.',
  recommended: false,
  custom: false,
};

const svb_credit_solutions_recommended = {
  quoteName: packageTitles['svb_credit_solutions_recommended'],
  description: "A customizable insurance package tailored to meet your startup's risks",
  recommended: true,
  custom: true,
};

const svb_credit_solutions_ineligible = {
  quoteName: packageTitles['svb_credit_solutions_ineligible'],
  description: "A customizable insurance package tailored to meet your startup's risks",
  recommended: true,
  custom: true,
};

const svb_edge_basic = {
  quoteName: packageTitles['svb_edge_basic'],
  description: 'The minimum coverage we recommend to all startups.',
  recommended: false,
  custom: false,
};

const svb_edge_recommended = {
  quoteName: packageTitles['svb_edge_recommended'],
  description: "A customizable insurance package tailored to meet your startup's risks",
  recommended: true,
  custom: true,
};

const vanta_compliant = {
  quoteName: packageTitles['vanta_compliant'],
  description:
    "A simple package that covers only the cost of cyber incident recovery and damages to users. Meets Vanta's requirements.",
  recommended: false,
  custom: true,
};

const vanta_complete = {
  quoteName: packageTitles['vanta_complete'],
  description:
    "A package that covers the cost of cyber incident recovery and damages to users, and claims arising from software errors. Meets Vanta's requirements and recommended by Vouch.",
  recommended: true,
  custom: true,
};

const vanta_recommended = {
  quoteName: packageTitles['vanta_recommended'],
  description:
    "A comprehensive insurance package that covers a wide range of startup claims. Exceeds Vanta's requirements.",
  recommended: true,
  custom: true,
};

const carta_basic = {
  quoteName: packageTitles['carta_basic'],
  description: 'The minimum coverage to satisfy typical investor and board requirements.',
  recommended: false,
  custom: true,
};

const carta_recommended = {
  quoteName: packageTitles['carta_recommended'],
  description:
    'A complete insurance package that covers a wide range of startup claims. Exceeds investor requirements.',
  recommended: true,
  custom: true,
};

const pulley_basic = {
  quoteName: packageTitles['pulley_basic'],
  description: 'The minimum coverage to satisfy typical investor and board requirements.',
  recommended: false,
  custom: true,
};

const pulley_recommended = {
  quoteName: packageTitles['pulley_recommended'],
  description:
    'A complete insurance package that covers a wide range of startup claims. Exceeds investor requirements.',
  recommended: true,
  custom: true,
};

export default {
  basic,
  requested_coverages,
  recommended,
  renewal,
  recommended_renewal,
  brex_10k_points_basic,
  brex_10k_points_recommended,
  svb_credit_solutions_basic,
  svb_credit_solutions_recommended,
  svb_credit_solutions_ineligible,
  svb_edge_basic,
  svb_edge_recommended,
  vanta_compliant,
  vanta_complete,
  vanta_recommended,
  carta_basic,
  carta_recommended,
  pulley_basic,
  pulley_recommended,
};
