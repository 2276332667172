import { FC, ReactElement } from 'react';
import {
  Card,
  CardContent,
  Container,
  Grid,
  PrimaryButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@vouch/ui';
import './QuoteCard.scss';
import { formatCents } from 'shared/helpers';

export type QuoteCardProps = {
  quoteName: string;
  description: string;
  // Need to see how the props are going to look like on this page
  coverageList: Array<string>;
  cost: number;
  specialOffer?: boolean;
  recommended?: boolean;
  selectQuote: () => void;
};

const QuoteCard: FC<QuoteCardProps> = ({
  quoteName,
  description,
  coverageList,
  cost,
  recommended,
  specialOffer,
  selectQuote,
}): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card
      className="quote-card"
      onClick={selectQuote}
      data-testid="quote-card"
      sx={{ outline: `1px solid ${theme.designTokens.colorSecondary}` }}
    >
      <CardContent className="card-content">
        {specialOffer && (
          <div className="special-offer">
            <Typography
              component="span"
              variant="subtitle1"
              data-testid="quote-card-special-offer"
              sx={{ fontFamily: theme.typography.button.fontFamily }}
            >
              Special Offer
            </Typography>
          </div>
        )}

        {recommended && (
          <div className="recommended">
            <Typography
              component="span"
              data-testid="quote-card-recommended"
              sx={{
                color: theme.designTokens.colorPrimaryDarker,
                fontFamily: theme.typography.button.fontFamily,
              }}
            >
              Recommended
            </Typography>
          </div>
        )}

        {!specialOffer && !recommended && <div className="top-padding">&nbsp;</div>}

        <Typography fontSize={24} fontWeight={600}>
          {quoteName}
        </Typography>

        {/* Package Description */}
        <Container className="description-container">
          {description && (
            <div className="description">
              <Typography
                sx={{
                  color: theme.designTokens.colorBlack,
                  fontWeight: 400,
                }}
              >
                {description}
              </Typography>
            </div>
          )}
          {coverageList?.length > 0 && (
            <div className="including">
              <Typography variant="detail" sx={{ fontSize: theme.typography.body1.fontSize }}>
                Includes
              </Typography>

              <ul className="coverage-list">
                {coverageList.map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ul>
            </div>
          )}
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap-reverse"
            mt={5}
          >
            <Grid item>
              <PrimaryButton sx={{ width: !isMobile ? '166px' : 'auto', height: '47px' }}>
                Continue
              </PrimaryButton>
            </Grid>
            <Grid item>
              <Typography variant="h4">{formatCents(cost)}</Typography>
              <Typography align="right">PER YEAR</Typography>
            </Grid>
          </Grid>
        </Container>
      </CardContent>
    </Card>
  );
};

export default QuoteCard;
