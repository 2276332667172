import { Product } from 'features/quotes/quotes.types';
import { CoveragesSet } from 'features/coverages/coverages.types';

export const BASE_LAYOUT_TEMPLATE: Record<string, string[]> = {
  CPP: ['basic_group', 'hired_and_nonowned_auto_group', 'employee_benefits_liability_group'],
  BOP: ['basic_group', 'hired_and_nonowned_auto_group', 'employee_benefits_liability_group'],
  MPL: [
    'directors_and_officers_group',
    'employment_practices_liability_group',
    'fiduciary_group',
    'errors_and_omissions_group',
    'cyber_group',
  ],
  CEM: ['errors_and_omissions_group', 'cyber_group'],
  NY_TPL: [
    'ny_employment_practices_liability_group',
    'ny_fiduciary_group',
    'ny_errors_and_omissions_group',
    'ny_cyber_group',
  ],
  NY_D_AND_O: ['ny_directors_and_officers_group'],
  CRIME: ['crime_group'],
};

// basic_group: any
// hired_and_nonowned_auto_group: any
// employee_benefits_liability_group: any
// directors_and_officers_group: CEM, (NY_TPL && MPL), (!NY_TPL && !NY_D_AND_O)
// employment_practices_liability_group: CEM, (NY_TPL && MPL), (!NY_TPL && !NY_D_AND_O)
// fiduciary_group: CEM, (NY_TPL && MPL), (!NY_TPL && !NY_D_AND_O)
// errors_and_omissions_group: CEM, (!NY_TPL && !NY_D_AND_O)
// cyber_group: CEM, (!NY_TPL && !NY_D_AND_O)
// ny_employment_practices_liability_group: NY_D_AND_O, (NY_TPL && !MPL)
// ny_fiduciary_group: NY_D_AND_O, (NY_TPL && !MPL)
// ny_errors_and_omissions_group: NY_D_AND_O, NY_TPL
// ny_cyber_group: NY_D_AND_O, NY_TPL
// ny_directors_and_officers_group: NY_D_AND_O, (NY_TPL && !MPL)
// crime_group: any

type SectionLayout = {
  productToken: string;
  coverageGroupsLayout: string[];
  alternateTitle?: string;
};
type PageLayout = SectionLayout[];
export const getLayoutFor = (products: Record<string, Product>): PageLayout => {
  const layout: PageLayout = [];

  if (products.CPP) {
    layout.push({
      productToken: 'CPP',
      coverageGroupsLayout: BASE_LAYOUT_TEMPLATE.CPP,
    });
  } else {
    layout.push({
      productToken: 'BOP',
      coverageGroupsLayout: BASE_LAYOUT_TEMPLATE.BOP,
    });
  }

  if (products.CEM) {
    layout.push({
      productToken: 'MPL',
      coverageGroupsLayout: BASE_LAYOUT_TEMPLATE.MPL.slice(0, 3),
    });
    layout.push({ productToken: 'CEM', coverageGroupsLayout: BASE_LAYOUT_TEMPLATE.CEM });
  } else if (products.NY_D_AND_O) {
    layout.push({
      productToken: 'NY_TPL',
      coverageGroupsLayout: BASE_LAYOUT_TEMPLATE.NY_TPL,
    });
    layout.push({
      productToken: 'NY_D_AND_O',
      coverageGroupsLayout: BASE_LAYOUT_TEMPLATE.NY_D_AND_O,
    });
  } else if (products.NY_TPL) {
    if (products.MPL) {
      layout.push({
        productToken: 'NY_TPL',
        coverageGroupsLayout: BASE_LAYOUT_TEMPLATE.NY_TPL.slice(2),
      });
      layout.push({
        productToken: 'MPL',
        coverageGroupsLayout: BASE_LAYOUT_TEMPLATE.MPL.slice(0, 3),
      });
    } else {
      layout.push({
        productToken: 'NY_TPL',
        coverageGroupsLayout: BASE_LAYOUT_TEMPLATE.NY_TPL,
      });
      layout.push({
        productToken: 'NY_D_AND_O',
        coverageGroupsLayout: BASE_LAYOUT_TEMPLATE.NY_D_AND_O,
      });
    }
  } else {
    layout.push({
      productToken: 'MPL',
      coverageGroupsLayout: BASE_LAYOUT_TEMPLATE.MPL,
    });
  }

  layout.push({
    productToken: 'CRIME',
    coverageGroupsLayout: BASE_LAYOUT_TEMPLATE.CRIME,
  });
  return layout;
};

// TODO: Resolve hidden tria on BOP; this is also linked to features/coverageGroups/coverageGroupsSlice.ts:39
export const COVERAGE_ORDER: Record<string, (keyof CoveragesSet)[]> = {
  basic_group: [
    'general_liability',
    'business_personal_property',
    'cpp_general_liability',
    'cpp_prop_contents',
  ],
};

// Order: GL, HNOA, EBL, Cyber, E&O, D&O, EPL, Fiduciary, Crime
export const COVERAGE_DISPLAY_ORDER = [
  'basic_group',
  'hired_and_nonowned_auto_group',
  'employee_benefits_liability_group',
  'ny_employment_practices_liability_group',
  'cyber_group',
  'ny_cyber_group',
  'errors_and_omissions_group',
  'ny_errors_and_omissions_group',
  'directors_and_officers_group',
  'ny_directors_and_officers_group',
  'employment_practices_liability_group',
  'fiduciary_group',
  'ny_fiduciary_group',
  'crime_group',
];
