import { configureStore } from '@reduxjs/toolkit';
import packagesReducer from './features/packages/packagesSlice';
import sessionReducer, { SessionState } from './features/session/sessionSlice';
import quotesReducer from './features/quotes/quotesSlice';
import coveragesReducer from './features/coverages/coveragesSlice';
import vouchTelemetry from './middlewares/vouchTelemetry';
import { Action, Dispatch, Middleware } from 'redux';
import { PackagesState } from './features/packages/packages.types';
import { QuotesState } from './features/quotes/quotes.types';
import { CoveragesState } from './features/coverages/coverages.types';
import coverageGroupsReducer, {
  CoverageGroupState,
} from './features/coverageGroups/coverageGroupsSlice';
import errorsReducer, { ErrorsState } from './features/errors/errorsSlice';

export interface State {
  coverageGroups: CoverageGroupState;
  coverages: CoveragesState;
  packages: PackagesState;
  quotes: QuotesState;
  session: SessionState;
  errors: ErrorsState;
}
export interface MiddlewareAPI<D extends Dispatch = Dispatch, S = State> {
  dispatch: D;
  getState(): S;
}

export interface AnyAction extends Action {
  // Allows any extra properties to be defined in an action.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [extraProps: string]: any;
}

const vouchMiddlewares = (getDefaultMiddleware: () => Middleware[]) =>
  getDefaultMiddleware().concat(vouchTelemetry);

// configureStore combines reducers, adds redux-thunk, redux devtools & other middleware
export const config = {
  reducer: {
    coverageGroups: coverageGroupsReducer,
    coverages: coveragesReducer,
    errors: errorsReducer,
    packages: packagesReducer,
    quotes: quotesReducer,
    session: sessionReducer,
  },
  middleware: vouchMiddlewares,
};

const store = configureStore(config);

export default store;
