import { FC, ReactElement } from 'react';
import { AddIcon, IconButton, RemoveIcon, Theme } from '@vouch/ui';

type ButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
};

const buttonTheme = (theme: Theme) => ({
  backgroundColor: theme.designTokens.colorPrimary,
  width: '28px',
  height: '28px',
  svg: {
    color: theme.designTokens.colorWhite,
  },
  '&:hover': {
    backgroundColor: theme.designTokens.colorPrimaryLightest,
  },
  '&:disabled': {
    backgroundColor: theme.designTokens.colorNeutralLight,
    svg: {
      color: theme.designTokens.colorWhite,
    },
  },
});

export const MinusButton: FC<ButtonProps> = (props: ButtonProps): ReactElement => (
  <IconButton sx={(theme) => buttonTheme(theme)} {...props} size="large">
    <RemoveIcon />
  </IconButton>
);
export const PlusButton: FC<ButtonProps> = (props: ButtonProps): ReactElement => (
  <IconButton sx={(theme) => buttonTheme(theme)} {...props} size="large">
    <AddIcon />
  </IconButton>
);
