import { Box, InfoOutlined, Tooltip, Typography } from '@vouch/ui';
import { FC, ReactElement } from 'react';

export type TransactionFeeDisclosureProps = { content: string | ReactElement };

export const TransactionFeeDisclosure: FC<TransactionFeeDisclosureProps> = (
  props
): ReactElement => (
  <Box
    sx={(theme) => ({
      color: theme.designTokens.colorNeutralDark,
      marginBottom: '80px',
      marginTop: '10px',
    })}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Typography variant="detail">{props.content}</Typography>
    <Box ml={0.75} display={'inline-flex'}>
      <Tooltip title="Transaction fees are calculated based on your payment method, which you’ll select at checkout">
        <InfoOutlined sx={{ fontSize: 20 }} />
      </Tooltip>
    </Box>
  </Box>
);
