import { ReactElement } from 'react';
import { ButtonProps } from '@mui/material';
import BaseButton from './BaseButton';

const TertiaryButton = (props: ButtonProps): ReactElement => {
  return BaseButton({
    ...props,
    color: 'secondary',
    variant: 'contained',
  });
};

export default TertiaryButton;
