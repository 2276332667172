import {
  aiCoverageBannerViewed,
  aiCoverageRequestButtonClicked,
  backToPackages,
  changeEffectiveDate,
  chatWidgetAutoOpened,
  chatWidgetClicked,
  coverageCardClicked,
  coverageTabChange,
  discretionRequested,
  exitPrintView,
  logExpiredApplication,
  logExpiredQuote,
  notificationModalClosed,
  notOfferedRequestCoverageClicked,
  quoteRePriced,
  quoteViewed,
  quoteDownloaded,
  toggleDefinitionPanel,
  viewPrintView,
} from './eventTracking.actions';
import { selectPackage, setPackagesOrder } from 'features/packages/packages.actions';
import { toggleCoverageGroupAcceptance } from 'features/coverageGroups/coverageGroupsSlice';
import { updateLimitOrOutOfPocketAmount } from 'features/coverages/coverages.actions';
import { requestCheckout, sendToBlockPage } from 'features/session/sessionSlice';
import { sendToCrumbs } from 'features/errors/errors.actions';

// Alphabetical by string value
const SegmentEventNames = {
  [aiCoverageBannerViewed.toString()]: 'AI Coverage Banner Viewed',
  [aiCoverageRequestButtonClicked.toString()]: 'AI Coverage Request Now Button Clicked',
  [backToPackages.toString()]: 'Back to Package Screen Event',
  [coverageCardClicked.toString()]: 'Coverage Card Clicked',
  [chatWidgetAutoOpened.toString()]: 'Chat Widget Auto Opened',
  [chatWidgetClicked.toString()]: 'Chat Widget Clicked',
  [toggleCoverageGroupAcceptance.toString()]: 'Coverage Card Toggled',
  [sendToCrumbs.toString()]: 'Crumbs Page Displayed',
  [discretionRequested.toString()]: 'Discretion Requested',
  [changeEffectiveDate.toString()]: 'Effective Date Changed',
  [exitPrintView.toString()]: 'Exit Quote Print View',
  [logExpiredApplication.toString()]: 'Expired Application',
  [logExpiredQuote.toString()]: 'Expired Quote',
  [updateLimitOrOutOfPocketAmount.toString()]: 'Limit Changed',
  [notificationModalClosed.toString()]: 'Notification modal closed',
  [notOfferedRequestCoverageClicked.toString()]: 'Request Not Offered Coverage Clicked',
  [requestCheckout.toString()]: 'Quote Completed',
  [quoteRePriced.toString()]: 'Quote Re-Priced',
  [quoteViewed.toString()]: 'Quote Viewed',
  [quoteDownloaded.toString()]: 'Quote Downloaded',
  [setPackagesOrder.toString()]: 'Package Screen Viewed',
  [selectPackage.toString()]: 'Package Selected',
  [coverageTabChange.toString()]: 'Subcoverage Clicked',
  [toggleDefinitionPanel.toString()]: 'Toggle Definition Panel',
  [viewPrintView.toString()]: 'View Quote Print View',
  [sendToBlockPage.toString()]: 'Quote Block Viewed',

  // Currently unsupported events from Vue quoting
  // 'quotes/downloadQuote': 'Quote Downloaded',
  // 'Partnered Coverage Added'
  // 'Partnered Coverage Removed'
};

// Limit Changed
// priceBeforeRePrice: this.originalValue,
// limitChange,
// newLimit: newValue,
// coverage: this.coverageKey,

export default SegmentEventNames;
