export const gridBase = 10;
const base = (x: number): number => {
  return gridBase * x;
};

export const space = {
  Xsmall: base(0.5),
  Small: base(1),
  Medium: base(2),
  Large: base(4),
  Xlarge: base(8),
  Xxlarge: base(16),
  Xxxlarge: base(32),
};
export default base;
