import { Theme } from '@mui/material';

const MuiPickersCalendarHeader = {
  styleOverrides: {
    label: ({ theme }: { theme: Theme }) => ({
      color: theme.designTokens.colorBlack,
      fontWeight: 700,
    }),
    switchViewButton: {
      padding: 0,
      '.MuiSvgIcon-root': {
        height: '24px',
        width: '24px',
      },
    },
  },
};

export default MuiPickersCalendarHeader;
