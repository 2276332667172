import { Container, Grid, Typography } from '@vouch/ui';
import ChatContainer from 'containers/ChatButtonContainer';
import { FC } from 'react';

const Crumbs: FC = (): JSX.Element => {
  return (
    <Container>
      <Grid
        alignItems="center"
        className="loading-page"
        container
        data-testid="crumbs-page"
        justifyContent="center"
        textAlign="center"
        minHeight="100vh"
      >
        <Grid item className="loading-title" sm={10} md={6}>
          <Typography variant="h4" mb={1}>
            Something went sideways.
          </Typography>
          <Typography variant="body1" mb={2}>
            {`We're sorry - an engineer has been alerted, and a Vouch pro will reach out once we can
          recover from the situation.`}
          </Typography>
          <ChatContainer />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Crumbs;
