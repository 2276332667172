import { Stack } from '@mui/material';
import React, { FC, ReactElement } from 'react';

type ActionSetProps = {
  actions: ReactElement[];
};
const ActionSet: FC<ActionSetProps> = ({ actions }): ReactElement => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {actions.map((action, index) => (
        <React.Fragment key={index}>{action}</React.Fragment>
      ))}
    </Stack>
  );
};

export default ActionSet;
