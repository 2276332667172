import { createTheme } from '@mui/material';

import Colors from './colors.module.scss';
import VouchFonts from './vouch/typography.module.scss';
import VantaFonts from './vanta/typography.module.scss';
import breakpoints from './breakpoints';
import components from './components/';
import chroma from 'chroma-js';
import { Experience } from '../experience-provider/ExperienceProvider';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    desktop: true;
  }
}

declare module '@mui/material/styles' {
  interface PaletteColor {
    50: string;
    100: string;
    A100: string;
    200: string;
    A200: string;
    300: string;
    400: string;
    A400: string;
    500: string;
    600: string;
    700: string;
    A700: string;
    800: string;
    900: string;
  }
  interface PaletteOptions {
    khaki: Omit<PaletteColor, 'main' | 'light' | 'dark'>;
    mango: Pick<PaletteColor, 100 | 200 | 300 | 400>;
    green: Pick<PaletteColor, 'light' | 'main' | 'dark'>;
  }
  interface Palette {
    khaki: PaletteColor;
    mango: PaletteColor;
    green: PaletteColor;
  }
}

const isLight = (color: string) => chroma(color).luminance() > 0.5;

interface DesignTokens {
  fontScale: number;
  surfaceCornerRadius: string;

  colorPrimary: string;
  colorPrimaryDarker: string;
  colorPrimaryLighter: string;
  colorPrimaryLightest: string;
  colorSecondary: string;
  colorSecondaryLighter: string;
  colorSecondaryDarker: string;
  colorSecondaryDarkest: string;
  colorBlack: string;
  colorWhite: string;
  colorNeutralLight: string;
  colorNeutralDark: string;
  colorError: string;
  colorAttention: string;
  contrastText: string; // dark or light color, readable on colorPrimary
  colorBackground: string;
  backgroundContrastText: string;

  buttonFontSize: string;
  buttonLetterSpacing: string;
  buttonTextCase:
    | 'capitalize'
    | 'full-size-kana'
    | 'full-width'
    | 'lowercase'
    | 'none'
    | 'uppercase';
}

declare module '@mui/material/styles' {
  interface Theme {
    designTokens: DesignTokens;
  }

  interface ThemeOptions {
    designTokens?: DesignTokens;
  }
}

const VOUCH_COLORS = {
  // colors from Colors.scss breaking unit tests.
  vouchGreen: '#3BE0AD', // Colors.primaryMain
  primaryDarkGreen: '#19A078', // Colors.primaryDark
  primaryLightGreen: '#42F1BB', // Colors.primaryLight
  primaryLightestGreen: '#D2F5E5', // Colors.secondaryDark
  khaki: '#DBE2CA', // Colors.khaki
  khaki100: '#F8FAF2', // Colors.khaki100
  khaki300: '#AFB899', // Colors.khaki300
  khaki400: '#81896E', // Colors.khaki400
  primaryDarkBlue: '#0f1C2C', // Colors.textPrimary
  white: '#FFFFFF', // Colors.white
  neutralLight: '#F6F6F6', // Colors.textDeemphasized
  black300: '#B7BBC0', // Colors.textDisabled
  red: '#CD0019', // Colors.errorMain
  mango400: '#E49114', // Colors.warningMain
};

/* eslint-disable */
const vouchTheme: DesignTokens = {
  fontScale: 1,
  surfaceCornerRadius: '4px',
  buttonFontSize: '14px',
  buttonLetterSpacing: '1px',
  buttonTextCase: 'uppercase',
  colorPrimary: VOUCH_COLORS.vouchGreen, // $vouch-green,              #3BE0AD, $vouch-primary-green
  colorPrimaryDarker: VOUCH_COLORS.primaryDarkGreen, // $vouch-primary-dark-green, #1a5745
  colorPrimaryLighter: VOUCH_COLORS.primaryLightGreen, // $vouch-primary-light-green,#42F1BB, $vouch-primary-green-for-light-bg
  colorPrimaryLightest: VOUCH_COLORS.primaryLightestGreen, //                            #D2F5E5
  colorSecondary: VOUCH_COLORS.khaki, // $khaki                     #DBE2CA
  colorSecondaryLighter: VOUCH_COLORS.khaki100, // $khaki-100                 #F8FAF2, $vouch-tint-light
  colorSecondaryDarker: VOUCH_COLORS.khaki300, // $khaki-300                 #AFB899, $vouch-placeholder
  colorSecondaryDarkest: VOUCH_COLORS.khaki400, // $khaki-400                 #81896E
  colorBackground: VOUCH_COLORS.khaki100,
  colorBlack: VOUCH_COLORS.primaryDarkBlue, // $vouch-primary-dark-blue,  #0f1C2C, Colors.primaryContrastText
  colorWhite: VOUCH_COLORS.white, // $white                     #FFFFFF
  colorNeutralLight: VOUCH_COLORS.neutralLight, // $neutral-light,            #F6F6F6
  colorNeutralDark: VOUCH_COLORS.black300, // $black-300,                #B7BBC0
  colorError: VOUCH_COLORS.red, // $red,                      #CD0019
  colorAttention: VOUCH_COLORS.mango400, // $mango-400,                #E49114, $special-offer-dark, $vouch-dark-orange
  contrastText: Colors.textPrimary,
  backgroundContrastText: Colors.textPrimary,
};
/* eslint-enable */
const VANTA_COLORS = {
  grey: '#7D7F82', // gray-700 in Figma, lighter than MUI 700
  lightGrey: '#F8F6F6',
  darkGrey: '#F0EDED',
  darkSlateGrey: '#545352',
  crimson: '#BA474A',
  sienna: '#AC5A23',
  bluishPurple: '#833BDB',
  bluishPurpleDarker: '#6D27C3',
  paleLavender: '#DAC0FB',
  veryLightPink: '#F6F0FD',
  black: Colors.textPrimary, // $vouch-primary-dark-blue
  white: Colors.white,
};

/* eslint-disable */
const vantaTheme: DesignTokens = {
  fontScale: 1,
  surfaceCornerRadius: '4px',
  buttonFontSize: '14px',
  buttonLetterSpacing: '1px',
  buttonTextCase: 'capitalize',
  colorPrimary: VANTA_COLORS.bluishPurple,
  colorPrimaryDarker: VANTA_COLORS.bluishPurpleDarker,
  colorPrimaryLighter: VANTA_COLORS.paleLavender,
  colorPrimaryLightest: VANTA_COLORS.veryLightPink,
  colorSecondary: VANTA_COLORS.darkGrey,
  colorSecondaryLighter: VANTA_COLORS.lightGrey,
  colorSecondaryDarker: VANTA_COLORS.darkSlateGrey,
  colorSecondaryDarkest: VANTA_COLORS.darkSlateGrey, // secondary darkest not in spec
  colorBackground: VANTA_COLORS.white,
  colorBlack: VANTA_COLORS.black,
  colorWhite: VANTA_COLORS.white,
  colorNeutralLight: VANTA_COLORS.lightGrey,
  colorNeutralDark: VANTA_COLORS.grey,
  colorError: VANTA_COLORS.crimson,
  colorAttention: VANTA_COLORS.sienna,
  contrastText: VANTA_COLORS.white,
  backgroundContrastText: VANTA_COLORS.black,
};
/* eslint-enable */

export const experienceTheme = ({ experience = 'vouch' }: { experience: Experience }) => {
  const activeTheme = experience === 'vanta' ? vantaTheme : vouchTheme;
  const themeFont = experience === 'vanta' ? VantaFonts : VouchFonts;

  const defaultFontOptions = {
    fontFamily: themeFont.familySans,
    fontStyle: themeFont.styleNormal,
    fontWeight: themeFont.weightNormal,
  };

  return createTheme({
    components,
    designTokens: activeTheme,
    palette: {
      tonalOffset: 0.2,
      primary: {
        light: activeTheme.colorPrimaryLighter,
        main: activeTheme.colorPrimary,
        dark: activeTheme.colorPrimaryDarker,
        contrastText: isLight(activeTheme.colorPrimary)
          ? activeTheme.colorBlack
          : activeTheme.colorWhite,
        ...createPalette(
          [
            activeTheme.colorPrimaryLighter,
            activeTheme.colorPrimary,
            activeTheme.colorPrimaryDarker,
          ],
          [300, 500, 700]
        ),
      },
      secondary: {
        light: Colors.secondaryLight,
        main: Colors.secondaryMain,
        dark: Colors.secondaryDark,
        contrastText: Colors.secondaryContrastText,
      },
      error: {
        main: activeTheme.colorError,
        contrastText: Colors.errorContrastText,
      },
      warning: {
        light: Colors.warningLight,
        main: Colors.warningMain,
        dark: Colors.warningDark,
        contrastText: Colors.warningContrastText,
      },
      info: {
        light: Colors.infoLight,
        main: Colors.infoMain,
        dark: Colors.infoDark,
        contrastText: Colors.infoContrastText,
      },
      success: {
        light: Colors.successLight,
        main: Colors.successMain,
        dark: Colors.successDark,
        contrastText: Colors.successContrastText,
      },
      grey: {
        50: Colors.grey50,
        100: Colors.grey100,
        200: Colors.grey200,
        300: Colors.grey300,
        400: Colors.grey400,
        500: Colors.grey500,
        600: Colors.grey600,
        700: Colors.grey700,
        800: Colors.grey800,
        900: Colors.grey900,
      },
      text: {
        primary: Colors.textPrimary,
        disabled: Colors.textDisabled,
      },
      khaki: {
        contrastText: '#of1c2c',
        ...createPalette(['#F8FAF2', '#EFF4E4', '#afb899'], [100, 200, 400]),
      },
      mango: {
        100: Colors.mango100,
        200: Colors.mango200,
        300: Colors.mango300,
        400: Colors.mango400,
      },
      green: {
        light: Colors.primaryLight,
        main: Colors.primaryMain,
        dark: Colors.vouchPrimaryDarkGreen,
      },
    },
    typography: {
      fontFamily: themeFont.familySans,
      fontWeightBold: themeFont.weightBold,
      h1: {
        ...defaultFontOptions,
        fontFamily: themeFont.familySerif,
        fontSize: themeFont.sizeH1Mobile,
        [breakpoints.up('tablet')]: {
          fontSize: themeFont.sizeH1Desktop,
        },
      },
      h2: {
        ...defaultFontOptions,
        fontFamily: themeFont.familySerif,
        fontSize: themeFont.sizeH2Mobile,
        [breakpoints.up('tablet')]: {
          fontSize: themeFont.sizeH2Desktop,
        },
      },
      h3: {
        ...defaultFontOptions,
        fontSize: themeFont.sizeH3Mobile,
        fontWeight: themeFont.weightNormal,
        fontFamily: themeFont.familySerif,
      },
      h3_numeric: {
        ...defaultFontOptions,
        fontSize: themeFont.sizeH3Mobile,
        fontWeight: themeFont.weightMedium,
        fontFamily: themeFont.familySansExtended,
      },
      h4: {
        ...defaultFontOptions,
        fontWeight: themeFont.weightBold,
        fontSize: themeFont.sizeH4,
      },
      h5: {
        ...defaultFontOptions,
        fontWeight: themeFont.weightBold,
        fontSize: themeFont.sizeH5,
        lineHeight: '20px',
      },
      subtitle1: {
        ...defaultFontOptions,
        fontSize: themeFont.sizeSubtitle,
        lineHeight: '25.16px',
      },
      body1: {
        ...defaultFontOptions,
        fontSize: themeFont.sizeBody,
      },
      caption: {
        ...defaultFontOptions,
        fontSize: themeFont.sizeCaption,
        lineHeight: '1.4em',
      },
      detail: {
        ...defaultFontOptions,
        color: Colors.grey600,
        lineHeight: '17.61px',
      },
      button: {
        ...defaultFontOptions,
        fontFamily: themeFont.familySansExtended,
        fontWeight: themeFont.weightBold,
        fontSize: activeTheme.buttonFontSize,
        textTransform: activeTheme.buttonTextCase,
        alignContent: 'center',
        letterSpacing: activeTheme.buttonLetterSpacing,
        lineHeight: '17.61px',
      },
      overline: {
        ...defaultFontOptions,
        fontSize: themeFont.sizeOverline,
      },
      label: {
        ...defaultFontOptions,
        fontSize: themeFont.sizeLabel,
        textTransform: 'uppercase',
        letterSpacing: '1px',
        color: Colors.grey500,
      },
      menu: {
        fontSize: themeFont.sizeMenu,
        fontWeight: themeFont.weightMedium,
      },
    },
  });
};

const theme = experienceTheme({ experience: 'vouch' });
export default theme;

interface Palette {
  50: string;
  100: string;
  A100: string;
  200: string;
  A200: string;
  300: string;
  400: string;
  A400: string;
  500: string;
  600: string;
  700: string;
  A700: string;
  800: string;
  900: string;
}
function createPalette(points: string[], domain: number[]): Palette {
  const scale = chroma.scale(points).domain(domain);
  const levels = ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900'];
  const saturated = ['100', '200', '400', '700'];
  const int = levels.reduce(
    (acc, idx) => ({
      ...acc,
      [idx]: scale(+idx).hex(),
    }),
    {}
  );
  return saturated.reduce(
    (acc, idx) => ({
      ...acc,
      [`A${idx}`]: scale(+idx).saturate(1).hex(),
    }),
    int
  ) as Palette;
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsVariantOverrides {
    quote: true;
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    quote: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    menu: true;
    tertiary: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    detail: true;
    h3_numeric: true;
    label: true;
    menu: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    detail: React.CSSProperties;
    h3_numeric: React.CSSProperties;
    label: React.CSSProperties;
    menu: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    detail?: React.CSSProperties;
    h3_numeric?: React.CSSProperties;
    label?: React.CSSProperties;
    menu?: React.CSSProperties;
  }
}
