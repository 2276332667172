import { createSlice } from '@reduxjs/toolkit';
import { State } from 'store';
import { Product } from 'features/quotes/quotes.types';

export type CoverageGroupState = {
  idToUiToken: Record<string, string>;
  coverageGroupAcceptance: Record<string, Record<string, boolean>>;
  loadedQuoteId: string;
};

const initialState: CoverageGroupState = {
  idToUiToken: {},
  coverageGroupAcceptance: {},
  loadedQuoteId: '',
};
const coverageGroupsSlice = createSlice({
  name: 'coverageGroups',
  initialState,
  reducers: {
    loadCoverageGroupIdToTokenMap(state, { payload }) {
      const products: Record<string, Product> = payload.products;
      const idToUiToken: Record<string, string> = {};
      Object.values(products).forEach((product) =>
        Object.values(product.coverageGroups).forEach((group) => {
          idToUiToken[group.id] = group.uiToken;
        })
      );
      state.idToUiToken = idToUiToken;
    },
    loadAcceptedCoverageGroups(state, { payload }) {
      const { quoteId } = payload;
      if (quoteId === state.loadedQuoteId) return;
      const products: Record<string, Product> = payload.products;
      const coverageGroupAcceptance: Record<string, Record<string, boolean>> = {};
      Object.values(products).forEach((product) =>
        Object.values(product.coverageGroups).forEach((group) => {
          Object.values(group.elements).forEach((element) => {
            // TODO: Resolve hidden tria on BOP; this is also linked to shared/ui/layoutUtils.ts:81
            if (element.uiToken === 'tria') return;
            const acceptance = coverageGroupAcceptance[group.uiToken] || {};
            acceptance[element.uiToken] = element.accepted;
            coverageGroupAcceptance[group.uiToken] = acceptance;
          });
        })
      );
      state.coverageGroupAcceptance = coverageGroupAcceptance;
      state.loadedQuoteId = quoteId;
    },
    toggleCoverageGroupAcceptance(state, { payload }) {
      const groupToken: string = payload.groupToken;
      const groupElements = state.coverageGroupAcceptance[groupToken] || [];
      Object.entries(groupElements).forEach(([elementToken, currentAcceptance]) => {
        state.coverageGroupAcceptance[groupToken][elementToken] = !currentAcceptance;
      });
    },
  },
});
export const coverageGroupsSelector = (state: State): CoverageGroupState => state.coverageGroups;
export const idToUiTokens = (state: State): Record<string, string> =>
  coverageGroupsSelector(state).idToUiToken;
export const quoteIdOfLoadedCoverages = (state: State): string =>
  coverageGroupsSelector(state).loadedQuoteId;
export const coverageGroupAcceptance = (state: State): Record<string, Record<string, boolean>> =>
  coverageGroupsSelector(state).coverageGroupAcceptance;
export const getTokenFor =
  (id: string) =>
  (state: State): string =>
    coverageGroupsSelector(state).idToUiToken[id];
export const {
  loadAcceptedCoverageGroups,
  loadCoverageGroupIdToTokenMap,
  toggleCoverageGroupAcceptance,
} = coverageGroupsSlice.actions;
const coverageGroupsReducer = coverageGroupsSlice.reducer;
export default coverageGroupsReducer;
