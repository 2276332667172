import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { Quote, QuotesState } from './quotes.types';
import { State } from 'store';

export const emptyQuote: Quote = {
  id: '',
  applicationId: '',
  acceptedCoverages: {},
  availableEffectiveDate: {
    defaultDate: '',
    earliestDate: '',
    latestDate: '',
    locked: false,
  },
  metadata: {
    isAccepted: false,
    createdAt: '',
    expiresOn: '',
  },
  pricing: {
    annual: {
      totalPremiumCents: 0,
      dueAtCheckout: 0,
      duePerTimeUnit: 0,
      billingInterval: {
        hasDifferentInitialPayment: false,
        id: '',
        initialPaymentTimeUnits: 1,
        interval: 'annual',
        totalTimeUnits: 1,
        premiumResultKey: 'annualCostPremiumCents',
      },
      adjustments: 0,
      discounts: 0,
      partnerDiscount: 0,
      taxes: 0,
      fees: 0,
    },
  },
  products: {},
};

const initialState: QuotesState = {
  activeQuoteBySlug: {},
  originalQuoteBySlug: {},
  byId: {},
  pdfById: {},
  isLoading: false,
};

const quotesSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    loadEffectiveDate(state, action) {
      const { slug, availableEffectiveDate } = action.payload;
      const modifiedQuote = state.activeQuoteBySlug[slug];
      modifiedQuote.availableEffectiveDate = availableEffectiveDate;

      state.byId[modifiedQuote.id] = modifiedQuote;
      state.activeQuoteBySlug[slug] = modifiedQuote;
      state.isLoading = false;
    },
    loadQuote(state, action) {
      const { slug } = action.payload;
      const quote: Quote = action.payload.quote;
      const acceptedCoverages: Record<string, boolean> = {};

      Object.values(quote.products).forEach((product) => {
        Object.values(product.coverageGroups).forEach(
          (group) =>
            (acceptedCoverages[group.uiToken] = Object.values(group.elements).reduce(
              (prevResult: boolean, coverage) =>
                coverage.visibleToUser ? prevResult && coverage.accepted : prevResult,
              true
            ))
        );
      });
      quote.acceptedCoverages = acceptedCoverages;
      state.byId[quote.id] = quote;

      if (action.payload.active) {
        state.activeQuoteBySlug[slug] = quote;
      } else {
        state.originalQuoteBySlug[slug] = quote;
      }

      // todo: state.isLoading can be set early in loadEffectiveDate
      // todo: this wll set false even if original is still loading
      state.isLoading = false;
    },
    loadQuotePdf(state, action) {
      const { quoteId, url } = action.payload;
      state.pdfById[quoteId] = url;
    },
    clearQuotes(state) {
      state.activeQuoteBySlug = {};
      state.byId = {};
    },
    clearLoadingState(state) {
      state.isLoading = false;
    },
    setLoadingState(state) {
      state.isLoading = true;
    },
  },
});

export const quotesSelector = (state: State): QuotesState => state.quotes;

export const activeQuoteBySlug =
  (slug: string) =>
  (state: State): Quote => {
    return get(quotesSelector(state), ['activeQuoteBySlug', slug], emptyQuote);
  };
export const originalQuoteBySlug =
  (slug: string) =>
  (state: State): Quote => {
    return get(quotesSelector(state), ['originalQuoteBySlug', slug], emptyQuote);
  };
export const quoteById =
  (id: string) =>
  (state: State): Quote => {
    return get(quotesSelector(state), ['byId', id], emptyQuote);
  };

export const quotePdfById =
  (id: string) =>
  (state: State): string => {
    return get(quotesSelector(state), ['pdfById', id]);
  };
export const acceptedCoveragesForQuoteId =
  (id: string) =>
  (state: State): Record<string, boolean> => {
    return quoteById(id)(state).acceptedCoverages;
  };
export const getActiveQuotesForPackages =
  (packageSlugs: Array<string>) =>
  (state: State): Quote[] => {
    return packageSlugs.map((slug) => activeQuoteBySlug(slug)(state));
  };
export const getIsLoading = (state: State): boolean => quotesSelector(state).isLoading;

export const quotesActions = quotesSlice.actions;
const quotesReducer = quotesSlice.reducer;
export default quotesReducer;

/*
Future state active quote management

{
  selected: displayedQuote,
  activeQuoteIdBySlug {
    basic: basicPackage.active_quote.id,
    recommended: recommendedPackage.active_quote.id,
  },
  byBaseId: {
    uuid: {
      version0: quote0,
      version1: quote1,
    }
  }
}
 */
