import { createSlice } from '@reduxjs/toolkit';
import { State } from 'store';
import { LEVEL_CRITICAL } from './errors.actions';

export type ErrorsState = {
  error: string | null;
  critical: boolean;
  crumbs: boolean;
  lrSessionUrl: string;
  isUnauthorized: boolean;
};

const initialState = {
  critical: false,
  crumbs: false,
  error: null,
  lrSessionUrl: false,
  isUnauthorized: false,
};
const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    catchError(state, action) {
      const { message, level, lrSessionUrl } = action.payload;
      if (level === LEVEL_CRITICAL) {
        state.critical = true;
        state.error = message || null;
        state.lrSessionUrl = lrSessionUrl || null;
      } else if (isUnauthorizedOrForbiddenError(message)) {
        state.isUnauthorized = true;
      }
    },
    sendToCrumbs(state) {
      state.crumbs = true;
    },
  },
});

export const criticalStateSelector = (state: State): boolean => state.errors.critical;
export const errorStateSelector = (state: State): ErrorsState => state.errors;
export const isUnauthorizedOrForbiddenError = (message: string | null) => {
  return message?.includes('401') || message?.includes('403');
};
const packagesReducer = errorsSlice.reducer;
export const errorsActions = errorsSlice.actions;
export default packagesReducer;
