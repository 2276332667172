import { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@vouch/ui';
import { MinusButton, PlusButton } from 'components/atoms/buttons';
import './LimitSelector.scss';
import { formatTruncatedCents } from 'shared/helpers';

type LimitSelectorProps = {
  amount: number;
  available: number[] | undefined;
  customerEditable: boolean;
  handleChange: (amount: number) => void;
};

const LimitSelector: FC<LimitSelectorProps> = ({
  amount,
  available,
  customerEditable,
  handleChange,
}: LimitSelectorProps) => {
  const initialIndex = available ? available.indexOf(amount) : 0;
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  // if available limits is an empty array, use default amount
  const currentAmount = !available || selectedIndex === -1 ? amount : available[selectedIndex];

  useEffect(() => {
    if (available) {
      const newIndex = available.indexOf(amount);
      setSelectedIndex(newIndex);
    }
  }, [amount, available, initialIndex]);

  const Editable: FC = () => {
    if (!available) return <></>;

    const increment: () => void = () => {
      handleChange(available[selectedIndex + 1]);
      setSelectedIndex((i) => i + 1);
    };
    const decrement: () => void = () => {
      handleChange(available[selectedIndex - 1]);
      setSelectedIndex((i) => i - 1);
    };
    const isMinAvailableLimit: () => boolean = () => selectedIndex <= 0;
    const isMaxAvailableLimit: () => boolean = () => selectedIndex >= available.length - 1;
    return (
      <Box className="vouch-limit-selector" data-testid="limit-selector">
        <MinusButton
          data-testid="limit-selector-minus"
          onClick={decrement}
          disabled={isMinAvailableLimit()}
        />
        <Typography className="selected-value" variant="h4" data-testid="limit-amount">
          {formatTruncatedCents(currentAmount)}
        </Typography>
        <PlusButton
          data-testid="limit-selector-plus"
          onClick={increment}
          disabled={isMaxAvailableLimit()}
        />
      </Box>
    );
  };

  const NonEditable: FC = () => (
    <Box className="vouch-limit-selector">
      <Typography className="selected-value" variant="h4">
        {formatTruncatedCents(amount)}
      </Typography>
    </Box>
  );

  if (customerEditable && available && available.length > 1) {
    return <Editable />;
  } else {
    return <NonEditable />;
  }
};

export default LimitSelector;
