import {
  CYBER_COVERAGES,
  FIRST_PARTY_CYBER,
  THIRD_PARTY_CYBER,
  Coverage,
  LimitsOrOutOfPockets,
  ConvertedLocationCoverage,
} from 'features/coverages/coverages.types';
import { getCoverages } from 'features/coverages/coveragesSlice';
import {
  updateLimitOrOutOfPocketAmount,
  toggleCoverageAcceptance,
} from 'features/coverages/coverages.actions';
import { PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { catchError } from 'features/errors/errors.actions';
import DefaultExpandedCard from 'components/molecules/cards/CoverageCard/variants/DefaultExpandedCard';
import CyberExpandedCard from 'components/molecules/cards/CoverageCard/variants/CyberExpandedCard';
import { toggleCoverageGroupAcceptance } from 'features/coverageGroups/coverageGroupsSlice';
import { Address } from 'generated/graphql';
import { selectedPackage } from 'features/packages/packagesSlice';
import { getActiveQuotesForPackages } from 'features/quotes/quotesSlice';
import { determinePAIComponent, removeBOPWorkFromAnywhere } from 'shared/helpers';

export type ExpandedCardProps = {
  accepted: boolean;
  coverageTokens: string[];
  closeModal?: () => void;
  groupToken: string;
  implicitElements: Record<string, Coverage>;
  limits: Record<string, LimitsOrOutOfPockets>;
  locationCoverageDetails: Record<string, ConvertedLocationCoverage> | undefined;
  locationSpecific: boolean | undefined;
  outOfPockets: Record<string, LimitsOrOutOfPockets>;
  productToken: string;
  selectedCoverageIndex: number;
  sharedLimit?: LimitsOrOutOfPockets;
  tabAction: (newValue: number) => void;
  unsupportedLocations?: Address[];
};

const ExpandedCard = (props: PropsWithChildren<ExpandedCardProps>) => {
  /**
   *  IA-1197: https://vouchinc.atlassian.net/browse/IA-1197
   *  The ask is to remove work from anywhere coverage from BOP in non-CPP quotes
   *  but leave it in for CPP quotes.
   */
  const currentPackage = useSelector(selectedPackage);
  const quotes = useSelector(getActiveQuotesForPackages([currentPackage]));
  const implicitElements = determinePAIComponent(
    removeBOPWorkFromAnywhere(quotes[0].products, {
      ...props.implicitElements,
    })
  );

  const { accepted, groupToken, coverageTokens } = props;
  const dispatch = useDispatch();

  const toggleAcceptance = () => {
    dispatch(toggleCoverageGroupAcceptance({ groupToken }));
    coverageTokens.forEach((coverageToken) =>
      dispatch(toggleCoverageAcceptance({ coverageToken }))
    );
  };

  const cyberQADebugging = (
    level: string,
    message: string,
    extraContext?: Record<string, Coverage | string | number>
  ): void => {
    dispatch(catchError({ level, message, extraContext }));
  };

  // Check if Cyber
  const isCyber = groupToken === 'cyber_group' || groupToken === 'ny_cyber_group';
  const cyberCoverages = useSelector(getCoverages(CYBER_COVERAGES));
  const updateCyberLimit = (coverageToken: string, amount: number): void => {
    if (!accepted) {
      toggleAcceptance();
    }

    dispatch(
      updateLimitOrOutOfPocketAmount({
        coverageToken,
        type: 'limits',
        frequency: 'in_aggregate',
        amount,
      })
    );
    if (coverageToken === THIRD_PARTY_CYBER) {
      // If THIRD party cyber is being changed, the cyber (group) shared limit must also be updated
      // In this case, calculated_in_aggregate is the shared limit and must be updated on
      // BOTH first and third party coverages
      dispatch(
        updateLimitOrOutOfPocketAmount({
          coverageToken: FIRST_PARTY_CYBER,
          type: 'limits',
          frequency: 'calculated_in_aggregate',
          amount,
        })
      );
      dispatch(
        updateLimitOrOutOfPocketAmount({
          coverageToken: THIRD_PARTY_CYBER,
          type: 'limits',
          frequency: 'calculated_in_aggregate',
          amount,
        })
      );
    }
  };

  const simpleHandleLimitOrOutOfPocketChange = (
    coverageToken: string,
    type: string,
    frequency: string,
    amount: number
  ) => {
    if (!accepted) {
      toggleAcceptance();
    }

    dispatch(updateLimitOrOutOfPocketAmount({ coverageToken, type, frequency, amount }));
  };

  return isCyber ? (
    <CyberExpandedCard
      {...props}
      debugLogger={cyberQADebugging}
      updateCyberLimit={updateCyberLimit}
      cyberCoverages={cyberCoverages}
      simpleLimitOrOutOfPocketHandler={simpleHandleLimitOrOutOfPocketChange}
    />
  ) : (
    <DefaultExpandedCard
      {...props}
      implicitElements={implicitElements}
      simpleLimitOrOutOfPocketHandler={simpleHandleLimitOrOutOfPocketChange}
    />
  );
};

export default ExpandedCard;
