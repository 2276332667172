import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from 'store';
import { ALL_COVERAGES, Coverage, CoveragesSet, CoveragesState } from './coverages.types';
import { reduce, isEqual } from 'lodash';
import { hasAcceptedCoverages } from 'features/helpers';

const initialState: CoveragesState = {
  originalData: {},
};

// We're calling it coverages, but this includes endorsements and exclusions
const coveragesSlice = createSlice({
  name: 'coverages',
  initialState,
  reducers: {
    clearOriginalData(state) {
      state.originalData = {};
    },
    loadCoverage(state, { payload }: PayloadAction<{ coverage: Coverage }>) {
      const coverage: Coverage = payload.coverage;
      const coverageToken: keyof CoveragesSet = coverage.uiToken;
      state[coverageToken] = { ...coverage };
      state.originalData[coverageToken] = { ...coverage };
    },
    toggleCoverageAcceptance(state, { payload }) {
      const coverageToken: keyof CoveragesSet = payload.coverageToken;
      const coverage = state[coverageToken];

      if (!coverage) return;
      const acceptance = coverage.accepted;
      state[coverageToken] = {
        ...coverage,
        accepted: !acceptance,
        locationCoverageDetails: coverage.locationSpecific
          ? reduce(
              coverage.locationCoverageDetails,
              (res, val, key) => {
                return {
                  ...res,
                  [key]: { ...val, accepted: !acceptance },
                };
              },
              {}
            )
          : null,
      };
    },
    toggleLocationAcceptance(state, { payload }) {
      const coverageToken: keyof CoveragesSet = payload.coverageToken;
      const locationId: string = payload.locationId;
      const coverage = state[coverageToken];

      if (!coverage || !coverage.locationCoverageDetails) return;

      const coverageLocation = coverage.locationCoverageDetails[locationId];
      const currentAcceptance = !coverageLocation.accepted;

      state[coverageToken] = {
        ...coverage,
        locationCoverageDetails: {
          ...coverage.locationCoverageDetails,
          [locationId]: {
            ...coverage.locationCoverageDetails[locationId],
            accepted: currentAcceptance,
          },
        },
      };
    },
    updateLocationLimitOrOutOfPocketAmount(state, { payload }) {
      const coverageToken: keyof CoveragesSet = payload.coverageToken;
      const { frequency, type, locationId, amount } = payload;
      const coverage = state[coverageToken];
      if (!coverage || !coverage.locationCoverageDetails) return;

      state[coverageToken] = {
        ...coverage,
        locationCoverageDetails: {
          ...coverage.locationCoverageDetails,
          [locationId]: {
            ...coverage.locationCoverageDetails[locationId],
            [type]: {
              ...coverage.locationCoverageDetails[locationId][type],
              [frequency]: {
                ...coverage.locationCoverageDetails[locationId][type][frequency],
                amount,
              },
            },
          },
        },
      };
    },
    updateLimitOrOutOfPocketAmount(state, { payload }) {
      const coverageToken: keyof CoveragesSet = payload.coverageToken;
      const { type, frequency, amount } = payload;
      const coverage = state[coverageToken];

      if (!coverage) return;
      state[coverageToken] = {
        ...coverage,
        [type]: {
          ...(coverage[type] || {}),
          [frequency]: {
            ...(coverage[type][frequency] || {}),
            amount,
          },
        },
      };
    },
  },
});

export const coveragesSelector = (state: State): CoveragesState => state.coverages;
export const hasAcceptance = (state: State): boolean => hasAcceptedCoverages(state.coverages);
export const getCoverages =
  (tokens: string[]) =>
  (state: State): Coverage[] => {
    return tokens.map((token) => coveragesSelector(state)[token]);
  };

export const hasUnsavedChanges = createSelector(
  (state: State) => state.coverages,
  (coverages: CoveragesState): boolean => {
    return !ALL_COVERAGES.every((coverageToken) => {
      return isEqual(coverages[coverageToken], coverages.originalData[coverageToken]);
    });
  }
);
export const coverageActions = coveragesSlice.actions;
const coveragesReducer = coveragesSlice.reducer;
export default coveragesReducer;
