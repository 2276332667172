import { FC } from 'react';
import { Box, ButtonProps } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import TertiaryButton from '../../buttons/TertiaryButton';

const noop = () => {};

const BackAction: FC<{ text: string; onClick?: ButtonProps['onClick'] }> = ({ text, onClick }) => (
  <TertiaryButton
    size="medium"
    startIcon={<ArrowBack />}
    sx={{ width: '90px', ':hover': { color: 'none' } }}
    onClick={onClick || noop}
  >
    <Box
      component="span"
      sx={(theme) => ({
        textAlign: 'left',
        whiteSpace: 'nowrap',
        textTransform: theme.designTokens.buttonTextCase,
      })}
    >
      {text}
    </Box>
  </TertiaryButton>
);

export default BackAction;
