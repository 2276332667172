import { FC, useState } from 'react';
import { Box, Grid, Typography, Skeleton, styled, useMediaQuery, useTheme } from '@vouch/ui';
import { CoveragesSet } from 'features/coverages/coverages.types';
import {
  CoverageTabs,
  SubcoverageSection,
} from 'components/molecules/cards/CoverageCard/components/components';
import { CoverageDescriptions } from 'shared/ui/coverageDescriptions';
import LocationCard from 'components/molecules/cards/LocationCard/LocationCard';
import { LimitSection } from 'components/templates/limit-section';
import CoverageCardContent from 'components/molecules/cards/CoverageCard/components/CoverageCardContent';
import { CoverageCardProps } from 'components/molecules/cards/CoverageCard/CoverageCard';
import { runningListOfUiTokens } from 'shared/ui/tokenNames';
import { coverageTabChange } from 'features/eventTracking/eventTracking.actions';
import { useDispatch, useSelector } from 'react-redux';
import { determinePAIComponent, removeBOPWorkFromAnywhere } from 'shared/helpers';
import { selectedPackage } from 'features/packages/packagesSlice';
import { getActiveQuotesForPackages } from 'features/quotes/quotesSlice';
import { ToggleableText } from 'components/atoms/buttons/toggle';

const CardContainer = styled(Grid)(({ theme }) => ({
  background: theme.designTokens.colorWhite,
  outline: `1px ${theme.designTokens.colorSecondaryLighter} solid`,
  borderRadius: '0.25rem',
}));

const CoverageFull: FC<CoverageCardProps> = (props: CoverageCardProps) => {
  const {
    group,
    coverages,
    hasActiveDiscretion,
    limitChangeAction,
    missingDependencies,
    product,
    toggleCoverage,
    loading = false,
  }: CoverageCardProps = props;
  const dispatch = useDispatch();
  const { elements } = group;
  const [selectedCoverageIndex, setSelectedCoverageIndex] = useState<number>(0);
  const coverageTokens: (keyof CoveragesSet)[] = Object.values(elements).map((c) => c.uiToken);
  const selectedCoverageKey = coverageTokens[selectedCoverageIndex];
  const { accepted, limits, offered, outOfPockets, locationSpecific, locationCoverageDetails } =
    coverages[selectedCoverageIndex];

  const currentPackage = useSelector(selectedPackage);
  const quotes = useSelector(getActiveQuotesForPackages([currentPackage]));
  const implicitElements = determinePAIComponent(
    removeBOPWorkFromAnywhere(quotes[0].products, {
      ...coverages[selectedCoverageIndex].implicitElements,
    })
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleTabChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    dispatch(
      coverageTabChange({
        coverageTabChange: coverageTokens[newValue],
        cardTitle: runningListOfUiTokens[group.uiToken],
        policy: product.uiToken,
      })
    );
    setSelectedCoverageIndex(newValue);
  };

  const handleLimitOrOutOfPocketChange =
    (coverageToken: string, type: string) => (frequency: string) => (amount: number) =>
      limitChangeAction({ coverageToken, type, frequency, amount });

  const skeletonMargin = (() => {
    const coverageCardSkeletonMargins = {
      sm: 740,
      md: 460,
      lg: 316,
    };

    if (isMobile) return coverageCardSkeletonMargins.sm;
    if (isMediumScreen) return coverageCardSkeletonMargins.md;
    return coverageCardSkeletonMargins.lg;
  })();

  return loading ? (
    // ToDo: Create Full Coverage Card Skeleton
    <Skeleton
      height={45}
      width={'100%'}
      style={{ marginTop: skeletonMargin, marginBottom: skeletonMargin }}
    />
  ) : (
    <CardContainer data-testid="coverage-card-full" container item className="basic-coverage-card">
      <Grid item xs={12} md={4} p={2}>
        <CoverageCardContent
          accepted={accepted}
          cardTitle={runningListOfUiTokens[group.uiToken]}
          groupToken={group.uiToken}
          hasActiveDiscretion={hasActiveDiscretion}
          limits={limits}
          missingDependencies={missingDependencies}
          offered={offered}
          pricing={group.pricing}
          toggleCoverage={toggleCoverage}
          toggleable={false}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        pt={1}
        borderLeft={{ xs: 'none', md: `1px solid ${theme.designTokens.colorSecondary}` }}
      >
        <Box>
          <CoverageTabs
            coverageTokens={coverageTokens}
            handleTabChange={handleTabChange}
            selectedCoverageIndex={selectedCoverageIndex}
          />
          {isMobile && !locationSpecific && (
            <LimitSection
              accepted={accepted}
              coverageTokens={coverageTokens}
              handleLimitOrOutOfPocketChange={handleLimitOrOutOfPocketChange}
              limits={limits}
              outOfPockets={outOfPockets}
              productToken={product.uiToken}
              selectedCoverageIndex={selectedCoverageIndex}
            />
          )}
          <Box px={2} pb={0} pt={1} className="coverage-description">
            <ToggleableText truncatedLines={2}>
              {CoverageDescriptions[selectedCoverageKey]}
            </ToggleableText>
          </Box>
        </Box>
        <Box ml={2} mt={2}>
          <Typography variant="subtitle2" sx={{ color: theme.designTokens.colorNeutralDark }}>
            Key Highlights
          </Typography>
        </Box>
        <SubcoverageSection implicitElements={implicitElements} />
        {locationSpecific && locationCoverageDetails && (
          <LocationCard groupToken={group.uiToken} locationDetails={locationCoverageDetails} />
        )}
      </Grid>
      {!isMobile && !locationSpecific && (
        <LimitSection
          accepted={accepted}
          coverageTokens={coverageTokens}
          handleLimitOrOutOfPocketChange={handleLimitOrOutOfPocketChange}
          limits={limits}
          outOfPockets={outOfPockets}
          productToken={product.uiToken}
          selectedCoverageIndex={selectedCoverageIndex}
        />
      )}
    </CardContainer>
  );
};

export default CoverageFull;
