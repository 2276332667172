import { formatListString } from 'shared/helpers';
import { InfoTooltip } from 'components/atoms/tooltip/info';
import { runningListOfUiTokens } from 'shared/ui/tokenNames';
import { Box } from '@vouch/ui';

export const meetsDependencyRequirements = (
  anyRequiredGroupIds: string[],
  allRequiredGroupIds: string[],
  groupIdToTokenMap: Record<string, string>,
  groupAcceptance: Record<string, Record<string, boolean>>
): JSX.Element | string => {
  let requiredGroups: string[] = [];
  if (!anyRequiredGroupIds.length && !allRequiredGroupIds.length) {
    return '';
  } else if (anyRequiredGroupIds.length) {
    requiredGroups = getMissingDependencyNames(
      anyRequiredGroupIds,
      groupIdToTokenMap,
      groupAcceptance
    );
    if (requiredGroups.length === anyRequiredGroupIds.length) {
      const message = `${formatListString(requiredGroups, 'or')} required`;
      if (message === 'Directors & Officers or Employment Practices Liability required') {
        return (
          <Box
            className="missing-do-epl"
            sx={(theme) => ({
              svg: {
                color: theme.designTokens.colorSecondaryDarker,
                marginLeft: '0.25rem',
              },
            })}
          >
            D&O or EPL required
            <InfoTooltip content="You must purchase Directors & Officers or Employment Practices Liability to purchase this coverage." />
          </Box>
        );
      }
      return message;
    }
  } else {
    requiredGroups = getMissingDependencyNames(
      allRequiredGroupIds,
      groupIdToTokenMap,
      groupAcceptance
    );
    if (requiredGroups.length) {
      return `${formatListString(requiredGroups, 'and')} required`;
    }
  }
  return '';
};

export const getMissingDependencyNames = (
  dependencyGroupIds: string[],
  groupIdToTokenMap: Record<string, string>,
  groupAcceptance: Record<string, Record<string, boolean>>
): string[] => {
  return dependencyGroupIds
    .map((groupId) => groupIdToTokenMap[groupId])
    .filter((groupToken) => !coverageGroupIsAccepted(groupAcceptance[groupToken]))
    .map((groupToken) => runningListOfUiTokens[groupToken]);
};

export const coverageGroupIsAccepted = (elementAcceptance: Record<string, boolean>): boolean =>
  Object.values(elementAcceptance).reduce((prev, current) => prev && current, true);

const SHARED_LIMIT_DISCOUNT_APPLICABLE = ['MPL', 'NY_TPL'];

const sharedLimitDiscountText = (length: number) => {
  if (length < 2) {
    return '';
  } else if (length === 2) {
    return 'Multi-coverage Discount: Buy 2 coverages & get 5% off this policy!';
  } else {
    return 'Multi-coverage Discount: Buy 2 coverages & get 5% off, buy 3 coverages & get 10% off this policy!';
  }
};

export const getDiscountText = (productToken: string, length: number): string => {
  if (!SHARED_LIMIT_DISCOUNT_APPLICABLE.includes(productToken)) {
    return '';
  }
  return sharedLimitDiscountText(length);
};

const BOP_ON_REQUEST_MARKETS = ['FL'];

export const providedOnRequest = (productToken: string, market?: string): boolean => {
  if (!market) {
    return false;
  }
  if (productToken === 'BOP' && BOP_ON_REQUEST_MARKETS.includes(market)) {
    return true;
  }
  return false;
};
