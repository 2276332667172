import { FC, ReactElement } from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import {
  Box,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import VouchLogo, { LogoProps } from '../asset/logo/Logo';
import ActionSet from './action-sets/ActionSet';
import { EXPERIENCES, useExperience } from '../experience-provider/ExperienceProvider';

export type AppBarProps = {
  actions?: ReactElement[];
  backButton?: ReactElement;
  drawerAction?: () => void;
  hasDrawer?: boolean;
  progress?: number;
  progressMessage?: ReactElement;
  priorityAction?: ReactElement;
} & MuiAppBarProps;

const AppBar: FC<AppBarProps> = (props) => {
  const {
    actions = [],
    backButton,
    drawerAction,
    hasDrawer = false,
    progress = 0,
    progressMessage = <></>,
    priorityAction,
    variant,
    ...appBarProps
  } = props;
  const theme = useTheme();
  const { experience } = useExperience();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isVouch = experience === EXPERIENCES.VOUCH;
  const logoProps: LogoProps = {
    variant: 'primary-dark',
    width: 95,
    height: 40,
  };
  return isVouch ? (
    <MuiAppBar
      position={appBarProps.position || 'fixed'}
      color={appBarProps.color || 'inherit'}
      variant={variant}
      sx={{ height: 64 }}
      {...appBarProps}
    >
      {Boolean(progress) && <LinearProgress variant="determinate" value={progress} />}
      <Toolbar>
        {hasDrawer && drawerAction && (
          <IconButton onClick={drawerAction}>
            <MenuIcon />
          </IconButton>
        )}
        <VouchLogo {...logoProps} />
        {backButton && (
          <Box component="span" ml={1.2}>
            {backButton}
          </Box>
        )}
        <Box sx={{ flexGrow: { xs: 1, md: priorityAction ? 0 : 1 } }} />
        {priorityAction || (
          <>
            {!isMedium && Boolean(progress) && (
              <Box mr={8}>
                <Typography variant="menu">{progressMessage}</Typography>
              </Box>
            )}
            <ActionSet actions={actions} />
          </>
        )}
      </Toolbar>
    </MuiAppBar>
  ) : null;
};

export default AppBar;
