import { FC, ReactElement } from 'react';
import { Box } from '@vouch/ui';
import { ReactComponent as NewIcon } from 'shared/assets/icons/new-icon.svg';
import { useDispatch } from 'react-redux';
import {
  aiCoverageBannerViewed,
  aiCoverageRequestButtonClicked,
} from '../../../features/eventTracking/eventTracking.actions';
import { QuoteBanner } from './quoteBanner';

export const AICoverageBanner: FC = (): ReactElement => {
  const dispatch = useDispatch();
  dispatch(aiCoverageBannerViewed());

  const scrollToDiscretion = () => {
    dispatch(aiCoverageRequestButtonClicked());

    const discretion = document.getElementById('note-to-underwriter-field');
    if (discretion) {
      discretion.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        discretion.focus();
      }, 500);
    }
  };
  return (
    <QuoteBanner
      title={
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box>AI Insurance</Box>{' '}
          <Box sx={{ paddingTop: '2px' }}>
            <NewIcon width={60} height={18} />
          </Box>
        </Box>
      }
      body="Recommended to cover losses from LLM hallucinations, algorithmic bias, regulatory mistakes, IP infringement, and more."
      linkText="Request quote"
      linkAction={scrollToDiscretion}
    />
  );
};
