import { envName, reactCommitRef, rollbarToken } from '../environment';

const rollbarConfig = {
  accessToken: rollbarToken,
  autoInstrument: envName !== 'local',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: rollbarToken !== '',
  environment: envName,
  payload: {
    client: {
      javascript: {
        code_version: reactCommitRef,
        guess_uncaught_frames: true,
        source_map_enabled: true,
      },
    },
  },
  verbose: envName !== 'production', // Also output to console unless Production
};
export default rollbarConfig;
