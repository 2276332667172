import { PrimaryButton } from '@vouch/ui';
import VouchLoadingSm from 'components/atoms/icons/VouchLoadingSm';
import { catchError, LEVEL_CRITICAL } from 'features/errors/errors.actions';
import { logExpiredQuote } from 'features/eventTracking/eventTracking.actions';
import { clearPackages, processPackages } from 'features/packages/packages.actions';
import { clearLoadingState, clearQuotes } from 'features/quotes/quotes.actions';
import { sendToOnboarding, sessionApplicationId } from 'features/session/sessionSlice';

import { useRecreateExpiredPackagesMutation } from 'generated/graphql';
import { get } from 'lodash';
import { FC, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

const RecreatePackagesContainer: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const applicationId = useSelector(sessionApplicationId);

  useEffect(() => {
    dispatch(logExpiredQuote());
  }, [dispatch]);

  const [recreatePackages, { loading }] = useRecreateExpiredPackagesMutation({
    onError: (e) => {
      dispatch(clearLoadingState());
      dispatch(
        catchError({
          level: LEVEL_CRITICAL,
          message: `RecreatePackagesContainer MutationError: ${e.message}`,
        })
      );
    },
    onCompleted: (data) => {
      const packages = get(data, 'recreateExpiredPackages');
      if (packages.length === 0) {
        // Application is unlocked and doesn't have valid packages
        dispatch(sendToOnboarding({}));
        return;
      }
      batch(() => {
        dispatch(clearQuotes());
        dispatch(clearPackages());
        dispatch(processPackages(packages));
      });
    },
  });

  const requestRecreateQuote = () => {
    recreatePackages({
      variables: {
        applicationId,
      },
    });
  };

  return (
    <PrimaryButton
      data-testid={'request-recreate-quote-button'}
      variant="contained"
      onClick={() => requestRecreateQuote()}
    >
      {loading ? <VouchLoadingSm /> : 'Refresh Packages'}
    </PrimaryButton>
  );
};

export default RecreatePackagesContainer;
