import { Theme } from '@mui/material';

const MuiTypography = {
  variants: [
    {
      props: { variant: 'menu' },
    },
  ],
  styleOverrides: {
    menu: ({ theme }: { theme: Theme }) => ({
      fontFamily: theme.typography.fontFamily,
      color: theme.designTokens.colorBlack,
      textDecoration: 'none',
      fontSize: 14,
      paddingTop: 10,
      paddingBottom: 10,
      lineHeight: 1.5,
    }),
    detail: {
      fontSize: 14,
    },
  },
};

export default MuiTypography;
