import { Theme } from '@mui/material';

const MuiLink = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      cursor: 'pointer',
      color: theme.designTokens.colorPrimaryDarker,
      fontSize: theme.typography.body1.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 50,
      ['& .MuiSvgIcon-root']: {
        verticalAlign: 'middle',
      },
      '&.MuiTypography-menu': {
        color: theme.designTokens.colorBlack,
        textDecoration: 'none',
        fontSize: 14,
      },
    }),
    underlineHover: {
      textDecorationLine: 'none',
    },
  },
};

export default MuiLink;
