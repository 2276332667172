import { Theme } from '@mui/material';

const MuiIconButton = {
  defaultProps: {
    color: 'secondary',
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      borderRadius: 4,
      color: theme.designTokens.colorBlack,
      fontSize: theme.designTokens.buttonFontSize,
      padding: '15px 20px',
      '&& .MuiTouchRipple-child': {
        background: theme.designTokens.colorPrimaryLightest,
      },
      '& .MuiSvgIcon-root': {
        height: 18,
        width: 18,
      },
      '&:disabled': {
        background: theme.designTokens.colorNeutralLight,
        color: theme.designTokens.colorNeutralDark,
      },
      '&:focus-visible': {
        boxShadow: `0 0 5px ${theme.designTokens.colorPrimary}`,
      },
      '&:hover': {
        background: theme.designTokens.colorPrimaryLightest,
      },
      '&.stepper': {
        borderRadius: '50%',
        height: 30,
        padding: 0,
        width: 30,
        '&:not(:disabled)': {
          background: theme.designTokens.colorPrimary,
          color: theme.designTokens.colorWhite,
        },
        '&& .MuiTouchRipple-child': {
          background: theme.designTokens.colorPrimaryDarker,
        },
        '&:focus': {
          outline: '2px solid white',
          outlineOffset: -2,
        },
        '&:hover': {
          background: theme.designTokens.colorPrimaryLighter,
        },
        '& .MuiSvgIcon-root': {
          height: 16,
          width: 16,
        },
      },
    }),
    sizeSmall: {
      padding: '0 5px',
      alignSelf: 'baseline',
      display: 'inline-flex',
    },
  },
};

export default MuiIconButton;
