import { Theme } from '@mui/material';

const MuiPickersToolbar = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.designTokens.colorPrimaryDarker,
      color: theme.designTokens.colorWhite,
      '.MuiTypography-root': {
        color: theme.designTokens.colorWhite,
        '&h4': {
          fontSize: '1.25rem',
          fontWeight: 700,
        },
        '&span': {
          fontSize: '0.75rem',
          letterSpacing: '1px',
        },
      },
    }),
  },
};

export default MuiPickersToolbar;
