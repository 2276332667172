import { ReactElement } from 'react';
import { InfoTooltip } from '../info';
import { formatCents } from 'shared/helpers';

export type TaxTooltipProps = {
  taxes: number;
  fees: number;
};

export const TaxTooltip = ({ taxes, fees }: TaxTooltipProps): ReactElement => {
  return (
    <InfoTooltip
      content={
        <div>
          {(taxes !== 0 || fees !== 0) && (
            <span>
              <b>Taxes: {formatCents(taxes)}</b>
              <br />
              State required.
              <br />
              <br />
              <b>Stamping fee: {formatCents(fees)}</b>
              <br />
              State required.
              <br />
              <br />
            </span>
          )}
          <span>Only required when purchasing certain coverages in your state.</span>
        </div>
      }
    />
  );
};
