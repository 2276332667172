const MuiAutocomplete = {
  styleOverrides: {
    root: {
      '.MuiButtonBase-root': {
        '&:hover': {
          backgroundColor: 'inherit',
        },
        '&.MuiAutocomplete-clearIndicator': {
          '.MuiSvgIcon-root': {
            height: 20,
            width: 20,
          },
        },
        '&.MuiAutocomplete-popupIndicator': {
          '.MuiSvgIcon-root': {
            height: 24,
            width: 24,
          },
        },
        '.MuiSvgIcon-root': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
        '.MuiTouchRipple-root': {
          display: 'none',
        },
      },
    },
  },
};

export default MuiAutocomplete;
