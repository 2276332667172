import { Box, Card, CardContent, Grid, Typography } from '@vouch/ui';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import VouchModal from 'components/atoms/display/Modal';
import { notificationModalClosed } from 'features/eventTracking/eventTracking.actions';
import { CloseButton } from 'components/atoms/buttons/icon';

export type NotificationModalProps = {
  buttonElem: JSX.Element;
  close?: () => void;
  heading: string;
  isLoading: boolean;
  isOpen: boolean;
  text: string;
};

const NotificationModal: FC<NotificationModalProps> = ({
  close,
  heading,
  text,
  isLoading,
  isOpen,
  buttonElem,
}: NotificationModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(notificationModalClosed({ notificationModalClosed: heading }));
  };
  return (
    <VouchModal
      displayHeader={false}
      modalClose={() => onClose()}
      modalOpen={isOpen}
      isLoading={isLoading}
      data-testid="notification-modal"
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={10} sm={8} md={6}>
          <Card>
            <CardContent>
              {close && <CloseButton onClick={close} />}
              <Box mb={1} padding={4} textAlign="center">
                <Typography data-testid="modal-heading" variant="h4">
                  {heading}
                </Typography>
                <Box my={2}>
                  <Typography className="modal-text" data-testid="modal-text" variant="body1">
                    {text}
                  </Typography>
                </Box>
                {buttonElem}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </VouchModal>
  );
};

export default NotificationModal;
