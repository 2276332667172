import { Theme } from '@mui/material';

const MuiTab = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      borderBottomColor: theme.designTokens.colorNeutralDark,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      '&.Mui-selected': {
        color: theme.designTokens.colorBlack,
      },
    }),
    textColorPrimary: ({ theme }: { theme: Theme }) => ({
      color: theme.designTokens.colorBlack,
      fontSize: '1.125rem',
      letterSpacing: 'normal',
      textTransform: 'initial',
    }),
  },
};

export default MuiTab;
