import { Theme } from '@mui/material';

const MuiPickersLayout = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      '.MuiPickersLayout-actionBar': {
        gap: '0.5rem',
        justifyContent: 'end',
        margin: '0 0.5rem 0.5rem',
        '.MuiButton-root': {
          backgroundColor: theme.designTokens.colorWhite,
          margin: 0,
          '&:hover': {
            backgroundColor: theme.designTokens.colorPrimaryLightest,
          },
          '&:focus': {
            outlineColor: 'transparent',
            outlineStyle: 'none',
          },
        },
      },
    }),
  },
};

export default MuiPickersLayout;
