import { FC } from 'react';
import { Typography } from '@vouch/ui';
import './style.scss';
import { BannerConfig } from 'shared/ui/tokenNames';

type BannerComponentProps = {
  bannerType: 'brex-10k' | 'stripe' | 'wework';
};

const BannerComponent: FC<BannerComponentProps> = ({ bannerType }): JSX.Element => {
  return (
    <div className={`banner-wrapper ${bannerType}`} data-testid={`banner-${bannerType}`}>
      <Typography className="banner-title" variant="h6">
        {BannerConfig[bannerType].title}
      </Typography>
      <Typography variant="body1">{BannerConfig[bannerType].subTitle}</Typography>
    </div>
  );
};

export default BannerComponent;
