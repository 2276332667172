import { FC, MouseEventHandler, useState } from 'react';
import { Box, Card, LocalPhone, Typography } from '@vouch/ui';
import LimitSelector from 'components/molecules/LimitSelector';
import { limitFrequency } from 'shared/ui/tokenNames';
import VouchModal from 'components/atoms/display/Modal/Modal';
import EPLLearnMoreCard from '../CoverageCard/variants/EPLLearnMoreCard';

export type LimitCardProps = {
  amount: number;
  available: number[] | undefined;
  customerEditable: boolean;
  frequency: string;
  label: string;
  coverageToken: string;
  handleChange?: (amount: number) => void;
};

const LimitCard: FC<LimitCardProps> = ({
  amount,
  available,
  customerEditable,
  frequency,
  label,
  coverageToken,
  handleChange = () => ({}),
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const learnMore: MouseEventHandler = (event) => {
    setOpen(true);
    event.stopPropagation();
  };

  return (
    <Card className="limit-card" data-testid="limit-card">
      <Typography textTransform="uppercase" className="limit-title" variant="detail">
        {label}
      </Typography>
      <Box py={1}>
        <LimitSelector
          amount={amount}
          available={available || []}
          customerEditable={customerEditable}
          handleChange={handleChange}
        />
      </Box>
      <Typography variant="subtitle2" fontWeight="normal" textAlign="center">
        {limitFrequency[frequency]}
      </Typography>
      {label === 'retention' && coverageToken === 'employment_practices_liability' ? (
        <Box pt="10px" display="flex" alignItems="center">
          <LocalPhone sx={(theme) => ({ fill: theme.designTokens.colorPrimaryDarker })} />
          <Box width="157px" display="flex" pl="0.5rem">
            <Typography
              variant="subtitle2"
              fontSize="13px"
              sx={(theme) => ({
                color: theme.designTokens.colorPrimaryDarker,
              })}
            >
              Reduce your retention with our{' '}
              <Typography
                component="span"
                variant="subtitle2"
                fontSize="13px"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={learnMore}
              >
                24/7 Hotline
              </Typography>
            </Typography>
            <Box>
              <VouchModal modalOpen={open} modalClose={() => setOpen(false)} isLoading={false}>
                <EPLLearnMoreCard closeModal={() => setOpen(false)} />
              </VouchModal>
            </Box>
          </Box>
        </Box>
      ) : null}
    </Card>
  );
};
export default LimitCard;
