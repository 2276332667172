const MuiRadio = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      '.radio-img': {
        width: '30px',
      },
    },
  },
};

export default MuiRadio;
