import { ProductOrGroupPricing } from 'features/quotes/quotes.types';
import { Address } from 'generated/graphql';

export type Coverage = {
  id: string;
  locationId: string;
  uiToken: keyof CoveragesSet;
  visibleToUser: boolean;
  exclusion: boolean;
  recommended: boolean;
  accepted: boolean;
  offered: boolean;
  raterInputKey: string;
  pricing: ProductOrGroupPricing;
  limits: Record<string, LimitsOrOutOfPockets>;
  outOfPockets: Record<string, LimitsOrOutOfPockets>;
  implicitElements: Record<string, Coverage>;
  locationSpecific?: boolean;
  locationCoverageDetails?: Record<string, ConvertedLocationCoverage> | null;
  unsupportedLocations?: Address[];
};

export type ConvertedLocationCoverage = {
  location: {
    id: string;
    addressLine1: string;
    city: string;
    state: string;
    zip: string;
  };
  id: string;
  isPrimary: boolean;
  uiToken: keyof CoveragesSet;
  visibleToUser: boolean;
  exclusion: boolean;
  recommended: boolean;
  accepted: boolean;
  offered: boolean;
  raterInputKey: string;
  pricing: ProductOrGroupPricing;
  implicitElements: Record<string, Coverage>;
  limits: Record<string, LimitsOrOutOfPockets>;
  outOfPockets: Record<string, LimitsOrOutOfPockets>;
  locationCoverageDetails?: Record<string, ConvertedLocationCoverage>;
};

export type locationData = Record<string, number>;

export type LimitsOrOutOfPockets = {
  amount: number;
  available: number[];
  customerEditable: boolean;
  id: string;
  frequency: string;
  label: string;
  visible: boolean;
  locationId: string;
};

export type CoveragesState = {
  originalData: { [coverageToken: string]: Coverage };
} & CoveragesSet;

export type CoveragesSet = {
  // Do not change snake_case coverage names to camelCase
  // pa_shim is passing us the UI tokens in snake_case
  // Coverages:
  bop_bpp_storage_units?: Coverage;
  business_income_interruption?: Coverage;
  business_personal_property?: Coverage;
  cem_errors_and_omissions?: Coverage;
  // computer_fraud?: Coverage;
  // counterfeit_money?: Coverage;
  crime?: Coverage;
  cyber?: Coverage;
  directors_and_officers?: Coverage;
  employee_benefits_liability?: Coverage;
  // employee_dishonesty?: Coverage;
  employment_practices_liability?: Coverage;
  errors_and_omissions?: Coverage;
  fiduciary?: Coverage;
  first_party_cyber?: Coverage;
  // forgery_and_alteration: Coverage;
  // fraudulent_impersonation: Coverage;
  general_liability?: Coverage;
  hired_and_nonowned_auto?: Coverage;
  third_party_cyber?: Coverage;
  // Subcoverages:
  med_pay?: Coverage;
  breach_response?: Coverage;
  cap_table_sublimit_status?: Coverage;
  damage_rented_premise?: Coverage;
  cem_public_relations_costs?: Coverage;
  cem_breach_response_costs?: Coverage;
  cem_bus_interrupt_security_failure?: Coverage;
  cem_bus_interrupt_system_failure?: Coverage;
  cem_cont_bus_interrupt_security_failure?: Coverage;
  cem_cont_bus_interrupt_system_failure?: Coverage;
  cem_cyber_crime?: Coverage;
  cem_cyber_extortion?: Coverage;
  cem_data_incident_restoration_costs?: Coverage;
  cem_eo_ai_endorsement?: Coverage;
  cem_media_liability?: Coverage;
  cem_network_security?: Coverage;
  cem_pci_violations?: Coverage;
  cem_privacy_liability?: Coverage;
  cem_privacy_regulatory?: Coverage;
  contractors_coverage?: Coverage;
  cyber_crime?: Coverage;
  mpl_exclusion_major_shareholder?: Coverage;
  digital_access?: Coverage;
  ip_sublimit_status?: Coverage;
  ny_errors_and_omissions?: Coverage;
  ny_fiduciary?: Coverage;
  ny_directors_and_officers?: Coverage;
  ny_employment_practices_liability?: Coverage;
  ny_cyber?: Coverage;
  ny_d_and_o_entity?: Coverage;
  ny_d_and_o_individual?: Coverage;
  ny_d_and_o_odl?: Coverage;
  ny_d_and_o_reimbursement?: Coverage;
  ny_d_and_o_tria?: Coverage;
  off_premise_business_property?: Coverage;
  pci_violations?: Coverage;
  personal_and_advertising_injury?: Coverage;
  product_and_completed_ops?: Coverage;
  reputational_harm?: Coverage;
  tria?: Coverage;
  wage_hour_sublimit_status?: Coverage;
  cpp_general_liability?: Coverage;
  cpp_gl_damages_prem_rented?: Coverage;
  cpp_gl_digital_access?: Coverage;
  cpp_gl_ebl?: Coverage;
  cpp_gl_hnoa?: Coverage;
  cpp_prop_bii?: Coverage;
  cpp_prop_contents?: Coverage;
  cpp_prop_wind?: Coverage;
  cpp_work_from_anywhere?: Coverage;
  cpp_gl_pai?: Coverage;
  cpp_gl_limit_PAI?: Coverage;
  gl_med_pay?: Coverage;
  gl_pai?: Coverage;
};

export const ALL_COVERAGES = [
  'business_personal_property',
  'cem_errors_and_omissions',
  'cpp_general_liability',
  'cpp_gl_damages_prem_rented',
  'cpp_gl_ebl',
  'cpp_gl_hnoa',
  'cpp_prop_bii',
  'cpp_prop_contents',
  'cpp_prop_wind',
  'gl_med_pay',
  'gl_pai',
  'crime',
  'cyber', // delete?
  'directors_and_officers',
  'ny_directors_and_officers',
  'ny_employment_practices_liability',
  'ny_fiduciary',
  'ny_errors_and_omissions',
  'employee_benefits_liability',
  'employment_practices_liability',
  'errors_and_omissions',
  'fiduciary',
  'first_party_cyber',
  'general_liability',
  'hired_and_nonowned_auto',
  'third_party_cyber',
  'cpp_gl_pai',
  'cpp_gl_limit_PAI',

  // hidden under crime
  // 'computer_fraud',
  // 'counterfeit_money',
  // 'employee_dishonesty',
  // 'forgery_and_alteration',
  // 'fraudulent_impersonation',
];

export const FIRST_PARTY_CYBER = 'first_party_cyber';
export const THIRD_PARTY_CYBER = 'third_party_cyber';
export const CYBER_COVERAGES = [FIRST_PARTY_CYBER, THIRD_PARTY_CYBER];
