import { FC, ReactNode } from 'react';
import { Card, Typography } from '@vouch/ui';

type InfoCardProps = {
  description: ReactNode;
};

const InfoCard: FC<InfoCardProps> = ({ description }) => (
  <Card className="info-card" data-testid="info-card">
    <Typography
      sx={(theme) => ({
        '.emphasized-text': {
          color: theme.designTokens.colorPrimaryDarker,
          fontWeight: 700,
        },
      })}
      fontWeight="normal"
      variant="subtitle2"
    >
      {description}
    </Typography>
  </Card>
);

export default InfoCard;
