import { catchError, LEVEL_CRITICAL } from 'features/errors/errors.actions';
import { logExpiredApplication } from 'features/eventTracking/eventTracking.actions';
import { getProgramVersion, sessionApplicationId } from 'features/session/sessionSlice';
import { ProgramVersion } from 'shared/services/onboarding-funnel';
import { useDuplicateExpiredApplicationMutation } from 'generated/graphql';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOnboardingFunnelURL } from 'shared/services/onboarding-funnel';
import VouchLoadingSm from 'components/atoms/icons/VouchLoadingSm';
import { PrimaryButton } from '@vouch/ui';

const DuplicateExpiredApplicationContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const programVersion = useSelector(getProgramVersion);
  const applicationId = useSelector(sessionApplicationId);

  useEffect(() => {
    dispatch(logExpiredApplication());
  }, [dispatch]);

  const navigateToReview = (newAppId: string) => {
    const url = getOnboardingFunnelURL('review', programVersion as ProgramVersion);
    url.searchParams.set('applicationId', newAppId);
    window.location.assign(url.href);
  };

  const [duplicateExpiredApplication, { loading }] = useDuplicateExpiredApplicationMutation({
    onError: (e) => {
      dispatch(
        catchError({
          level: LEVEL_CRITICAL,
          message: `Unable to duplicate expired application: ${e.message}`,
          extraContext: { applicationId },
        })
      );
    },
    onCompleted: (data) => {
      navigateToReview(data.duplicateExpiredApplication.id);
    },
  });

  const requestDuplicateExpiredApplication = () => {
    duplicateExpiredApplication({
      variables: {
        applicationId,
      },
    });
  };

  return (
    <PrimaryButton
      variant="contained"
      disabled={loading}
      onClick={requestDuplicateExpiredApplication}
      data-testid={'duplicate-expired-application-container'}
    >
      {loading ? <VouchLoadingSm /> : 'Go to Review'}
    </PrimaryButton>
  );
};

export default DuplicateExpiredApplicationContainer;
