import { Box, Typography } from '@vouch/ui';
import { FC } from 'react';
import '../CoverageCard.scss';
import { formatCents } from 'shared/helpers';
import { ProductOrGroupPricing } from 'features/quotes/quotes.types';
import { LimitsOrOutOfPockets } from 'features/coverages/coverages.types';
import { Offered } from './Offered';
import { NotOffered } from './NotOffered';
import { TopClaimSourceCallout } from './components';
import { runningListOfUiTokens } from 'shared/ui/tokenNames';

export type CoverageContentProps = {
  accepted: boolean;
  cardTitle: string;
  groupToken: string;
  hasActiveDiscretion: boolean;
  limits: Record<string, LimitsOrOutOfPockets>;
  missingDependencies: string | JSX.Element;
  offered: boolean;
  pricing: ProductOrGroupPricing | { annual: { totalPremiumCents: number } };
  toggleCoverage: () => void;
  toggleable?: boolean;
};
const CoverageCardContent: FC<CoverageContentProps> = (props) => {
  const {
    accepted,
    cardTitle,
    hasActiveDiscretion,
    limits,
    missingDependencies,
    offered,
    groupToken,
    pricing,
    toggleCoverage,
    toggleable = true,
  } = props;

  const shouldShowTopClaimSourceCallout =
    (cardTitle === runningListOfUiTokens.employment_practices_liability ||
      cardTitle === runningListOfUiTokens.ny_employment_practices_liability_group) &&
    offered;
  // Only show annual for now
  const { annual } = pricing;
  return (
    <div>
      <Box height="18px">{shouldShowTopClaimSourceCallout ? <TopClaimSourceCallout /> : null}</Box>
      <Box className="coverage-title-with-alert" data-testid="coverage-title">
        <Typography
          variant="h4"
          sx={(theme) => (!offered ? { color: theme.designTokens.colorNeutralDark } : {})}
        >
          {cardTitle}
        </Typography>
      </Box>
      <Box className="coverage-content" data-testid="coverage-content">
        {offered ? (
          <Offered
            accepted={accepted}
            limits={limits}
            price={formatCents(annual.totalPremiumCents)}
            missingDependencies={missingDependencies}
            toggleCoverage={toggleCoverage}
            toggleable={toggleable}
            groupToken={groupToken}
          />
        ) : (
          <NotOffered hasActiveDiscretion={hasActiveDiscretion} groupToken={groupToken} />
        )}
      </Box>
    </div>
  );
};

export default CoverageCardContent;
