import { PropsWithChildren, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  idToken,
  establishAuthSession,
  isLoadingAuth,
  setLoginUrl,
  setApplicationId,
} from './session/sessionSlice';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { ErrorBoundary } from 'react-error-boundary';
import { catchError, LEVEL_CRITICAL } from './errors/errors.actions';
import { compact } from 'lodash';
import Crumbs from '../Pages/crumbs/CrumbsPage';
import { LoadSpinner } from '@vouch/ui';
import { useSearchParams } from 'react-router-dom';

interface AuthProps {
  gatewayDomain: string;
  loginPortalUrl: string;
}

const authLink = (token: string) =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

const Authenticator = (props: PropsWithChildren<AuthProps>): JSX.Element => {
  const dispatch = useDispatch();
  const { gatewayDomain, loginPortalUrl } = props;
  const token = useSelector(idToken);
  const authPending = useSelector(isLoadingAuth);
  const { segmentTesting } = useFlags();
  const launchDarklyClient = useLDClient();

  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [params] = useSearchParams();
  const authSource = params.get('authSource') || 'auth0';

  const httpLink = createHttpLink({
    uri: gatewayDomain,
    credentials: 'include',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const limitOopKey = (args: Record<string, any> | null) =>
    compact(['id', 'label', args?.locationId ? 'locationId' : null]);

  const client = useMemo(
    () =>
      new ApolloClient({
        link: authLink(token).concat(httpLink),
        cache: new InMemoryCache({
          typePolicies: {
            Coverage: {
              keyFields: ['id', 'uiToken'],
            },
            LocationCoverage: {
              keyFields: ['location', 'id'],
            },
            Limit: {
              keyFields: limitOopKey,
            },
            OutOfPocket: {
              keyFields: limitOopKey,
            },
          },
        }),
      }),
    [httpLink, token]
  );

  if (authSource === 'auth0') {
    if (!token && !authPending) {
      dispatch(setLoginUrl({ loginPortalUrl }));
      dispatch(setApplicationId());
      dispatch(
        establishAuthSession({
          getAccessTokenSilently,
          getIdTokenClaims,
          redirectToLogin: () => {
            window.location.href = loginPortalUrl;
          },
          launchDarklyClient,
          segmentTesting,
        })
      );
    }
  } else {
    dispatch(setApplicationId());
  }

  const handleError = (error: Error) => {
    dispatch(
      catchError({ level: LEVEL_CRITICAL, message: `Unhandled ${error.name}: ${error.message}` })
    );
  };

  if (token || authSource === 'auth_service') {
    return (
      <ApolloProvider client={client}>
        <ErrorBoundary onError={handleError} FallbackComponent={Crumbs}>
          {props.children}
        </ErrorBoundary>
      </ApolloProvider>
    );
  } else {
    return <LoadSpinner message="Authenticating..." />;
  }
};

export default Authenticator;
