import { PrimaryButton } from '@vouch/ui';
import { logoutPortalUrl } from 'environment';
import { FC } from 'react';

const SignOutButtonContainer: FC = () => {
  return (
    <PrimaryButton
      data-testid={'unauthorized-modal-logout-btn'}
      variant="outlined"
      onClick={() => {
        window.location.href = logoutPortalUrl;
      }}
    >
      Sign Out
    </PrimaryButton>
  );
};

export default SignOutButtonContainer;
