import { Theme } from '@mui/material';

const MuiFormHelperText = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      color: theme.designTokens.colorNeutralDark,
      marginBottom: '8px',
      lineHeight: 1.2,
      letterSpacing: 0,
      '&.has-icon': {
        paddingLeft: '1.25rem',
        position: 'relative',
      },
      '.MuiSvgIcon-root': {
        left: 0,
        position: 'absolute',
        top: 1,
      },
    }),
  },
};

export default MuiFormHelperText;
