const MuiAvatar = {
  styleOverrides: {
    root: {
      fontSize: 14,
      fontWeight: 700,
      height: 38,
      width: 38,
    },
  },
};

export default MuiAvatar;
