import {
  Link as MUILink,
  LinkProps as MUILinkProps,
  Typography,
  TypographyVariant,
} from '@mui/material';
import React, { FC } from 'react';

export interface LinkProps extends MUILinkProps {
  type: 'internal' | 'external';
  icon?: JSX.Element;
  variant?: TypographyVariant;
  text: string;
}

const IconLink: FC<LinkProps> = ({ icon, variant, text, type, ...props }: LinkProps) => {
  const linkProps = { ...props };
  if (type === 'external') {
    linkProps.target = '_blank';
  }
  return (
    <MUILink {...linkProps}>
      <Typography variant={variant || 'inherit'}>
        {text} {icon}
      </Typography>
    </MUILink>
  );
};

export default IconLink;

IconLink.defaultProps = {
  underline: 'hover',
};
