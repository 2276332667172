import { Theme } from '@mui/material';

const MuiPickersDay = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      color: theme.designTokens.colorBlack,
      fontSize: '0.875rem',
      '&.Mui-selected': {
        backgroundColor: theme.designTokens.colorPrimary,
      },
    }),
    today: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.designTokens.colorPrimaryLightest,
      borderColor: theme.designTokens.colorBlack,
    }),
  },
};

export default MuiPickersDay;
