import { idToken } from 'features/session/sessionSlice';
import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserInfo } from './api';

type UserInfoData = {
  isAdmin: boolean;
};

type ContextValue = {
  loading: boolean;
  data?: UserInfoData;
};

const contextValue: ContextValue = {
  loading: false,
};

export const UserInfoContext = createContext<ContextValue>(contextValue);

export default function UserInfoProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<UserInfoData | undefined>();
  const token = useSelector(idToken);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await getUserInfo(token);
      setData({
        isAdmin: response.data.is_admin,
      });
      setLoading(false);
    };

    fetchData();
  }, [token]);

  return <UserInfoContext.Provider value={{ loading, data }}>{children}</UserInfoContext.Provider>;
}
