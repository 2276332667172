import { FC, ReactElement } from 'react';
import { Box, CardMedia, List, AlertIcon, Tab, Tabs, Typography, styled } from '@vouch/ui';
import '../CoverageCard.scss';
import VouchLogo from 'components/atoms/icons/VouchLogo';
import { formatTruncatedCents } from 'shared/helpers';
import { Coverage, LimitsOrOutOfPockets } from 'features/coverages/coverages.types';
import Subcoverage from 'components/molecules/cards/SubcoverageCard';
import {
  displayIfAcceptedElements,
  hiddenElements,
  runningListOfUiTokens,
} from 'shared/ui/tokenNames';

type RecommendedProps = {
  recommended: boolean;
  offered: boolean;
  accepted: boolean;
};
export const Recommended = ({ recommended, offered, accepted }: RecommendedProps): ReactElement => (
  <>
    {recommended && offered && !accepted ? (
      <Box mb={1} mt={0.5} display="flex" alignItems="center" data-testid="recommend">
        <VouchLogo />
        <Typography
          sx={(theme) => ({
            color: theme.designTokens.colorPrimary,
          })}
          component="span"
          variant="detail"
          fontWeight="normal"
        >
          RECOMMENDED
        </Typography>
      </Box>
    ) : (
      ''
    )}
  </>
);

export const TopClaimSourceCallout = (): ReactElement => (
  <Box display="flex" alignItems="center" data-testid="topClaimSourceCallout">
    <AlertIcon sx={(theme) => ({ fill: theme.designTokens.colorPrimaryDarker })} />
    <Typography
      sx={(theme) => ({
        color: theme.designTokens.colorPrimaryDarker,
      })}
      pl="0.25rem"
      fontWeight="700"
      fontSize="13px"
      lineHeight="18px"
    >
      #1 Source of Claims
    </Typography>
  </Box>
);

export const PriceInfo: FC<{ amount: string }> = ({ amount }): ReactElement => {
  return (
    <Box className="price">
      <Typography variant="h4" fontWeight="normal">
        {amount}
      </Typography>
      <Typography variant="detail" sx={(theme) => ({ fontSize: theme.typography.body1.fontSize })}>
        PER YEAR
      </Typography>
    </Box>
  );
};

// TODO: Limit text
type AffiliateProps = {
  // limit: Limit;
  logo: string;
};
export const Affiliate: FC<AffiliateProps> = ({
  // limit: { amount, suggestedValue },
  logo,
}: AffiliateProps) => {
  return (
    <Box data-testid="affiliate" pt={1}>
      <CardMedia component="img" style={{ width: 'auto' }} src={logo} title="SVB logo" />
      {/* <Box mt={0.5}>
        {amount >= suggestedValue ? (
          <Typography color="primary">Meets limit requirements</Typography>
        ) : (
          <Typography color="error">Below limit requirements</Typography>
        )}
      </Box> */}
    </Box>
  );
};
type CoverageTabsProps = {
  coverageTokens: string[];
  selectedCoverageIndex: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleTabChange: any;
};
export const CoverageTabs: FC<CoverageTabsProps> = ({
  coverageTokens,
  selectedCoverageIndex,
  handleTabChange,
}: CoverageTabsProps) => {
  const StyledTabs = styled(Tabs)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
    },
  }));

  return coverageTokens.length > 1 ? (
    <Box
      pb={0}
      pt={2.5}
      className="coverage-tabs"
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      <StyledTabs
        value={selectedCoverageIndex}
        aria-label="simple tabs example"
        onChange={handleTabChange}
      >
        {coverageTokens.map((token) => {
          return (
            <Tab
              sx={{ minWidth: 160 }}
              key={token}
              label={<Typography variant="h5">{runningListOfUiTokens[token]}</Typography>}
            />
          );
        })}
      </StyledTabs>
    </Box>
  ) : null;
};

type LimitProps = { availableLimits: LimitsOrOutOfPockets[] };
export const LimitInfo: FC<LimitProps & { showLimits: boolean }> = ({
  availableLimits,
  showLimits,
}) => {
  if (showLimits) {
    return (
      <Box className="limit">
        <Typography variant="h4" fontWeight="normal">
          {formatTruncatedCents(availableLimits[0].amount)}
        </Typography>
        <Typography
          variant="detail"
          mt="0.1rem"
          sx={(theme) => ({ fontSize: theme.typography.body1.fontSize })}
        >
          LIMIT
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box className="limit multiple">
        <Typography
          lineHeight="1.5rem"
          variant="detail"
          sx={(theme) => ({
            fontSize: theme.typography.body1.fontSize,
          })}
        >
          LIMITS INSIDE
        </Typography>
      </Box>
    );
  }
};

export const shouldDisplay = (subcoverage: Coverage) => {
  const uiToken = subcoverage.uiToken;

  if (hiddenElements[uiToken] || !subcoverage.visibleToUser || !runningListOfUiTokens[uiToken]) {
    return false;
  }

  if (displayIfAcceptedElements[uiToken]) {
    return subcoverage.accepted;
  }

  return true;
};

export const getSubcoverageDisplayKeys = (implicitElements: Record<string, Coverage>) => {
  const subcoverageDisplayKeys: string[] = Object.keys(implicitElements).filter((key) =>
    shouldDisplay(implicitElements[key])
  );

  return subcoverageDisplayKeys;
};

type SubcoverageSectionProps = {
  closeModal?: (() => void) | undefined;
  implicitElements: Record<string, Coverage>;
};

export const SubcoverageSection: FC<SubcoverageSectionProps> = ({
  closeModal,
  implicitElements,
}: SubcoverageSectionProps) => {
  const displayKeys = getSubcoverageDisplayKeys(implicitElements);

  return (
    <>
      {displayKeys.length > 0 && (
        <Box className="subcoverages" data-testid="subcoverage-section">
          <List>
            {displayKeys.map((sub, i) => (
              <Subcoverage key={i} closeModal={closeModal} {...implicitElements[sub]} />
            ))}
          </List>
        </Box>
      )}
    </>
  );
};
