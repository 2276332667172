import { Box, Card, Grid, styled, Typography } from '@vouch/ui';
import { Toggle } from 'components/atoms/buttons';
import LocationLimitCard from 'containers/LocationLimitCardContainer';
import { ConvertedLocationCoverage } from 'features/coverages/coverages.types';
import { FC } from 'react';
import { space } from 'shared/styles/Basegrid';
import Subcoverage from 'components/molecules/cards/SubcoverageCard';

import { LocationCard as LocationCardStyle } from './LocationCard.styles';
import { toggleLocationAcceptance } from 'features/coverages/coverages.actions';
import { useDispatch } from 'react-redux';
import { Address } from 'generated/graphql';

interface LocationProps {
  groupToken: string;
  locationDetails: Record<string, ConvertedLocationCoverage>;
  unsupportedLocations?: Address[];
}

const LocationCard: FC<LocationProps> = ({ groupToken, locationDetails, unsupportedLocations }) => {
  const dispatch = useDispatch();
  const LimitGrid = styled(Grid)(({ theme }) => ({
    margin: `0 ${space.Medium}px`,
    width: 'auto',
    '.MuiGrid-item': {
      display: 'flex',
      marginBottom: 0,
      position: 'relative',
      justifyContent: 'center',
    },
    '.MuiGrid-item + *:before': {
      backgroundColor: theme.designTokens.colorSecondary,
      bottom: 0,
      content: '""',
      height: 100,
      left: 0,
      margin: 'auto',
      position: 'absolute',
      right: 'auto',
      top: 0,
      width: '1px',
    },
  }));

  const LocationInfoCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.designTokens.colorSecondary}`,
    borderRadius: 0,
  }));
  const LocationDetailCard = styled(Card)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    borderTop: `1px solid ${theme.designTokens.colorSecondary}`,
    borderRadius: 0,
  }));

  type LocationInfoProps = {
    accepted?: boolean;
    isToggleable?: boolean;
    isSupported: boolean;
    location: Address;
    uiToken?: string;
  };
  const LocationInfo = ({
    accepted,
    isToggleable = false,
    isSupported,
    location,
    uiToken,
  }: LocationInfoProps) => (
    <Card className="location-title">
      <Grid container justifyContent="space-between">
        <Grid
          item
          sx={(theme) => (isSupported ? {} : { color: theme.designTokens.colorNeutralDark })}
        >
          <Typography variant="h5">{location.addressLine1}</Typography>
          <Typography variant="h5">
            {location.city}, {location.state} {location.zip}
          </Typography>
        </Grid>
        <Grid item>
          {isToggleable && (
            <Toggle
              handleClick={() => {
                dispatch(
                  toggleLocationAcceptance({
                    coverageToken: uiToken as string,
                    locationId: location.id,
                    groupToken,
                  })
                );
              }}
              checked={accepted}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );

  return (
    <LocationCardStyle data-testid="location-card">
      {locationDetails &&
        Object.entries(locationDetails).map(([locationId, location]) => (
          <LocationDetailCard key={location.uiToken + locationId}>
            <LocationInfo
              uiToken={location.uiToken}
              isSupported
              isToggleable={!location.isPrimary}
              location={location.location}
              accepted={location.accepted}
            />
            <Card key={location.id}>
              {Object.entries(location.implicitElements).map(([key, element]) => (
                <Subcoverage key={key} {...element} />
              ))}
            </Card>
            <LimitGrid container justifyContent="flex-start">
              {Object.entries(location.limits).map(([key, limit]) => (
                <Grid key={limit.id + key} item xs={12} sm={6} justifyContent="center">
                  <LocationLimitCard
                    {...location.limits[key]}
                    coverageToken={location.uiToken}
                    groupToken={groupToken}
                    limitId={limit.id}
                    locationId={location.location.id}
                    type="limits"
                  />
                </Grid>
              ))}
              {Object.entries(location.outOfPockets).map(([key, outOfPocket]) => (
                <Grid key={outOfPocket.id + key} item xs={12} sm={6}>
                  <LocationLimitCard
                    {...location.outOfPockets[key]}
                    coverageToken={location.uiToken}
                    groupToken={groupToken}
                    type="outOfPockets"
                    locationId={location.location.id}
                    limitId={outOfPocket.id}
                  />
                </Grid>
              ))}
            </LimitGrid>
          </LocationDetailCard>
        ))}
      {unsupportedLocations &&
        unsupportedLocations.map((location, i) => (
          <LocationDetailCard key={`location-${i}`}>
            <LocationInfo location={location} isSupported={false} />
            <Card>
              <Box ml={2} mb={2}>
                <Typography
                  variant="subtitle2"
                  sx={(theme) => ({ color: theme.designTokens.colorNeutralDark })}
                >
                  Location not supported
                </Typography>
              </Box>
            </Card>
          </LocationDetailCard>
        ))}
      <LocationInfoCard>
        <Typography variant="h5" mb={1}>
          Landlord requiring something else?
        </Typography>
        <Typography variant="body1">
          Vouch also has less common property coverages like earthquake, flood, coverage for
          property in storage units, and more. Share your contract requirements and request them
          below.
        </Typography>
      </LocationInfoCard>
    </LocationCardStyle>
  );
};

export default LocationCard;
