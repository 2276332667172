import { ReactElement } from 'react';
import { Typography, TypographyProps } from '@vouch/ui';
import { Launch } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { Lock } from '../../assets/icons/Lock';
import IconLink from '../links/IconLink';
import CopyrightIcon from '@mui/icons-material/Copyright';

const Footer = (props: TypographyProps): ReactElement => (
  <Grid container align-items="left" justify-content="flex-start" direction="column">
    <Typography
      className={props.className}
      sx={(theme) => ({
        color: theme.designTokens.colorNeutralDark,
        fontSize: '0.875rem',
        lineHeight: 1.25,
        pl: 2,
        position: 'relative',
        textAlign: 'left',
      })}
    >
      <Lock style={{ top: '3px', left: 0, position: 'absolute' }} />
      Your responses will be kept confidential in accordance with our{' '}
      <IconLink
        href="https://www.vouch.us/legal/terms-of-use"
        icon={<Launch />}
        target="_blank"
        sx={(theme) => ({
          display: 'inline-block',
          fontSize: '0.875rem',
          color: theme.designTokens.colorNeutralDark,
        })}
        text="Terms of Use"
        type="external"
      />{' '}
      and{' '}
      <IconLink
        href="https://www.vouch.us/legal/privacy-notice"
        icon={<Launch />}
        target="_blank"
        sx={(theme) => ({
          display: 'inline-block',
          fontSize: '0.875rem',
          color: theme.designTokens.colorNeutralDark,
        })}
        text="Privacy Notice"
        type="external"
      />
      .
    </Typography>
    <Typography
      className={props.className}
      sx={(theme) => ({
        fontSize: '0.875rem',
        lineHeight: 1.25,
        pl: 2,
        position: 'relative',
        textAlign: 'left',
        color: theme.designTokens.colorNeutralDark,
      })}
    >
      <CopyrightIcon style={{ top: '1px', left: '-1px', position: 'absolute' }} />
      {new Date().getFullYear()} Vouch, Inc. All Rights Reserved.
    </Typography>
  </Grid>
);

export default Footer;
