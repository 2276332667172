import { FC, MouseEventHandler, useState } from 'react';
import { LimitInfo, PriceInfo } from './components';
import { LimitsOrOutOfPockets } from 'features/coverages/coverages.types';
import { Box, Typography, ExpandMoreIcon } from '@vouch/ui';
import { Toggle } from 'components/atoms/buttons';
import VouchModal from 'components/atoms/display/Modal/Modal';
import EPLLearnMoreCard from '../variants/EPLLearnMoreCard';

type OfferedCoverageProps = {
  accepted: boolean;
  groupToken: string;
  limits: Record<string, LimitsOrOutOfPockets>;
  missingDependencies: string | JSX.Element;
  price: string;
  toggleCoverage: () => void;
  toggleable: boolean;
};

export const Offered: FC<OfferedCoverageProps> = (props) => {
  const { accepted, limits, groupToken, price, missingDependencies, toggleCoverage, toggleable } =
    props;
  const [open, setOpen] = useState<boolean>(false);
  const handleClick: MouseEventHandler = (event) => {
    toggleCoverage();
    event.stopPropagation();
  };

  const learnMore: MouseEventHandler = (event) => {
    setOpen(true);
    event.stopPropagation();
  };

  const convertedLimits: LimitsOrOutOfPockets[] = Object.values(limits);
  const showLimits =
    convertedLimits.length === 1 &&
    !(groupToken === 'basic_group') &&
    !(groupToken === 'ny_cyber_group') &&
    !(groupToken === 'cyber_group');

  const Detail: FC = () => {
    return (
      <Box className="hover-hint" data-testid="hover-hint">
        <ExpandMoreIcon sx={{ marginTop: 1, width: 24, height: 24 }} />
        <Typography
          variant="subtitle2"
          sx={(theme) => ({ color: theme.designTokens.colorSecondaryDarker })}
        >
          Click to adjust limits
        </Typography>
      </Box>
    );
  };
  return (
    <>
      <Box display="flex" className="card-middle" data-testid="offered">
        {/* Hint --- OR AFFILIATION + AFFILIATION REQUIREMENT */}
        {groupToken === 'employment_practices_liability_group' ||
        groupToken === 'ny_employment_practices_liability_group' ? (
          <Box>
            <Typography variant="body2" lineHeight="18px">
              Solve issues and save on claims with our 24/7 Hotline.{' '}
              <Typography
                component="span"
                variant="body2"
                lineHeight="18px"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={learnMore}
              >
                Learn more
              </Typography>
            </Typography>
            <Box>
              <VouchModal modalOpen={open} modalClose={() => setOpen(false)} isLoading={false}>
                <EPLLearnMoreCard closeModal={() => setOpen(false)} />
              </VouchModal>
            </Box>
          </Box>
        ) : (
          <Detail />
        )}
      </Box>
      <Box className="coverage-details" data-testid="coverage-details">
        <PriceInfo amount={price} />
        <LimitInfo availableLimits={convertedLimits} showLimits={showLimits} />
      </Box>

      <Box className="coverage-acceptance" data-testid="coverage-acceptance">
        {missingDependencies ? (
          <Typography data-testid="missing-dependencies" fontWeight="normal" variant="subtitle2">
            {missingDependencies}
          </Typography>
        ) : (
          toggleable && <Toggle handleClick={handleClick} checked={accepted} />
        )}
      </Box>
    </>
  );
};
