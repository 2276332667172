import { FC, ReactElement } from 'react';
import { Box, Grid, Typography } from '@vouch/ui';

type PolicyProps = {
  coverageList?: ReactElement[];
  providedOnRequest?: boolean;
};

const PolicySection: FC<PolicyProps> = ({
  coverageList,
  providedOnRequest,
}: PolicyProps): ReactElement => {
  return (
    <Grid container>
      {providedOnRequest && (
        <Grid item margin={'20px'} container>
          <Typography variant="subtitle2">
            {providedOnRequest && (
              <Box
                component="span"
                sx={(theme) => ({ color: theme.designTokens.colorSecondaryDarkest })}
              >
                Provided on request via Vouch Specialty, our broker division.
              </Box>
            )}
          </Typography>
        </Grid>
      )}
      {coverageList && (
        <Grid item container>
          {coverageList}
        </Grid>
      )}
    </Grid>
  );
};

export default PolicySection;
