import './middlewares/DatadogRUM';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import configureSegment from '@vouch/third-party/segment';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import { customersUrl, launchDarklyId, logRocketAppId } from './environment';

window.__RELEASE_SHA__ = __RELEASE_SHA__;

const domain = customersUrl.slice(customersUrl.indexOf('vouch'));
LogRocket.init(logRocketAppId, {
  rootHostname: domain,
  release: __RELEASE_SHA__,
  network: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    requestSanitizer: (request: any) => {
      // By default, remove request headers & body content for all requests recorded by LogRocket
      // To opt-in some requests, add a conditional here to filter on the endpoint URL.
      // For config docs, see: https://docs.logrocket.com/reference#network

      // NB the 'any' types on req/resp are intentional; not only does LogRocket not export their
      // options and args types from their d.ts file, they violate their own types: the Rollbar
      // docs say to null these properties, but the type defs for them are not nullable.
      if (request.url.endsWith('graphql') && request.method === 'POST') {
        request.body = {
          operationName: JSON.parse(request.body || '{}').operationName,
        };
      } else {
        request.body = null;
      }

      request.headers = null;

      return request;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    responseSanitizer: (response: any) => {
      response.body = null;
      return response;
    },
  },
});

configureSegment();

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyId,
  });
  const container = document.getElementById('root');
  const root = createRoot(container!);
  // TODO: Create consolidated ServicesProvider for tooling
  root.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
