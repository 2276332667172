import { Button, ExpandMoreIcon, ListItem, ListItemIcon, ListItemText, styled } from '@vouch/ui';

export const SubcoverageListItem = styled(ListItem)(({ theme }) => ({
  boxSizing: 'border-box',
  borderBottom: `1px solid ${theme.designTokens.colorPrimary}`,
  marginBottom: 0,
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginTop: 0,
  padding: 0,
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  width: 'auto',
}));

export const SubcoverageListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  minWidth: theme.spacing(1.5),
  flexDirection: 'row-reverse',
}));
export const SubcoverageListItemText = styled(ListItemText)(({ theme }) => ({
  minWidth: theme.spacing(3),
  maxWidth: `calc(100% - ${theme.spacing(10)})`,
}));

export const SubcoverageInfoIcon = styled(ExpandMoreIcon, {
  shouldForwardProp: (prop) => prop !== 'infoExpanded',
})<{ infoExpanded?: boolean }>(({ infoExpanded }) => ({
  color: 'black',
  height: 24,
  width: 24,
  transform: infoExpanded ? 'rotate(180deg)' : 'none',
}));

export const SubcoverageInfoButton = styled(Button)(({ theme }) => ({
  padding: 0,
  width: theme.spacing(1),
  marginLeft: theme.spacing(1),
  minWidth: 0,
  '.MuiTouchRipple-root': {
    display: 'none',
  },
}));
