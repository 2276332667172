import { FC, ReactElement } from 'react';
import { Grid, Typography } from '@vouch/ui';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { sessionSelector } from 'features/session/sessionSlice';
import { availableEffectiveDateType } from 'features/quotes/quotes.types';
import EffectiveDateFieldContainer from 'containers/EffectiveDateFieldContainer';
import ExpiresOn from 'components/atoms/form/ExpiresOn/ExpiresOn';

type TitleProps = {
  availableEffectiveDate: availableEffectiveDateType;
  quoteExpirationString: string;
  applicationExpirationString: string;
};

const TitleSection: FC<TitleProps> = (props: TitleProps): ReactElement => {
  const { mplRefile2 } = useFlags();
  const { availableEffectiveDate, quoteExpirationString, applicationExpirationString } = props;

  const isRenewal = useSelector(sessionSelector).isRenewal;

  return (
    <Grid container>
      <Grid item>
        <Typography variant="h2" fontWeight="bold">
          Your Insurance Package
        </Typography>
        <EffectiveDateFieldContainer availableEffectiveDate={availableEffectiveDate} />
        {mplRefile2 && !isRenewal && (
          <ExpiresOn
            quoteExpirationString={quoteExpirationString}
            applicationExpirationString={applicationExpirationString}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TitleSection;
