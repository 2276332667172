import { Theme } from '@mui/material';

const MuiPaper = {
  variants: [
    {
      props: { variant: 'quote' },
      style: ({ theme }: { theme: Theme }) => ({
        background: theme.designTokens.colorSecondaryLighter,
        borderBottom: `1px solid ${theme.designTokens.colorSecondary}`,
      }),
    },
  ],
};

export default MuiPaper;
