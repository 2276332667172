import { FC, ReactElement } from 'react';
import PolicySection from 'components/organisms/policy-section';
import CoverageCard from 'containers/CoverageCardContainer';
import { Product } from 'features/quotes/quotes.types';

type ProductSectionProps = {
  product: Product;
};

const BasicProduct: FC<ProductSectionProps> = ({ product }): ReactElement => {
  const {
    uiToken,
    coverageGroups: { basic_group },
  } = product;

  return (
    <PolicySection
      key={uiToken}
      coverageList={[
        <CoverageCard key={basic_group.uiToken} group={basic_group} product={product} />,
      ]}
    />
  );
};
export default BasicProduct;
