const MuiCheckbox = {
  styleOverrides: {
    root: {
      padding: 8,
      paddingTop: 4,
      svg: {
        height: 20,
        width: 20,
      },
    },
  },
};

export default MuiCheckbox;
