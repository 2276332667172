import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import { coverageActions } from './coveragesSlice';
import { Quote } from 'features/quotes/quotes.types';
import { loadAcceptedCoverageGroups } from 'features/coverageGroups/coverageGroupsSlice';
import { State } from 'store';
import { each, values } from 'lodash';

export const {
  clearOriginalData,
  loadCoverage,
  toggleCoverageAcceptance,
  updateLimitOrOutOfPocketAmount,
  toggleLocationAcceptance: _toggleLocationAcceptance,
  updateLocationLimitOrOutOfPocketAmount,
} = coverageActions;
export const loadCoverages = createAsyncThunk(
  'coverages/loadCoverages',
  (quote: Quote, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(loadAcceptedCoverageGroups({ quoteId: quote.id, products: quote.products }));
    dispatch(clearOriginalData());
    Object.values(quote.products).forEach((product) =>
      Object.values(product.coverageGroups).forEach((group) =>
        Object.values(group.elements).forEach((coverage) => {
          dispatch(loadCoverage({ coverage }));
        })
      )
    );
  }
);

export const toggleLocationAcceptance = ({
  locationId,
  coverageToken,
  groupToken,
}: {
  locationId: string;
  coverageToken: string;
  groupToken: string;
}) => {
  return (dispatch: Dispatch, getState: () => State) => {
    const { coverageGroups, coverages } = getState();
    const accepted = coverages[coverageToken].locationCoverageDetails[locationId].accepted;
    const coverageGroupIsAccepted = values(
      coverageGroups.coverageGroupAcceptance[groupToken]
    ).every(Boolean);
    if (accepted || coverageGroupIsAccepted) {
      dispatch({
        type: 'coverages/toggleLocationAcceptance',
        payload: {
          coverageToken,
          locationId,
        },
      });
    } else {
      if (!coverageGroupIsAccepted) {
        dispatch({
          type: 'coverageGroups/toggleCoverageGroupAcceptance',
          payload: { groupToken },
        });
        each(coverageGroups.coverageGroupAcceptance[groupToken], (val, key) => {
          if (!val) {
            dispatch({
              type: 'coverages/toggleCoverageAcceptance',
              payload: {
                coverageToken: key,
              },
            });
          }
        });
      }
    }
  };
};
