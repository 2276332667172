import { Theme } from '@mui/material';

const MuiTableCell = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      fontFamily: theme.typography.fontFamily,
      color: theme.designTokens.colorBlack,
      fontSize: '1rem',
      fontWeight: 400,
      '.expand-row-btn svg': {
        height: '1.5rem',
        width: '1.5rem',
      },
    }),
    head: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.designTokens.colorSecondaryDarker,
      fontSize: '0.75rem',
      fontWeight: 400,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    }),
  },
};

export default MuiTableCell;
