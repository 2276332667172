import { DownloadButton } from 'components/atoms/buttons';
import { quoteIdOfLoadedCoverages } from 'features/coverageGroups/coverageGroupsSlice';
import { GetQuotePdfQuery, useGetQuotePdfLazyQuery } from 'generated/graphql';
import { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { catchError, LEVEL_CRITICAL } from 'features/errors/errors.actions';
import { saveAs } from 'file-saver';
import { quoteDownloaded } from 'features/eventTracking/eventTracking.actions';
import { sessionApplicationId, getSessionInfo } from 'features/session/sessionSlice';

const DownloadPdfContainer: FC = (): ReactElement => {
  const savePdf = (url: string) => saveAs(url, 'QuoteProposal_VouchInsurance.pdf');
  const dispatch = useDispatch();
  const applicationId = useSelector(sessionApplicationId);
  const quoteId = useSelector(quoteIdOfLoadedCoverages);
  const { email } = useSelector(getSessionInfo);

  const processQuery = (data: GetQuotePdfQuery) => {
    savePdf(data.quotePdf.url);
    dispatch(quoteDownloaded({ applicationId, quoteId, currentUserEmail: email }));
  };

  const [getPdf, { loading }] = useGetQuotePdfLazyQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: quoteId,
    },
  });

  const handleClick = async () => {
    const { data, error } = await getPdf();
    if (error) {
      return dispatch(
        catchError({
          level: LEVEL_CRITICAL,
          message: `GetQuotePdf QueryError: ${error.message}`,
        })
      );
    }

    if (data) {
      processQuery(data);
    }
  };

  return (
    <DownloadButton
      data-testid={'pdf-download-button'}
      isLoading={loading}
      disabled={loading}
      onClick={handleClick}
    />
  );
};

export default DownloadPdfContainer;
