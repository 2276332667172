import { LimitsOrOutOfPockets } from 'features/coverages/coverages.types';
import { FC } from 'react';
import { getLimitCardData } from 'features/helpers';
import { Grid, styled } from '@vouch/ui';
import LimitOrInfoCard from 'components/molecules/cards/LimitOrInfoCard';

export type LimitSectionProps = {
  accepted: boolean;
  coverageTokens: string[];
  handleLimitOrOutOfPocketChange: (
    coverageToken: string,
    type: string
  ) => (frequency: string) => (amount: number) => void;
  limits: Record<string, LimitsOrOutOfPockets>;
  outOfPockets: Record<string, LimitsOrOutOfPockets>;
  productToken: string;
  selectedCoverageIndex: number;
  sharedLimit?: LimitsOrOutOfPockets;
};

const StyledSection = styled(Grid)(({ theme }) => ({
  borderTop: `1px solid ${theme.designTokens.colorSecondary}`,
  [theme.breakpoints.down('sm')]: {
    borderBottom: `1px solid ${theme.designTokens.colorSecondary}`,
  },
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const LimitSection: FC<LimitSectionProps> = ({
  accepted,
  coverageTokens,
  productToken,
  handleLimitOrOutOfPocketChange,
  limits,
  outOfPockets,
  selectedCoverageIndex,
  sharedLimit,
}: LimitSectionProps) => {
  const sectionData: {
    limits: Record<string, LimitsOrOutOfPockets & { key?: string }>;
    outOfPockets: Record<string, LimitsOrOutOfPockets & { key?: string }>;
    sharedLimit?: LimitsOrOutOfPockets;
  } = { limits, outOfPockets };
  if (sharedLimit) sectionData.sharedLimit = sharedLimit;
  const cardData = getLimitCardData(sectionData);
  const sectionIsFull = cardData.length === 3;

  if (!limits && !outOfPockets) {
    return <></>;
  } else {
    return (
      <StyledSection container>
        {cardData.map(({ key, amount, available, customerEditable, frequency, label }, i) => {
          const displayFirst = customerEditable;
          return (
            <Grid
              order={displayFirst ? '0' : '1'}
              item
              key={i}
              xs={12}
              sm={4}
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  borderTop: `${
                    displayFirst ? 'none' : `1px solid ${theme.designTokens.colorSecondary}`
                  }`,
                },
                [theme.breakpoints.up('sm')]: {
                  borderLeft: `${
                    displayFirst && sectionIsFull
                      ? 'none'
                      : `1px solid ${theme.designTokens.colorSecondary}`
                  }`,
                },
                ...(frequency === 'policy-shared' && {
                  backgroundColor: theme.designTokens.colorSecondaryLighter,
                  display: 'flex',
                  justifyContent: 'center',
                }),
              })}
            >
              <LimitOrInfoCard
                amount={amount}
                available={available || []} // because sharedLimit.available is undefined
                coverageToken={coverageTokens[selectedCoverageIndex]}
                customerEditable={customerEditable}
                disabled={!accepted}
                frequency={frequency!}
                handleChange={handleLimitOrOutOfPocketChange}
                productToken={productToken}
                label={label}
                type={key || ''}
              />
            </Grid>
          );
        })}
      </StyledSection>
    );
  }
};
