import breakpoints from '../breakpoints';
import { Theme } from '@mui/material';

const MuiChip = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      '-webkit-text-fill-color': 'initial',
      outline: 'none',
      border: `1px solid ${theme.designTokens.colorBlack}`,
      background: theme.designTokens.colorWhite,
      height: 'auto',
      lineHeight: 'initial',
      maxWidth: '100%',
      paddingRight: 20,
      paddingLeft: 20,
      [breakpoints.down('tablet')]: {
        position: 'relative',
      },
      '&:hover, &.MuiChip-clickable:hover': {
        background: theme.designTokens.colorPrimaryLightest,
        color: theme.designTokens.colorBlack,
        '& .MuiSvgIcon-root': {
          color: theme.designTokens.colorBlack,
        },
      },
      '&:active, &.MuiChip-clickable:active': {
        background: theme.designTokens.colorPrimary,
        color: theme.designTokens.contrastText,
        '& .MuiSvgIcon-root': {
          color: theme.designTokens.contrastText,
        },
      },
      '& .MuiSvgIcon-root': {
        height: 18,
        margin: 0,
        marginRight: -10,
        width: 18,
      },
      '& .MuiTooltip-tooltip': {
        whiteSpace: 'normal',
      },
      '&.MuiButtonBase-root': {
        paddingRight: 20,
        paddingLeft: 12,
      },
      '&.has-icon': {
        paddingRight: 40,
      },
    }),
    deletable: ({ theme }: { theme: Theme }) => ({
      background: theme.designTokens.colorPrimary,
      color: theme.designTokens.contrastText,
      border: '1px solid transparent',
    }),
    deleteIcon: ({ theme }: { theme: Theme }) => ({
      color: theme.designTokens.contrastText,
      width: 15,
      height: 15,
    }),
    icon: {
      margin: 0,
      order: 1,
      padding: 0,
      '&:hover': {
        background: 'none',
      },
      '& + .MuiTooltip-popper': {
        whiteSpace: 'normal',
        minWidth: 150,
        '& .MuiTooltip-tooltip': {
          marginRight: -10,
        },
      },
    },
    label: {
      overflow: 'visible',
      whiteSpace: 'normal',
      padding: 5,
      paddingRight: 0,
      paddingLeft: 0,
      '&:not(:first-child)': {
        paddingRight: 5,
      },
      '& + .MuiSvgIcon-root.MuiChip-deleteIcon': {
        marginLeft: 5,
      },
    },
  },
};

export default MuiChip;
