export const SubCoverageDescriptions: Record<string, string | JSX.Element> = {
  d_and_o_breach_of_contract:
    'Unlike most D&O policies in the market, we can help cover losses from claims made against your business by anyone who controls a significant part of the company.',
  med_pay: 'Provides no-fault coverage for injuries suffered on premise',
  business_income_interruption:
    'Helps cover lost income if your business is interrupted due to physical loss of or damage to the property insured.',
  ip_sublimit_status: 'Helps pay for the defense of covered intellectual property claims.',
  cap_table_sublimit_status:
    'Helps pay for the defense of covered claims when your company is sued regarding ownership percentages or equity distributions.',
  breach_response:
    'Helps cover the cost of responding to a breach incident and notifying customers.',
  cem_eo_ai_endorsement:
    'AI Coverage extends your E&O insurance to cover errors stemming from your AI Products or Services. It also adds sub-limited coverage for unique AI-related E&O risks, including claims alleging algorithmic bias or discrimination, defense costs for alleged regulatory violations, and IP infringement claims related to your AI algorithms.',
  cem_media_liability:
    'Helps cover claims of libel, slander, or violation of intellectual property by a third party.',
  cem_public_relations_costs:
    'Helps cover PR costs for negative media coverage due to an errors and omissions claim.',
  cem_breach_response_costs:
    'Helps cover the cost of responding to a breach incident and notifying customers.',
  cem_bus_interrupt_system_failure:
    'Helps cover the loss of income due to cyber incidents on your systems.',
  cem_cont_bus_interrupt_system_failure:
    "Helps cover the loss of income due to cyber incidents on vendors' systems that your business may rely on (for example, cloud services like AWS, Google Cloud, and Azure).",
  cem_cyber_crime: 'Helps cover money losses from hackers, fraudsters, or social engineering.',
  cem_cyber_extortion: 'Helps pay for ransomware attacks and other extortion costs ',
  cem_network_security:
    "Helps cover damages to others when your company's network is breached or spreads malware.",
  cem_privacy_liability: "Helps cover damages from losing people's confidential data.",
  cem_privacy_regulatory: 'Helps cover legal costs from a privacy violation.',
  cem_pci_violations:
    'Helps cover the cost of PCI-DSS (Payment Card Industry Data Security Standard) violations (such as poorly protecting customer credit card data).',
  computer_fraud:
    'Helps pay for losses due to fraudulent data entry and fraudulent instructions to a financial institution to transfer money.',
  contractors_coverage: '',
  cyber_crime: 'Helps cover money losses from hackers, fraudsters, or social engineering.',
  damage_rented_premise: 'Provides coverage for damage done to property temporarily rented to you',
  digital_access:
    "Covers legal liabilities if your website or app doesn't meet Americans with Disabilities Act (ADA) or similar state accessibility laws.",
  employee_dishonesty: 'Helps pay for losses due to employee theft',
  forgery_and_alteration:
    'Helps pay for losses due to forgery or alteration of things such as checks and promissory notes',
  fraudulent_impersonation:
    'Helps pay for losses due to fraudulent transfers issued by an employee',
  mpl_exclusion_major_shareholder:
    'Unlike most D&O policies in the market, we can help cover losses from claims made against your business by anyone who controls a significant part of the company.',
  on_premise_money: 'Helps pay for losses due to physical theft, disappearance, or destruction',
  off_premise_business_property:
    'Vouch exclusive. Extends your business personal property coverage to off premise locations that your employees work remotely from.',
  pci_violations:
    'Helps cover the cost of PCI-DSS (Payment Card Industry Data Security Standard) violations (such as poorly protecting customer credit card data).',
  personal_and_advertising_injury:
    'Provides coverage for accusations of slander, libel, defamation, and invasion of privacy.',
  product_and_completed_ops:
    'Provides coverage for injury or damage inflicted by the products or services you sell.',
  reputational_harm:
    'Helps cover PR costs for negative media coverage due to an errors and omissions claim.',
  wage_hour_sublimit_status:
    'Helps pay for the defense of covered violations against federal Fair Labor Standards Act, which establishes standards for things such as minimum wage and overtime pay.',
  bop_work_from_anywhere:
    'Extends your business personal property coverage to off premise locations that your employees work remotely from.',
  cpp_prop_work_anywhere:
    'Extends your business personal property coverage to off premise locations that your employees work remotely from.',
  cpp_gl_prem_ops:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  cpp_gl_prod_cops:
    'Provides coverage for injury or damage inflicted by the products or services you sell.',
  cpp_gl_damages_prem_rented:
    'Provides coverage for damage done to property temporarily rented to you.',
  cpp_gl_digital_access:
    "Covers legal liabilities if your website or app doesn't meet Americans with Disabilities Act (ADA) or similar state accessibility laws.",
  eo_speciality_insurance:
    'Tailors coverage for exposures like insurance product sales, risk management services, or actuarial advice.',
  eo_speciality_investment:
    'Tailors coverage for exposures like financial planning, investment advice, or portfolio management.',
  eo_speciality_lending:
    'Tailors coverage for exposures like granting, marketing, or servicing loans, leases, or mortgages.',
  eo_speciality_real_estate:
    'Tailors coverage for exposures like brokering real estate or mortgages or property management.',
};

export const SubCoverageLabels: Record<string, string | JSX.Element> = {
  waiting_period_hours: 'hour waiting period',
  condition: 'hour waiting period',
  'co-insurance': 'co-insurance',
  percent: 'deductible',
};
