import { Theme, SxProps } from '@vouch/ui';

export const toggleOpen: SxProps<Theme> = (theme) => ({
  color: theme.designTokens.colorPrimaryDarker,
  position: 'absolute',
  right: '0',
  bottom: '0',
  backgroundColor: theme.designTokens.colorWhite,
  paddingLeft: '0.5rem',
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

export const toggleClosed: SxProps<Theme> = (theme) => ({
  color: theme.designTokens.colorPrimaryDarker,
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});
