import { Typography, useTheme } from '@vouch/ui';
import { FC, useState } from 'react';
import { getSubCoveragePricingText, getSubcoverageOfferClass } from 'features/helpers';
import { Coverage } from 'features/coverages/coverages.types';
import { SubCoverageDescriptions } from 'shared/ui/subcoverageDescriptions';
import { runningListOfUiTokens } from 'shared/ui/tokenNames';

import {
  SubcoverageInfoButton,
  SubcoverageInfoIcon,
  SubcoverageListItem,
  SubcoverageListItemIcon,
  SubcoverageListItemText,
} from './SubcoverageCard.styles';
import './SubcoverageCard.scss';

const CheckIcon = ({ color }: { color: string }) => {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.883884" y1="5.57025" x2="6.54074" y2="11.2271" stroke={color} strokeWidth="2.5" />
      <path d="M5.71582 10.2842L15.0001 1.00003" stroke={color} strokeWidth="2.5" />
    </svg>
  );
};

const XIcon = ({ color }: { color: string }) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 10.28L9.99976 2" stroke={color} strokeWidth="2.6" strokeLinecap="square" />
      <path d="M9.99976 10.28L2 2" stroke={color} strokeWidth="2.6" strokeLinecap="square" />
    </svg>
  );
};

interface SubcoverageProps extends Coverage {
  closeModal?: () => void;
}
const Subcoverage: FC<SubcoverageProps> = (props: SubcoverageProps) => {
  const [infoExpanded, toggleInfoExpanded] = useState(false);
  const theme = useTheme();
  const { accepted, closeModal, exclusion, limits, offered, outOfPockets, uiToken } = props;
  if (!runningListOfUiTokens[uiToken]) return null;
  const limitArray = Object.values(limits);
  const oopArray = Object.values(outOfPockets);
  const limit = limitArray[0];
  const oop = oopArray[0];
  const subCoverageInclusion = getSubcoverageOfferClass({ uiToken, accepted, exclusion, offered });

  const handleRequestBelowClick = () => {
    closeModal && closeModal();
    document.getElementById('note-to-underwriter-field')?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const subCoveragePricingText: string | null =
    subCoverageInclusion === 'included'
      ? getSubCoveragePricingText({
          token: uiToken,
          limit: limit,
          outOfPocket: oop,
        })
      : null;
  const subRequestText: string | JSX.Element | null =
    subCoverageInclusion === 'not-included' ? (
      <Typography variant="subtitle2" fontWeight="normal">
        Need this Coverage?{' '}
        <Typography
          component="span"
          className="request-link"
          sx={{
            color: theme.designTokens.colorPrimaryDarker,
          }}
          tabIndex={0}
          onClick={handleRequestBelowClick}
          onKeyDown={handleRequestBelowClick}
        >
          Request it below.
        </Typography>
      </Typography>
    ) : null;

  const AcceptanceIcon = () => {
    switch (subCoverageInclusion) {
      case 'included':
        return <CheckIcon color={theme.designTokens.colorPrimary} />;
      case 'not-included':
        return <XIcon color={theme.designTokens.colorPrimary} />;
      default:
        return <></>;
    }
  };

  return (
    <SubcoverageListItem
      alignItems="flex-start"
      className={`subcoverage ${subCoverageInclusion}`}
      data-testid="subcoverage-card"
    >
      <SubcoverageListItemIcon sx={{ marginTop: 2.6 }}>
        <AcceptanceIcon />
      </SubcoverageListItemIcon>
      <SubcoverageListItemText
        primary={
          <>
            {runningListOfUiTokens[uiToken]}
            {SubCoverageDescriptions[uiToken] && (
              <SubcoverageInfoButton
                variant="text"
                onClick={() => toggleInfoExpanded((state) => !state)}
              >
                <SubcoverageInfoIcon infoExpanded={infoExpanded} data-testid="expand-info" />
              </SubcoverageInfoButton>
            )}
          </>
        }
        primaryTypographyProps={{
          alignItems: 'center',
          display: 'flex',
          fontWeight: 700,
          variant: 'subtitle2',
        }}
        secondary={
          <>
            {infoExpanded && (
              <Typography
                data-testid="expanded-info"
                fontWeight="normal"
                variant="subtitle2"
                mb={0.5}
                mt={0}
              >
                {SubCoverageDescriptions[uiToken]}
              </Typography>
            )}
            {subCoveragePricingText && (
              <Typography fontWeight="normal" variant="subtitle2" mb={0}>
                {subCoveragePricingText}
              </Typography>
            )}
            {subRequestText && (
              <Typography data-testid="not-offered-text" variant="subtitle2" mb={0}>
                {subRequestText}
              </Typography>
            )}
          </>
        }
      ></SubcoverageListItemText>
    </SubcoverageListItem>
  );
};

export default Subcoverage;
