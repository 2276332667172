import { AppBar, Toolbar as MuiToolbar, Avatar, styled } from '@mui/material';
import { ReactComponent as VouchLogo } from '../../../icons/Vouch-Logo.svg';
import PrimaryButton from '../buttons/PrimaryButton';

const StyledAppbar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.background.default,
  boxShadow: theme.shadows[0],
  borderBottom: `1px solid ${theme.designTokens.colorSecondaryDarker}`,
  left: 0,
}));

export function Toolbar({
  avatar,
  logout,
  login,
}: {
  avatar?: string;
  logout: () => void;
  login: () => void;
}) {
  return (
    <StyledAppbar position="fixed">
      <MuiToolbar sx={{ display: 'flex' }}>
        <VouchLogo />
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          <PrimaryButton
            size="small"
            color="inherit"
            onClick={logout}
            sx={{ textTransform: 'none', marginRight: '2.5rem' }}
          >
            Get Help
          </PrimaryButton>
          {avatar ? <Avatar src={avatar} onClick={logout} /> : null}
          {avatar ? null : (
            <PrimaryButton
              size="small"
              color="inherit"
              sx={{ textTransform: 'none' }}
              onClick={login}
            >
              Login
            </PrimaryButton>
          )}
        </div>
      </MuiToolbar>
    </StyledAppbar>
  );
}
