import { FC, ReactElement } from 'react';
import { Box, Card, CardContent, Typography, useMediaQuery, useTheme } from '@vouch/ui';
import { COVERAGE_CARD_MARGIN_PX, COVERAGE_CARD_WIDTH_PX } from 'Pages/quote/QuotePage';

type QuoteBannerProps = {
  title: ReactElement | string;
  body: string;
  linkText?: string;
  linkAction?: () => void;
};

export const QuoteBanner: FC<QuoteBannerProps> = ({
  title,
  body,
  linkText,
  linkAction,
}: QuoteBannerProps): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Card
        sx={{
          background: theme.designTokens.colorPrimaryLighter,
          maxWidth:
            3 * (COVERAGE_CARD_WIDTH_PX + COVERAGE_CARD_MARGIN_PX) - COVERAGE_CARD_MARGIN_PX,
          paddingLeft: '12px',
        }}
        variant="outlined"
      >
        <CardContent>
          <Box justifyContent="left">
            <Typography fontSize={19} fontWeight={700}>
              {title}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: isMobile ? 'column' : 'row',
              }}
            >
              <Typography
                fontSize={16}
                fontWeight={400}
                maxWidth={linkText && !isMobile ? '75%' : '100%'}
              >
                {body}
              </Typography>
              {linkText && (
                <Typography
                  onClick={linkAction}
                  align={isMobile ? 'left' : 'right'}
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    paddingRight: '20px',
                    paddingTop: isMobile ? '10px' : '0px',
                  }}
                >
                  {linkText}
                </Typography>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
