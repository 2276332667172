import { Theme } from '@mui/material';

const MuiBreadcrumbs = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      li: {
        '&.MuiBreadcrumbs-separator': {
          color: theme.designTokens.colorNeutralDark,
          fontSize: '0.875rem',
          fontWeight: 400,
        },
        a: {
          color: theme.designTokens.colorNeutralDark,
        },
        p: {
          color: theme.designTokens.colorBlack,
        },
        '.MuiTypography-root': {
          fontSize: '0.875rem',
          fontWeight: 400,
        },
      },
    }),
  },
};

export default MuiBreadcrumbs;
