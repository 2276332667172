import LimitCard, { LimitCardProps } from 'components/molecules/cards/LimitCard/LimitCard';
import { coverageGroupAcceptance } from 'features/coverageGroups/coverageGroupsSlice';
import {
  toggleCoverageAcceptance,
  updateLocationLimitOrOutOfPocketAmount,
} from 'features/coverages/coverages.actions';
import { useDispatch, useSelector } from 'react-redux';

type LocationLimitCardProps = {
  coverageToken: string;
  groupToken: string;
  limitId: string;
  locationId: string;
  type: string;
};
const LocationLimitCard = (props: LimitCardProps & LocationLimitCardProps): JSX.Element => {
  const { groupToken, coverageToken, frequency, type, locationId, limitId } = props;
  const dispatch = useDispatch();
  const groupAcceptance = useSelector(coverageGroupAcceptance);
  const handleLocationLimitOrOutOfPocketChange = (amount: number) => {
    if (
      groupAcceptance[groupToken] &&
      !groupAcceptance[groupToken][coverageToken] &&
      toggleCoverageAcceptance
    ) {
      dispatch(toggleCoverageAcceptance({ coverageToken }));
    }
    updateLocationLimitOrOutOfPocketAmount &&
      dispatch(
        updateLocationLimitOrOutOfPocketAmount({
          amount,
          coverageToken,
          frequency,
          limitId,
          locationId,
          type,
        })
      );
  };

  return (
    <div data-testid="location-limit-card">
      <LimitCard
        {...props}
        handleChange={(amount) => handleLocationLimitOrOutOfPocketChange(amount)}
      />
    </div>
  );
};

export default LocationLimitCard;
