import { ReactElement, SVGProps } from 'react';
import { ReactComponent as IconDark } from './variant/icon-dark.svg';
import { ReactComponent as IconLight } from './variant/icon-light.svg';
import { ReactComponent as PrimaryDark } from './variant/primary-dark.svg';
import { ReactComponent as PrimaryLight } from './variant/primary-light.svg';
import { ReactComponent as TaglineDark } from './variant/tagline-dark.svg';
import { ReactComponent as TaglineLight } from './variant/tagline-light.svg';

export type LogoProps = {
  variant:
    | 'icon-dark'
    | 'icon-light'
    | 'primary-dark'
    | 'primary-light'
    | 'tagline-dark'
    | 'tagline-light';
  title?: string;
} & SVGProps<SVGSVGElement>;

const Logo = (props: LogoProps): ReactElement => {
  // Even though it's not used, children is destructured to exclude it from rest. Intake client throws type errors otherwise.
  const { variant, title, children: _children, ...rest } = props;

  switch (variant) {
    case 'icon-dark':
      return (
        <IconDark
          data-testid="logo-icon-dark"
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
          title={title || 'Vouch Logo (dark)'}
          {...rest}
        />
      );
    case 'icon-light':
      return (
        <IconLight
          data-testid="logo-icon-light"
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
          title={title || 'Vouch Logo (light)'}
          {...rest}
        />
      );
    case 'primary-dark':
      return (
        <PrimaryDark
          data-testid="logo-primary-dark"
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
          title={title || 'Vouch Logo (dark)'}
          {...rest}
        />
      );
    case 'primary-light':
      return (
        <PrimaryLight
          data-testid="logo-primary-light"
          style={{ display: 'block' }}
          title={title || 'Vouch Logo (light)'}
          {...rest}
        />
      );
    case 'tagline-dark':
      return (
        <TaglineDark
          data-testid="logo-tagline-dark"
          style={{ display: 'block' }}
          title={title || 'Vouch Logo (dark)'}
          {...rest}
        />
      );
    case 'tagline-light':
      return (
        <TaglineLight
          data-testid="logo-tagline-light"
          style={{ display: 'block' }}
          title={title || 'Vouch Logo (light)'}
          {...rest}
        />
      );
    default:
      return <PrimaryDark style={{ display: 'block' }} />;
  }
};

export default Logo;
