import { Theme } from '@mui/material';

const MuiSwitch = {
  styleOverrides: {
    root: {
      height: '30px',
      width: '48px',
      padding: 0,
      '&:hover': {
        background: 'none',
      },
    },
    switchBase: ({ theme }: { theme: Theme }) => ({
      height: '30px',
      padding: 0,
      transitionDuration: '300ms',
      width: '48px',
      '&.Mui-checked': {
        transform: 'translateX(18px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.designTokens.colorPrimary,
          opacity: 1,
          border: 0,
        },
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    }),
    thumb: ({ theme }: { theme: Theme }) => ({
      boxShadow: 'none',
      color: theme.designTokens.colorWhite,
      width: 26,
      height: 26,
      marginLeft: -18,
      borderColor: theme.designTokens.colorPrimary,
      borderWidth: 2,
    }),
    track: ({ theme }: { theme: Theme }) => ({
      borderRadius: '26px',
      backgroundColor: theme.designTokens.colorNeutralDark,
      opacity: 1,
    }),
  },
};

export default MuiSwitch;
