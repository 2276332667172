import { Box, Card, CardContent, Skeleton } from '@vouch/ui';
import VouchModal from 'components/atoms/display/Modal/Modal';
import { CoveragesSet } from 'features/coverages/coverages.types';
import ExpandedCard from 'containers/ExpandedCardContainer/ExpandedCard';
import { useState } from 'react';
import { runningListOfUiTokens } from 'shared/ui/tokenNames';
import CoverageCardContent from 'components/molecules/cards/CoverageCard/components/CoverageCardContent';
import { CoverageCardProps } from '../CoverageCard';
import {
  coverageCardClicked,
  coverageTabChange,
} from 'features/eventTracking/eventTracking.actions';
import { useDispatch } from 'react-redux';

export const SummaryCard = (props: CoverageCardProps) => {
  const dispatch = useDispatch();
  const {
    coverages,
    group,
    hasActiveDiscretion,
    missingDependencies,
    product,
    toggleCoverage,
    loading = false,
  }: CoverageCardProps = props;
  const { elements } = group; //Product Group Elements
  const [open, toggleExpanded] = useState<boolean>(false);
  const [selectedCoverageIndex, setSelectedCoverageIndex] = useState<number>(0);
  const coverageTokens: (keyof CoveragesSet)[] = Object.values(elements).map((c) => c.uiToken); //Product Group Tokens
  const {
    implicitElements,
    accepted,
    limits,
    offered,
    outOfPockets,
    locationSpecific,
    locationCoverageDetails,
    unsupportedLocations,
  } = coverages[selectedCoverageIndex];

  const handleClick = () => {
    if (!offered) {
      return;
    }

    toggleExpanded(true);

    dispatch(
      coverageCardClicked({
        cardAction: 'Coverage Card Clicked',
        cardTitle: runningListOfUiTokens[group.uiToken],
        groupToken: group.uiToken,
        policy: product.uiToken,
      })
    );
  };

  const handleTabChange = (newValue: number) => {
    dispatch(
      coverageTabChange({
        coverageTabChange: coverageTokens[newValue],
        cardTitle: runningListOfUiTokens[group.uiToken],
        policy: product.uiToken,
      })
    );
    setSelectedCoverageIndex(newValue);
  };

  const skeletonCard = () => {
    return (
      <Card
        data-testid="summary-card-skeleton"
        className="coverage-card"
        sx={(theme) => ({ outline: `1px solid ${theme.designTokens.colorSecondary}` })}
      >
        <CardContent>
          <Skeleton
            variant="rectangular"
            width={'100%'}
            height={50}
            style={{ marginBottom: 80, marginTop: 20 }}
          />
          <Skeleton variant="rectangular" width={'100%'} height={40} style={{ marginBottom: 15 }} />
          <Skeleton variant="rectangular" width={50} height={30} style={{ marginBottom: 10 }} />
        </CardContent>
      </Card>
    );
  };

  return loading ? (
    <>{skeletonCard()}</>
  ) : (
    <Card
      data-testid="summary-card"
      className={`coverage-card
        ${offered ? 'offered' : ''}`}
      sx={(theme) => ({ outline: `1px solid ${theme.designTokens.colorSecondary}` })}
    >
      <CardContent data-testid="summary-card-trigger" onClick={handleClick}>
        <CoverageCardContent
          accepted={accepted}
          cardTitle={runningListOfUiTokens[group.uiToken]}
          groupToken={group.uiToken}
          hasActiveDiscretion={hasActiveDiscretion}
          limits={limits}
          missingDependencies={missingDependencies}
          offered={offered}
          pricing={group.pricing}
          toggleCoverage={toggleCoverage}
        />
      </CardContent>
      <VouchModal modalOpen={open} modalClose={() => toggleExpanded(false)} isLoading={loading}>
        <ExpandedCard
          accepted={accepted}
          coverageTokens={coverageTokens}
          groupToken={group.uiToken}
          implicitElements={implicitElements}
          limits={limits}
          outOfPockets={outOfPockets}
          productToken={product.uiToken}
          selectedCoverageIndex={selectedCoverageIndex}
          sharedLimit={product.sharedLimit}
          tabAction={handleTabChange}
          closeModal={() => toggleExpanded(false)}
          locationSpecific={locationSpecific}
          locationCoverageDetails={locationCoverageDetails || {}}
          unsupportedLocations={unsupportedLocations}
        >
          <Box p={2}>
            <CoverageCardContent
              accepted={accepted}
              cardTitle={runningListOfUiTokens[group.uiToken]}
              groupToken={group.uiToken}
              hasActiveDiscretion={hasActiveDiscretion}
              limits={limits}
              missingDependencies={missingDependencies}
              offered={offered}
              pricing={group.pricing}
              toggleCoverage={toggleCoverage}
            />
          </Box>
        </ExpandedCard>
      </VouchModal>
    </Card>
  );
};

export default SummaryCard;
