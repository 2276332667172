import { ReactElement } from 'react';
import TextButtonBase, { TextButtonProps } from './text-button-base';
import { SaveAlt } from '@vouch/ui';

export const DownloadButton = (props: TextButtonProps): ReactElement => (
  <TextButtonBase
    icon={<SaveAlt />}
    {...props}
    sx={(theme) => ({ color: theme.designTokens.colorBlack })}
  >
    Download quote
  </TextButtonBase>
);
