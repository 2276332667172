import { FC, ReactElement } from 'react';
import 'date-fns';
import { LocalizationProvider, AdapterDateFns } from '@vouch/ui';
import './ExpiresOn.scss';
import NotificationModal from 'components/molecules/modals/NotificationModal/NotificationModal';
import { CurrentStatus, getCurrentStatus } from 'shared/helpers';
import RecreateQuotesContainer from 'containers/RecreateQuotesContainer';
import { DateTime } from 'luxon';
import { differenceInCalendarDays } from 'date-fns';
type ExpiresOnProp = {
  quoteExpirationString: string;
  applicationExpirationString: string;
};

const ExpiresOn: FC<ExpiresOnProp> = (props): ReactElement => {
  const { quoteExpirationString, applicationExpirationString } = props;
  const applicationExpirationDate = new Date(applicationExpirationString);
  const quoteExpirationDate = new Date(quoteExpirationString);
  const toDateWithoutLocalOffset = new Date(
    quoteExpirationDate.valueOf() + quoteExpirationDate.getTimezoneOffset() * 60 * 1000
  );
  const toDateWithoutLocalOffsetNow = new Date(
    new Date().valueOf() + new Date().getTimezoneOffset() * 60 * 1000
  );
  const currentStatus: CurrentStatus = getCurrentStatus(
    applicationExpirationDate,
    toDateWithoutLocalOffset
  );
  let timeTillExpirationDays = differenceInCalendarDays(
    toDateWithoutLocalOffset,
    toDateWithoutLocalOffsetNow
  );
  if (toDateWithoutLocalOffsetNow > toDateWithoutLocalOffset) {
    timeTillExpirationDays = 0;
  }
  const expiredDate = toDateWithoutLocalOffset
    ? DateTime.fromJSDate(toDateWithoutLocalOffset).toFormat('LL/dd/yyyy')
    : '';

  const getTimeTilExpirationDaysMsg = () => {
    if (timeTillExpirationDays <= 0) {
      const pastExpiry = toDateWithoutLocalOffset.toDateString().split(' ');
      return `This quote expired on ${pastExpiry[1]} ${pastExpiry[2]}, ${pastExpiry[3]}`;
    } else {
      if (timeTillExpirationDays === 1) {
        return `This quote expires in ${timeTillExpirationDays} day.`;
      } else {
        return `This quote expires in ${timeTillExpirationDays} days.`;
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="VouchExpiresOn">
        <p className={timeTillExpirationDays <= 5 ? 'red-text' : 'black-text'}>
          {getTimeTilExpirationDaysMsg()}
        </p>
      </div>
      <NotificationModal
        heading="Your Quote Has Expired"
        isOpen={currentStatus === 'quote expired'}
        buttonElem={<RecreateQuotesContainer />}
        text={`Your quote expired on ${expiredDate}. Since we are continually updating our insurance products to provide you the best coverage, you will need to update your previous quote. Please note that this may impact your overall price.`}
        isLoading={false}
      />
    </LocalizationProvider>
  );
};

export default ExpiresOn;
