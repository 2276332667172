import { createSlice } from '@reduxjs/toolkit';
import { State } from 'store';
import { PackagesState, ReducerPackage } from './packages.types';
import { get } from 'lodash';

const initialState: PackagesState = {
  bySlug: {},
  order: [],
  selectedPackage: '',
};

const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    selectPackage(state, action) {
      state.selectedPackage = action.payload.packageSlug;
    },
    loadPackage(state, action) {
      const { packageItem } = action.payload;
      state.bySlug[packageItem.slug] = packageItem;
    },
    clearPackages(state) {
      state.bySlug = {};
      state.order = [];
      state.selectedPackage = '';
    },
    setPackagesOrder(state, action) {
      state.order = action.payload.order;
    },
  },
});

export const packagesSelector = (state: State): PackagesState => state.packages;
export const packageOrder = (state: State): string[] => packagesSelector(state).order;
export const packagesBySlug = (state: State): Record<string, ReducerPackage> =>
  packagesSelector(state).bySlug;
export const selectedPackage = (state: State): string => packagesSelector(state).selectedPackage;
export const getActiveQuoteIds =
  (slugs: string[]) =>
  (state: State): string[] =>
    slugs.map((slug) => get(packagesSelector(state), ['bySlug', slug, 'activeQuoteId'], ''));
export const getOriginalQuoteIds =
  (slugs: string[]) =>
  (state: State): string[] =>
    slugs.map((slug) => get(packagesSelector(state), ['bySlug', slug, 'originalQuoteId'], ''));
export const packagesActions = packagesSlice.actions;
const packagesReducer = packagesSlice.reducer;
export default packagesReducer;
