import { createAsyncThunk } from '@reduxjs/toolkit';
import { PackagesQueryResult } from './packages.types';
import { packagesActions } from './packagesSlice';
import { get } from 'lodash';
import { updateHasAcceptedQuote } from 'features/session/sessionSlice';

export const { clearPackages, loadPackage, selectPackage, setPackagesOrder } = packagesActions;
export const processPackages = createAsyncThunk(
  'packages/processPackages',
  (packages: PackagesQueryResult[], thunkAPI) => {
    const { dispatch } = thunkAPI;
    packages.forEach((p: PackagesQueryResult) => {
      if (p.acceptedQuote?.id) dispatch(updateHasAcceptedQuote());
      dispatch(
        loadPackage({
          packageItem: {
            slug: p.slug,
            activeQuoteId: p.activeQuote.id,
            discretionDecisionId: p.activeQuote.discretionDecisionId,
            pendingDiscretionDecisionId: p.activeQuote.pendingDiscretionDecisionId,
            originalQuoteId: p.originalQuote.id,
            acceptedQuoteId: get(p.acceptedQuote, 'id'),
          },
        })
      );
    });
    dispatch(setPackagesOrder({ order: packages.map((p) => p.slug) }));
  }
);
