import { useDispatch, useSelector } from 'react-redux';
import { packagesBySlug, selectedPackage } from 'features/packages/packagesSlice';
import { sendToDiscretionPage, sessionApplicationId } from 'features/session/sessionSlice';
import { useEffect } from 'react';
import { useRequestDiscretionMutation } from 'generated/graphql';
import DiscretionForm from 'Pages/quote/discretionForm';
import { discretionRequested } from 'features/eventTracking/eventTracking.actions';
import { catchError, LEVEL_CRITICAL } from 'features/errors/errors.actions';

const DiscretionFormContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const packageSlug = useSelector(selectedPackage);
  const packageSlugs = useSelector(packagesBySlug);
  const applicationId = useSelector(sessionApplicationId);

  useEffect(() => {
    const selectedPackageSlug = packageSlugs[packageSlug];
    const hasDiscretionId = !!selectedPackageSlug?.pendingDiscretionDecisionId;

    if (hasDiscretionId) {
      // Can this effect be removed?
      dispatch(
        sendToDiscretionPage({
          pendingDiscretionDecisionId: selectedPackageSlug.pendingDiscretionDecisionId,
        })
      );
    }
  }, [packageSlugs, packageSlug, dispatch]);

  const [requestDiscretionMutation, { loading, error }] = useRequestDiscretionMutation({
    onCompleted: (data) => {
      const packages = data.requestDiscretion ?? [];
      if (packages.length) {
        const [recommendedQuote] = packages;
        const pendingDiscretionDecisionId =
          recommendedQuote.activeQuote.pendingDiscretionDecisionId;
        dispatch(discretionRequested({ pendingDiscretionDecisionId }));
        dispatch(sendToDiscretionPage({ pendingDiscretionDecisionId }));
      }
    },
    onError: (e) => {
      dispatch(
        catchError({
          level: LEVEL_CRITICAL,
          message: `DiscretionFormContainer MutationError: ${e.message}`,
        })
      );
    },
  });

  const requestDiscretion = (comment: string) => {
    requestDiscretionMutation({
      variables: { id: applicationId, comment },
    });
  };

  return <DiscretionForm loading={loading} error={error} onClick={requestDiscretion} />;
};
export default DiscretionFormContainer;
