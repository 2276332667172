const cyberCoverage = {
  'cyber aggregate limit': {
    calculated_in_aggregate: (
      <>
        <p>
          The <span className="emphasized-text">Cyber Aggregate Limit</span> is the maximum amount
          we will pay for covered claims across your entire cyber coverage for the lifespan of the
          coverage. This is reset if your policy is renewed.
        </p>
        <p>
          Note that this coverage is claims-based, which means you must file a claim for something
          that happened during your policy while your policy is still active.
        </p>
      </>
    ),
  },
  'first party cyber limit': {
    in_aggregate: (
      <>
        <p>
          The <span className="emphasized-text">Cyber First-Party Limit</span> is the maximum amount
          we will pay for all covered First-Party Cyber incidents across the lifespan of the
          coverage. This is reset if your policy is renewed.
        </p>
        <p>
          Note that this coverage is claims-based, which means you must file a claim for something
          that happened during your policy while your policy is still active.
        </p>
      </>
    ),
  },
  'first party cyber retention': {
    per_claim: (
      <p>
        <span className="emphasized-text">Retentions</span> are taken out of the amount we pay out
        for a claim. Retentions are similar to deductibles, except deductibles are paid out of
        pocket upfront by the insured, whereas retentions are taken out of each claim payment.
      </p>
    ),
  },
  'third party cyber limit': {
    in_aggregate: (
      <>
        <p>
          The <span className="emphasized-text">Cyber Third-Party Limit</span> is the maximum amount
          we will pay for all covered Third-Party Cyber incidents across the lifespan of the
          coverage. This is reset if your policy is renewed.
        </p>
        <p>
          Note that this coverage is claims-based, which means you must file a claim for something
          that happened during your policy while your policy is still active.
        </p>
      </>
    ),
  },
  'third party cyber retention': {
    per_claim: (
      <p>
        <span className="emphasized-text">Retentions</span> are taken out of the amount we pay out
        for a claim. Retentions are similar to deductibles, except deductibles are paid out of
        pocket upfront by the insured, whereas retentions are taken out of each claim payment.
      </p>
    ),
  },
  retention: {
    per_claim: (
      <p>
        <span className="emphasized-text">Retentions</span> are taken out of the amount we pay out
        for a claim. Retentions are similar to deductibles, except deductibles are paid out of
        pocket upfront by the insured, whereas retentions are taken out of each claim payment.
      </p>
    ),
  },
};
const mplDescriptions = {
  ...cyberCoverage,
  'Policy Shared Limit': {
    shared_in_aggregate: (
      <>
        <p>
          The <span className="emphasized-text">Policy Shared Limit</span> is the maximum we will
          pay for all covered incidents across all coverages within this policy.
        </p>
        <p>
          For example, if you buy D&O and Fiduciary coverages, they are both part of the same
          policy. If you have a policy shared limit of $2M and a D&O limit of $2M, if you were to
          have a $2M D&O claim, you would max out your policy shared limit and any additional
          fiduciary claims would not be covered.
        </p>
      </>
    ),
  },
  limit: {
    in_aggregate: (
      <>
        <p>
          The <span className="emphasized-text">Aggregate Limit</span> is the maximum amount we will
          pay for all covered incidents across the lifespan of the coverage. This is reset if your
          policy is renewed.
        </p>
        <p>
          Note that this coverage is claims-based, which means you must file a claim for something
          that happened during your policy while your policy is still active.
        </p>
      </>
    ),
  },
  retention: {
    per_claim: (
      <p>
        <span className="emphasized-text">Retentions</span> are taken out of the amount we pay out
        for a claim. Retentions are similar to deductibles, except deductibles are paid out of
        pocket upfront by the insured, whereas retentions are taken out of each claim payment.
      </p>
    ),
  },
};

export const limitDescriptions = {
  BOP: {
    limit: {
      in_aggregate: (
        <p>
          The <span className="emphasized-text">Coverage Aggregate Limit</span> is the maximum
          amount we will pay up to for a covered incident, across the lifespan of the policy. This
          is reset if your policy is renewed.
        </p>
      ),
      per_employee: (
        <p>
          The <span className="emphasized-text">Per Employee Limit</span> is the maximum amount we
          will pay for a covered incident per employee until you reach the aggregate limit across
          all incidents in the lifespan of the coverage.
        </p>
      ),
      per_occurrence: (
        <>
          <p>
            The <span className="emphasized-text">Occurrence Limit</span> is the maximum amount we
            will pay each covered incident until you reach the aggregate limit across all incidents
            in the lifespan of the coverage.
          </p>
          <p>
            Note that this coverage is occurrence-based, meaning you can make claims at any point on
            an incident that occurred during the timespan of your policy.
          </p>
        </>
      ),
    },
    deductible: {
      per_employee: (
        <p>
          <span className="emphasized-text">Deductibles</span> are the amount that you will need to
          pay out of pocket first per incident before any insurance coverage is paid out.
        </p>
      ),
      per_occurrence: (
        <p>
          <span className="emphasized-text">Deductibles</span> are the amount that you will need to
          pay out of pocket first per incident before any insurance coverage is paid out.
        </p>
      ),
    },
  },
  MPL: { ...mplDescriptions },
  NY_TPL: { ...mplDescriptions },
  NY_D_AND_O: { ...mplDescriptions },
  CEM: {
    ...cyberCoverage,
    'Policy Shared Limit': {
      shared_in_aggregate: (
        <>
          <p>
            The <span className="emphasized-text">Policy Shared Limit</span> is the maximum we will
            pay for all covered incidents across all coverages within this policy.
          </p>
          <p>
            For example, if you buy E&O and Cyber coverages, they are both part of the same policy.
            If you have a policy shared limit of $2M and a E&O limit of $2M, if you were to have a
            $2M E&O claim, you would max out your policy shared limit and any additional Cyber
            claims would not be covered.
          </p>
        </>
      ),
    },
    limit: {
      in_aggregate: (
        <>
          <p>
            The <span className="emphasized-text">Coverage Aggregate Limit</span> is the maximum
            amount we will pay for all covered incidents across the lifespan of the coverage. This
            is reset if your policy is renewed.
          </p>
          <p>
            Note that this coverage is claims-based, which means you must file a claim for something
            that happened during your policy while your policy is still active.
          </p>
        </>
      ),
    },
  },
  CRIME: {
    'crime aggregate limit': {
      calculated_in_aggregate: (
        <>
          <p>
            The <span className="emphasized-text">Aggregate Limit</span> is the maximum amount we
            will pay for all covered incidents across the lifespan of the policy. This is reset if
            your policy is renewed.
          </p>
        </>
      ),
    },
    'crime limit': {
      per_crime_occurrence: (
        <>
          <p>
            The <span className="emphasized-text">Occurrence Limit</span> is the maximum amount we
            will pay up to for each covered incident until you reach the aggregate limit.
          </p>
          <p>
            Note that this coverage is occurrence-based, meaning you can make claims at any point on
            an incident that occurred during the timespan of your policy.
          </p>
        </>
      ),
    },
    'crime deductible': {
      per_occurrence: (
        <p>
          <span className="emphasized-text">Deductibles</span> are the amount that you will need to
          pay out of pocket first per incident before any insurance coverage is paid out.
        </p>
      ),
    },
  },
};
