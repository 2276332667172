import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCoverages } from 'features/coverages/coveragesSlice';
import { CoveragesSet, CYBER_COVERAGES } from 'features/coverages/coverages.types';
import {
  coverageGroupAcceptance,
  idToUiTokens,
  toggleCoverageGroupAcceptance,
} from 'features/coverageGroups/coverageGroupsSlice';
import {
  toggleCoverageAcceptance,
  updateLimitOrOutOfPocketAmount,
} from 'features/coverages/coverages.actions';
import { selectedPackage } from 'features/packages/packagesSlice';
import { CoverageGroup, Product } from 'features/quotes/quotes.types';
import { getIsLoading } from 'features/quotes/quotesSlice';
import { getHasActiveDiscretion } from 'features/session/sessionSlice';
import { catchError, LEVEL_CRITICAL } from 'features/errors/errors.actions';
import { get, isEmpty } from 'lodash';
import { coverageGroupIsAccepted, meetsDependencyRequirements } from 'Pages/quote/quote-helpers';
import { COVERAGE_ORDER } from 'shared/ui/layoutUtils';
import { isBasic } from 'Pages/package/PackagesConfig';
import CoverageCard from 'components/molecules/cards/CoverageCard/CoverageCard';

export type CoverageCardContainerProps = {
  group: CoverageGroup;
  product: Product;
};

const CoverageCardContainer: FC<CoverageCardContainerProps> = ({
  group,
  product,
}: CoverageCardContainerProps) => {
  const { uiToken: productToken } = product;
  const { allRequiredGroupIds, anyRequiredGroupIds, elements, uiToken: groupToken } = group;

  const isLoading = useSelector(getIsLoading);
  const coverageTokens = Object.keys(elements) as (keyof CoveragesSet)[];
  const displayTokens = COVERAGE_ORDER[groupToken] || coverageTokens;
  const coverageMap = useSelector(getCoverages(displayTokens)).filter((elt) => !!elt)!;
  const packageSlug = useSelector(selectedPackage);
  const groupIdToTokenMap = useSelector(idToUiTokens);
  const groupAcceptance = useSelector(coverageGroupAcceptance);
  const hasActiveDiscretion = useSelector(getHasActiveDiscretion);
  const dispatch = useDispatch();

  const toggleCoverage = useCallback(() => {
    const acceptanceAction = groupAcceptance ? 'Removed from cart' : 'Added to cart';
    dispatch(
      toggleCoverageGroupAcceptance({
        groupToken,
        cardAction: acceptanceAction,
      })
    );
    displayTokens.forEach((coverageToken) => dispatch(toggleCoverageAcceptance({ coverageToken })));
  }, [dispatch, displayTokens, groupAcceptance, groupToken]);

  const changeLimit: (props: {
    amount: number;
    coverageToken: string;
    frequency: string;
    type: string;
  }) => void = (props) => {
    dispatch(updateLimitOrOutOfPocketAmount(props));
  };

  let missingDependencies: string | JSX.Element = '';
  if (!isEmpty(groupAcceptance)) {
    missingDependencies = meetsDependencyRequirements(
      anyRequiredGroupIds,
      allRequiredGroupIds,
      groupIdToTokenMap,
      groupAcceptance
    );
  }
  useEffect(() => {
    if (isEmpty(groupAcceptance)) return;
    const accepted = coverageGroupIsAccepted(groupAcceptance[groupToken]);
    if (missingDependencies && accepted) {
      toggleCoverage();
    }
  }, [groupAcceptance, groupToken, missingDependencies, toggleCoverage]);

  /********************************************************************************
   *  CEM QA: If an error is being thrown here, then it's likely not a frontend
   *          logic or behavior bug; the problem is likely because the data
   *          being passed from the backend already breaks CEM rules
   */
  const isCEM = productToken === 'CEM';
  const [cyberFirst, cyberThird] = useSelector(getCoverages(CYBER_COVERAGES));
  useEffect(() => {
    if (!isCEM) return;
    const firstPartyAccepted = get(cyberFirst, 'accepted');
    const thirdPartyAccepted = get(cyberThird, 'accepted');
    const firstPartyAmount = get(cyberFirst, ['limits', 'in_aggregate', 'amount']);
    const thirdPartyAmount = get(cyberThird, ['limits', 'in_aggregate', 'amount']);
    if (!firstPartyAccepted && thirdPartyAccepted) {
      dispatch(
        catchError({
          level: LEVEL_CRITICAL,
          message: 'CEM ERROR: Third party cyber is accepted but first party is not.',
        })
      );
    }
    if (firstPartyAmount > thirdPartyAmount) {
      dispatch(
        catchError({
          level: LEVEL_CRITICAL,
          message: 'CEM ERROR: First party cyber limit is greater than third party cyber limit',
          extraContext: {
            cyberFirst,
            cyberThird,
          },
        })
      );
    }
  }, [cyberFirst, cyberThird, dispatch, isCEM]);
  //*****************************************************************up**************

  if (!coverageMap.length) return null;
  return (
    <CoverageCard
      variant={isBasic[packageSlug] ? 'full' : 'default'}
      group={group}
      coverages={coverageMap}
      hasActiveDiscretion={hasActiveDiscretion}
      limitChangeAction={changeLimit}
      loading={isLoading}
      product={product}
      toggleCoverage={toggleCoverage}
      missingDependencies={missingDependencies}
      packageSlug={packageSlug}
    />
  );
};

export default CoverageCardContainer;
