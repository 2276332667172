import { Theme } from '@mui/material';

const MuiSelect = {
  defaultProps: {
    variant: 'filled',
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      select: {
        '&:active, &:focus': {
          borderRadius: '4px 4px 0 0',
          backgroundColor: theme.designTokens.colorSecondaryLighter,
        },
        '&:hover': {
          backgroundColor: theme.designTokens.colorSecondaryDarker,
        },
        '&.Mui-disabled:hover': {
          backgroundColor: theme.designTokens.colorNeutralLight,
        },
        '.multi-select-chip': {
          backgroundColor: theme.designTokens.colorSecondary,
          cursor: 'pointer',
        },
      },
    }),
    icon: { width: 24, height: 24 },
  },
};

export default MuiSelect;
