import {
  DiscountOrAdjustmentData,
  Fee,
  Limit,
  Maybe,
  OutOfPocket,
  PricingData,
  LocationCoverage,
  Tax,
} from 'generated/graphql';
import {
  ProductPricingFrequencyPartial,
  QuotePricingFrequencyPartial,
} from './quotes/quotes.types';
import {
  ALL_COVERAGES,
  ConvertedLocationCoverage,
  Coverage,
  CoveragesState,
  LimitsOrOutOfPockets,
  locationData,
} from './coverages/coverages.types';
import { formatTruncatedCents } from 'shared/helpers';
import { subCoverageFrequency } from 'shared/ui/tokenNames';
import { isEmpty, isEqual } from 'lodash';
import { SubCoverageLabels } from 'shared/ui/subcoverageDescriptions';

const convertDiscountOrAdjustmentData = (data: DiscountOrAdjustmentData[]) => {
  const convertedData: Record<string, DiscountOrAdjustmentData> = {};
  data.forEach((item) => {
    const { uiToken } = item;
    convertedData[uiToken] = item;
  });
  return convertedData;
};

export const sumDiscountOrAdjustment = (data: DiscountOrAdjustmentData[]): number => {
  return data.reduce((previousResult: number, currentItem: DiscountOrAdjustmentData) => {
    if (currentItem.active) {
      return previousResult + currentItem.premiumCents;
    }
    return previousResult;
  }, 0);
};

export const sumTaxesOrFees = (data: Maybe<Array<Tax | Fee>> | undefined): number => {
  if (!data) return 0;
  return data.reduce(
    (previousResult: number, currentItem: Tax | Fee) => previousResult + currentItem.amountCents,
    0
  );
};

export const sumPartnerDiscounts = (data: DiscountOrAdjustmentData[]): number => {
  if (!data) return 0;
  return data.reduce(
    (previousResult: number, currentItem: DiscountOrAdjustmentData) =>
      currentItem.uiToken === 'partnership_discount' && currentItem.active && currentItem.eligible
        ? previousResult + currentItem.premiumCents
        : previousResult,
    0
  );
};

export const convertQuotePricing = (
  pricing: PricingData[]
): Record<string, QuotePricingFrequencyPartial> => {
  const convertedObject: Record<string, QuotePricingFrequencyPartial> = {};
  pricing.forEach((data) => {
    const { interval } = data.billingInterval;
    convertedObject[interval] = {
      ...data,
      taxes: sumTaxesOrFees(data.taxes),
      fees: sumTaxesOrFees(data.fees),
      adjustments: sumDiscountOrAdjustment(data.adjustments),
      discounts: sumDiscountOrAdjustment(data.discounts),
      partnerDiscount: sumPartnerDiscounts(data.discounts),
    };
  });
  return convertedObject;
};

export const convertProductPricing = (
  pricing: PricingData[]
): Record<string, ProductPricingFrequencyPartial> => {
  const convertedObject: Record<string, ProductPricingFrequencyPartial> = {};
  pricing.forEach((data) => {
    const { adjustments, discounts } = data;
    const { interval } = data.billingInterval;
    convertedObject[interval] = {
      ...data,
      adjustments: convertDiscountOrAdjustmentData(adjustments),
      discounts: convertDiscountOrAdjustmentData(discounts),
    };
  });
  return convertedObject;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const priceConverterFor: Record<string, any> = {
  products: convertProductPricing,
  coverageGroups: convertProductPricing,
  elements: null,
  implicitElements: convertProductPricing,
};

export const convertPricing = (dataLevel: string, pricing: PricingData[]): PricingData[] => {
  const converter = priceConverterFor[dataLevel];
  if (converter) {
    return converter(pricing);
  }
  return pricing;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertLimits = (limits: Limit[]): Record<string, any> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const convertedObj: Record<string, any> = {};
  limits.forEach((data) => {
    const { available, frequency, id } = data;
    const availableValues = (available && available.map((item) => item.amount)) || [];
    convertedObj[frequency || id] = { ...data, available: toCents(availableValues) };
  });
  return convertedObj;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertOutOfPockets = (outOfPockets: OutOfPocket[]): any => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const convertedObj: Record<string, any> = {};
  outOfPockets.forEach((data) => {
    const { frequency, available, id } = data;
    const centsAvailable = toCents(available || []);
    convertedObj[frequency || id] = { ...data, available: centsAvailable };
  });
  return convertedObj;
};

export const convertLocationCoverageDetails = (
  locationCoverageDetails: LocationCoverage[]
): Record<string, LocationCoverage> => {
  const convertedObj: Record<string, LocationCoverage> = Object.fromEntries(
    locationCoverageDetails.map((location) => [
      location.location.id,
      {
        ...location,
        limits: Object.fromEntries(
          Object.values(location.limits).map((limit) => {
            const { available } = limit;
            const availableValues = (available && available.map((item) => item.amount)) || [];
            const convertedLimit = { ...limit, available: toCents(availableValues) };
            return [limit.frequency, convertedLimit];
          })
        ),
        outOfPockets: Object.fromEntries(
          Object.values(location.outOfPockets).map((outOfPocket) => {
            const { available } = outOfPocket;
            const availableValues = (available && available.map((amount) => amount)) || [];
            const convertedoutOfPocket = { ...outOfPocket, available: toCents(availableValues) };
            return [outOfPocket.frequency, convertedoutOfPocket];
          })
        ),
      },
    ])
  );
  return convertedObj;
};

// TODO: get to consensus with admin-ui and gateway to have all price values
//  passed in either cents or dollars but not both
export const toCents = (dollarValues: number[]): number[] => dollarValues.map((d) => d * 100);

const shouldAddImplicitCoverage = (isImplicit: boolean | undefined, coverage: Coverage) => {
  return isImplicit && coverage.offered;
};

export const getAcceptedItems = (
  coverages: (Coverage | undefined)[],
  topLevelCoverage = true
): {
  requestedCoverages: string[];
  requestedLimits: { id: string; amount: number }[];
  requoteRequest: {
    coverage_id: string;
    limits: Record<string, number>;
    locations: Record<string, Record<string, number>>;
  }[];
} => {
  const requestedCoverages: string[] = [];
  const requestedLimits: { id: string; amount: number }[] = [];
  const requoteRequest: {
    coverage_id: string;
    limits: Record<string, number>;
    locations: Record<string, Record<string, number>>;
  }[] = [];

  coverages.forEach((coverage) => {
    if (coverage && (coverage.accepted || shouldAddImplicitCoverage(!topLevelCoverage, coverage))) {
      requestedCoverages.push(coverage.id);
      requestedLimits.push(...getRequestedAmounts(Object.values(coverage.limits)));
      requoteRequest.push({
        coverage_id: coverage.id,
        limits: limitsForLocationRequest(coverage),
        locations: getRequestedLocations(coverage),
      });
      if (topLevelCoverage) {
        const {
          requestedCoverages: requestedImplicitCoverages,
          requoteRequest: requoteImplicitCoverageRequest,
          /* requestedLimits: requestedImplicitLimits */
        } = getAcceptedItems(
          [...Object.values(coverage.implicitElements), ...getLocationExplicitElements(coverage)],
          false
        );
        requestedCoverages.push(...requestedImplicitCoverages);
        requoteRequest.push(...requoteImplicitCoverageRequest);
        // Currently users can only change subcoverage limits via discretion
        // requestedLimits.push(...requestedImplicitLimits);
      }
    }
  });
  return { requestedCoverages, requestedLimits, requoteRequest };
};

//TODO: fix this data type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRequestedAmounts = (data: (LimitsOrOutOfPockets | OutOfPocket)[]): any[] =>
  data
    .filter((item) => item.frequency !== 'calculated_in_aggregate' && item.customerEditable)
    .map((item) => ({ id: item.id, amount: item.amount }));

export const getLocationExplicitElements = (coverage: Coverage): Coverage[] => {
  if (coverage.locationCoverageDetails)
    return Object.values(coverage.locationCoverageDetails)
      .map((c) => (c.accepted ? Object.values(c.implicitElements) : []))
      .flat();

  return [];
};

export const getRequestedLocations = (
  data: Coverage | ConvertedLocationCoverage
): Record<string, locationData> => {
  if (data.locationCoverageDetails)
    return Object.fromEntries(
      Object.entries(data.locationCoverageDetails)
        .filter(([_id, loc]) => loc.accepted)
        .map(([id, locationCoverage]) => [id, limitsForLocationRequest(locationCoverage)])
    );
  else if ((data as ConvertedLocationCoverage)?.location)
    return {
      [(data as ConvertedLocationCoverage).location.id]: limitsForLocationRequest(data),
    };
  return {};
};

const limitsForLocationRequest = (
  data: Coverage | ConvertedLocationCoverage
): Record<string, number> => {
  return data.locationCoverageDetails
    ? {}
    : Object.fromEntries(Object.values(data.limits).map((limit) => [limit.id, limit.amount]));
};

export const getSubCoveragePricingText = ({
  token,
  limit,
  outOfPocket,
}: {
  token: string;
  limit: LimitsOrOutOfPockets | null;
  outOfPocket: LimitsOrOutOfPockets | null;
}): string => {
  const excludedSubCoverages = {
    eo_speciality_insurance: 1,
    eo_speciality_investment: 1,
    eo_speciality_lending: 1,
    eo_speciality_real_estate: 1,
    mpl_exclusion_major_shareholder: 1,
    cem_eo_ai_endorsement: 1,
  };
  if (excludedSubCoverages[token]) return '';

  const hasLimit = limit && Object.keys(limit).length > 0 && limit.amount;
  const hasOutOfPocket = outOfPocket && Object.keys(outOfPocket).length > 0 && outOfPocket.amount;
  if (!hasLimit && !hasOutOfPocket) return '(Limits calculated if requested)';

  const limitText = hasLimit ? `${formatTruncatedCents(limit!.amount)} Limit` : '';
  const connector = hasLimit && hasOutOfPocket ? ', ' : '';
  const oopText = hasOutOfPocket ? getSubcoverageOopText(outOfPocket!) : '';
  const oopFrequency =
    (hasOutOfPocket &&
      outOfPocket!.frequency &&
      ' ' + subCoverageFrequency[outOfPocket!.frequency]) ||
    '';

  return limitText + connector + oopText + oopFrequency;
};

export const getSubcoverageOopText: (outOfPocket: OutOfPocket) => string = (outOfPocket) => {
  switch (outOfPocket.label) {
    case 'waiting_period_hours':
      return `${outOfPocket.amount} ${SubCoverageLabels[outOfPocket.label]}`;
    case 'co-insurance':
      return `${outOfPocket.amount! * 100}% ${SubCoverageLabels[outOfPocket.label]}`;
    case 'percent':
      return `${outOfPocket.amount}% ${SubCoverageLabels[outOfPocket.label]}`;
    case 'condition':
      return `${outOfPocket.amount} ${SubCoverageLabels[outOfPocket.label]}`;
    default:
      return `${formatTruncatedCents(outOfPocket.amount)} ${outOfPocket.label}`;
  }
};

export type getLimitsProps = {
  limits: Record<string, LimitsOrOutOfPockets>;
  outOfPockets: Record<string, LimitsOrOutOfPockets>;
  sharedLimit?: LimitsOrOutOfPockets;
};
export const getLimitCardData: (
  props: getLimitsProps
) => Array<LimitsOrOutOfPockets & { key?: string }> = ({ limits, outOfPockets, sharedLimit }) => {
  const limitOopCount = Object.keys(limits).length + Object.keys(outOfPockets).length;
  const limitOopCardList: Array<LimitsOrOutOfPockets & { key: string }> = [];

  // If there is a policy shared limit
  if (limitOopCount < 3 && sharedLimit && !isEmpty(sharedLimit))
    limitOopCardList.push({ ...sharedLimit, key: 'limits' });
  // If there's a group shared limit
  if (limits.calculated_in_aggregate)
    limitOopCardList.push({ ...limits.calculated_in_aggregate, key: 'limits' });
  // If there's coverage shared limit
  if (limits.in_aggregate) limitOopCardList.push({ ...limits.in_aggregate, key: 'limits' });
  // All other limits
  Object.values(limits)
    .filter(
      (limit) =>
        !(limit.frequency === 'calculated_in_aggregate' || limit.frequency === 'in_aggregate')
    )
    .forEach((limit) => limit && limitOopCardList.push({ ...limit, key: 'limits' }));
  // All out of pockets
  Object.values(outOfPockets).map(
    (oop) => oop && limitOopCardList.push({ ...oop, key: 'outOfPockets' })
  );
  return limitOopCardList;
};

export const hasAcceptedCoverages = (state: CoveragesState): boolean => {
  return ALL_COVERAGES.some((coverageKey) => state[coverageKey]?.accepted);
};

export const getSubcoverageOfferClass = ({
  uiToken,
  accepted,
  exclusion,
  offered,
}: {
  uiToken: string;
  accepted: boolean | undefined;
  exclusion: boolean | undefined;
  offered: boolean | undefined;
}): string => {
  const specialInclusionRules = {
    bop_work_from_anywhere: accepted ? 'included' : 'not-included',
    media_liability: accepted ? 'included' : 'not-included',
  };

  if (specialInclusionRules[uiToken]) return specialInclusionRules[uiToken];

  switch ([offered, exclusion].toString()) {
    case 'true,false':
    case 'false,true':
      return 'included';
    case 'true,true':
    case 'false,false':
      return 'not-included';
    default:
      return '';
  }
};

export const getLocationData = (location: ConvertedLocationCoverage): locationData => {
  return Object.fromEntries(
    Object.values(location.limits).map((limit) => [limit.id, limit.amount])
  );
};

export const locationHasChanged = (
  originalLocation: Record<string, number>,
  location: Record<string, number>
) => {
  return isEqual(originalLocation, location);
};

export const keyByUIToken = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: Record<string, any>,
  path: string[] = ['products', 'coverageGroups', 'elements', 'implicitElements']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> => {
  const dataLevel: string | undefined = path.shift();
  if (dataLevel !== undefined) {
    // clone object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const convertedObj: Record<string, any> = { ...item };
    // reassign property from array to object type
    convertedObj[dataLevel] = {};

    // get property array items
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nextItemSet: Record<string, any>[] = item[dataLevel];

    // for each item, save the converted child object by uiToken
    nextItemSet.forEach((nextItem) => {
      const { uiToken } = nextItem;
      let convertedNextItem = keyByUIToken(nextItem, path.slice());
      const { pricing, limits, outOfPockets, locationCoverageDetails } = convertedNextItem;
      convertedNextItem = {
        ...convertedNextItem,
        pricing: pricing && convertPricing(dataLevel, pricing),
        limits: limits && convertLimits(limits),
        locationCoverageDetails:
          locationCoverageDetails && convertLocationCoverageDetails(locationCoverageDetails),
        outOfPockets: outOfPockets && convertOutOfPockets(outOfPockets),
      };
      convertedObj[dataLevel][uiToken] = convertedNextItem;
    });
    return convertedObj;
  }
  return item;
};
