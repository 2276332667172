import { FC, MouseEventHandler, ReactElement } from 'react';
import { Cancel, IconButton } from '@vouch/ui';

export type CloseProps = {
  className?: string;
  'data-testid'?: string;
  icon?: ReactElement;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const CloseButton: FC<CloseProps> = (props): ReactElement => (
  <IconButton className="close-button" size="small" {...props}>
    {props.icon || <Cancel className="close-icon" />}
  </IconButton>
);
