import { PACKAGES_PATH, PRINT_QUOTE_PATH, QUOTE_PATH } from 'shared/services/router';
import { selectPackage } from 'features/packages/packages.actions';
import { Dispatch } from 'react';
import { NavigateFunction, useSearchParams } from 'react-router-dom';

type SetURLSearchParams = ReturnType<typeof useSearchParams>[1];

type handleRoutingType = {
  currentQuoteId: string;
  dispatch: Dispatch<{ payload: unknown; type: string }>;
  navigate: NavigateFunction;
  setSearchParams: SetURLSearchParams;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any;
  location: URL;
  packagesOrder: string[];
  paramSlug: string | null;
  pathname: string;
  statePackageSlug: string;
};

export const handleRouting = ({
  currentQuoteId,
  dispatch,
  navigate,
  setSearchParams,
  state,
  location,
  packagesOrder,
  paramSlug,
  pathname,
  statePackageSlug,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
handleRoutingType): any => {
  switch (pathname) {
    case QUOTE_PATH:
    case PRINT_QUOTE_PATH:
      if (statePackageSlug) {
        /**
         * User selects a package
         */
        location.searchParams.set('packageSlug', statePackageSlug);
        setSearchParams(location.search);
      } else if (paramSlug && packagesOrder.length && !packagesOrder.includes(paramSlug)) {
        /**
         * Initializing the app on quote route WITH an INVALID packageSlug in the URL
         */
        location.searchParams.delete('packageSlug');
        navigate(PACKAGES_PATH + location.search, { replace: true });
      } else if (paramSlug && currentQuoteId) {
        /**
         * Initializing the app on quote route WITH a VALID packageSlug in the URL
         */
        dispatch(selectPackage({ packageSlug: paramSlug }));
      } else if (!paramSlug) {
        /**
         * Initializing the app on quote route WITHOUT a packageSlug in the URL
         */
        navigate(PACKAGES_PATH + window.location.search, { replace: true });
      }
      break;
    case PACKAGES_PATH:
    default:
      if (state?.backToPackages) {
        /**
         * User uses "Back to Packages" button
         */
        location.searchParams.delete('packageSlug');
        setSearchParams(location.search);
      } else if (paramSlug) {
        /**
         * Initializing the app on packages routes WITH a packageSlug in the URL
         */
        navigate(QUOTE_PATH + location.search, { replace: true });
      }
      break;
  }
};
