import { Theme } from '@mui/material';
import Colors from '../colors.module.scss';

const MuiButton = {
  defaultProps: {
    disableElevation: true,
    variant: 'contained',
    disableRipple: true,
  },
  variants: [
    {
      props: { variant: 'menu' },
    },
    {
      props: { variant: 'tertiary' },
    },
  ],
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      borderRadius: theme.designTokens.surfaceCornerRadius,
      '&:disabled': {
        background: theme.designTokens.colorNeutralLight,
        color: theme.designTokens.colorNeutralDark,
      },
      '&:focus-visible': {
        boxShadow: `0 0 5px 2px ${theme.designTokens.colorPrimary}`,
      },
      '&.MuiButton-sizeLarge': {
        padding: '15px 20px',
      },
      '&.MuiButton-sizeMedium': {
        padding: '11px 20px',
      },
      '&.MuiButton-outlinedSecondary, &.MuiButton-containedSecondary': {
        background: Colors.transparent,
        color: theme.designTokens.colorBlack,
        '&.active-button:active': {
          background: theme.designTokens.colorPrimaryLightest,
        },
        '&:disabled': {
          backgroundColor: theme.designTokens.colorNeutralLight,
          borderColor: theme.designTokens.colorNeutralDark,
          color: theme.designTokens.colorNeutralDark,
        },
        '&:focus-visible': {
          outline: '2px solid white',
        },
        '&:hover': {
          background: theme.designTokens.colorPrimaryLightest,
          borderColor: theme.designTokens.colorBlack,
        },
        '&.error': {
          color: theme.designTokens.colorError,
        },
      },
    }),
    containedPrimary: ({ theme }: { theme: Theme }) => ({
      '& + .MuiAvatar-root': {
        marginLeft: '10px',
      },
      '&.active-button:active': {
        background: theme.designTokens.colorPrimaryDarker,
      },
      '&:focus-visible': {
        outline: '2px solid white',
      },
      '&:hover': {
        background: theme.designTokens.colorPrimaryLighter,
      },
      '&.MuiLoadingButton-root': {
        background: theme.designTokens.colorPrimary,
      },
    }),
    outlined: ({ theme }: { theme: Theme }) => ({
      border: `1px solid ${theme.designTokens.colorBlack}`,
      '&.MuiButton-sizeLarge': {
        padding: '14px 19px',
      },
      '&.MuiButton-sizeMedium': {
        padding: '10px 19px',
      },
    }),
    endIcon: {
      marginLeft: 8,
      '& .MuiSvgIcon-root': {
        height: 18,
        width: 18,
      },
    },
    menu: {
      fontWeight: 400,
      lineHeight: 1.5,
      padding: 0,
      paddingTop: 10,
      paddingBottom: 10,
      textTransform: 'none',
      '&.MuiButton-sizeMedium': {
        padding: 0,
        paddingTop: 10,
        paddingBottom: 10,
      },
      '&:hover': {
        background: 'none',
      },
    },
    startIcon: {
      marginRight: 8,
      '& + span': {
        marginLeft: 8,
      },
      '& .MuiSvgIcon-root': {
        height: 18,
        width: 18,
      },
    },
    tertiary: ({ theme }: { theme: Theme }) => ({
      background: Colors.transparent,
      color: theme.designTokens.colorBlack,
      outline: 'none',
      outlineOffset: '-1px',
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: 0,
      '& + .MuiAvatar-root': {
        marginLeft: '10px',
      },
      '&& .MuiTouchRipple-child': {
        background: theme.designTokens.colorPrimaryLightest,
      },
      '&:disabled': {
        background: theme.designTokens.colorNeutralLight,
        borderColor: theme.designTokens.colorNeutralDark,
        color: theme.designTokens.colorNeutralDark,
      },
      '&:focus': {
        boxShadow: 'none',
      },
      '&:hover': {
        background: theme.designTokens.colorPrimaryLightest,
        borderColor: theme.designTokens.colorBlack,
      },
    }),
  },
};

export default MuiButton;
