import { GlobalStyles, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ReactNode } from 'react';
import { experienceTheme } from './theme';
import { useExperience } from '../experience-provider/ExperienceProvider';

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const experience = useExperience();
  const theme = experienceTheme(experience);

  const osanoStyleOverrides = {
    '.osano-cm-button:hover': {
      backgroundColor: theme.designTokens.colorPrimaryLighter,
    },
    '.osano-cm-button:focus': {
      backgroundColor: theme.designTokens.colorPrimaryLighter,
    },
    '.osano-cm-widget__dot': {
      fill: theme.designTokens.colorPrimary,
    },
    '.osano-cm-button': {
      color: theme.designTokens.colorPrimary,
      borderColor: theme.designTokens.colorPrimaryDarker,
      backgroundColor: theme.designTokens.colorPrimaryLightest,
    },
    '.osano-cm-button:active': {
      backgroundColor: theme.designTokens.colorPrimaryDarker,
      color: theme.designTokens.colorPrimaryLightest,
    },
    '.osano-cm-info-dialog-header, .osano-cm-info-dialog__info, .osano-cm-info-dialog-header__close, .osano-cm-dialog':
      {
        background: theme.designTokens.colorPrimaryLightest,
        color: theme.designTokens.colorPrimary,
      },
    '.osano-cm-info-dialog-header__close, .osano-cm-info-dialog-header__close:hover': {
      stroke: theme.designTokens.colorPrimaryDarker,
    },
    '.osano-cm-info-dialog-header__close:focus, .osano-cm-dialog__close, .osano-cm-dialog__close:hover, .osano-cm-dialog__close:focus':
      {
        backgroundColor: theme.designTokens.colorPrimary,
        borderColor: theme.designTokens.colorPrimary,
        stroke: theme.designTokens.colorWhite,
        color: theme.designTokens.colorPrimary,
      },
    '.osano-cm-header': {
      background: 'transparent',
    },
    '.osano-cm-toggle, .osano-cm-link': {
      color: theme.designTokens.colorPrimary,
    },
    '.osano-cm-link:hover, .osano-cm-disclosure__toggle:hover': {
      color: theme.designTokens.colorSecondary,
    },
    '.osano-cm-toggle__input:disabled:checked + .osano-cm-toggle__switch, .osano-cm-toggle__input:disabled:checked:hover + .osano-cm-toggle__switch':
      {
        backgroundColor: theme.designTokens.colorSecondary,
      },
    '.osano-cm-toggle__input:disabled:checked + .osano-cm-toggle__switch::after, .osano-cm-toggle__input:disabled:checked:hover + .osano-cm-toggle__switch::after':
      {
        backgroundColor: theme.designTokens.colorPrimaryDarker,
      },
    '.osano-cm-toggle__input:checked + .osano-cm-toggle__switch::before': {
      borderColor: theme.designTokens.colorPrimaryDarker,
    },
    '.osano-cm-toggle__input:checked + .osano-cm-toggle__switch, .osano-cm-toggle__switch': {
      backgroundColor: theme.designTokens.colorSecondary,
    },
  };

  return (
    <MuiThemeProvider theme={theme}>
      <GlobalStyles styles={experience.experience !== 'vouch' ? { ...osanoStyleOverrides } : {}} />
      <>{children}</>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
