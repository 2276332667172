import breakpoints from '../breakpoints';

const MuiDialogTitle = {
  styleOverrides: {
    root: {
      margin: '0px 75px 20px',
      padding: 0,
      textAlign: 'center',
      [breakpoints.down('tablet')]: {
        marginLeft: 40,
        marginRight: 40,
      },
    },
  },
};

export default MuiDialogTitle;
