import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import QuotePage from './Pages/quote/QuotePage';
import PackagesPage from './Pages/package/PackagesPage';
import Header from './containers/HeaderContainer';
import AppContext from './features/AppContext';
import PrintableQuotePage from './Pages/quote/PrintableQuotePage';
import {
  BLOCKED_PATH,
  PACKAGES_PATH,
  PRINT_QUOTE_PATH,
  QUOTE_PATH,
} from './shared/services/router';
import BlockedPage from 'Pages/blocked/BlockedPage';
import { Navigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import Authenticator from './features/Authenticator';
import { Provider as ReduxProvider } from 'react-redux';
import store from './store';
import {
  ExperienceProvider,
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
  makeStyles,
} from '@vouch/ui';
import theme from '@vouch/ui/src/components/theme/theme';
import { auth0ClientId, auth0Domain, gatewayDomain, loginPortalUrl } from './environment';
import UserInfoProvider from 'shared/services/user-info/user-info-context';

const useStyles = makeStyles(() => {
  return {
    root: {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '140%',
      color: theme.designTokens.colorBlack,
      backgroundColor: theme.designTokens.colorWhite,
    },
  };
});

const ProviderOutlet = () => (
  <ReduxProvider store={store}>
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      redirectUri={window.location.origin}
      scope="openid profile email"
    >
      <ExperienceProvider>
        <ThemeProvider>
          <Authenticator gatewayDomain={gatewayDomain} loginPortalUrl={loginPortalUrl}>
            <UserInfoProvider>
              <StyledEngineProvider injectFirst>
                <CssBaseline />
                <Outlet />
              </StyledEngineProvider>
            </UserInfoProvider>
          </Authenticator>
        </ThemeProvider>
      </ExperienceProvider>
    </Auth0Provider>
  </ReduxProvider>
);

function App(): JSX.Element {
  const classes = useStyles();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProviderOutlet />}>
          <Route
            element={
              <div className={classes.root}>
                <AppContext />
              </div>
            }
          >
            <Route element={<Header />}>
              <Route path={PACKAGES_PATH} element={<PackagesPage />} />
              <Route path={QUOTE_PATH} element={<QuotePage />} />
              <Route path={PRINT_QUOTE_PATH} element={<PrintableQuotePage />} />
              <Route path={BLOCKED_PATH} element={<BlockedPage />} />
              <Route
                path={'*'}
                element={<Navigate to={PACKAGES_PATH + window.location.search} replace />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
