import { useCallback, useEffect, useState } from 'react';
import { bootZendesk } from '.';
import { isDuringBusinessHours } from '../shared/timeHelpers';

export const ZD_CHAT_MANUAL_OPENED = 'zd_chat_manual_opened';

type ProactiveChatProps = {
  forceOpen?: boolean;
  tracking: (path: string) => void;
  enabled: boolean;
  t?: number;
};

type ChatProps = {
  flags?: Record<string, boolean>;
  cb?: () => void;
  tracking: (path: string, openViaClick: boolean) => void;
  zendeskJwt?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme?: any;
};

export const useProactiveChat = ({
  enabled,
  tracking,
  forceOpen = false,
  t = 30_000,
}: ProactiveChatProps) => {
  const [userEvent, setUserEvent] = useState('');

  const userEvents = ['keypress', 'click'];

  const handleUserEvent: EventListener = useCallback((event: Event) => {
    const { type } = event;
    setUserEvent((prevUserText) => `${prevUserText}${type}`);
  }, []);

  useEffect(() => {
    if (!enabled) return;
    if (!isDuringBusinessHours()) return;

    userEvents.forEach((event) => {
      window.addEventListener(event, handleUserEvent);
    });

    const timeout = setTimeout(() => {
      if (!!localStorage.getItem(ZD_CHAT_MANUAL_OPENED) && !forceOpen) return;

      if (typeof window.zE === 'function') {
        window.zE('messenger', 'open');
        tracking(window.location.href.split('?')[0]);
      }
    }, t);

    return () => {
      if (timeout) clearTimeout(timeout);

      userEvents.forEach((event) => {
        window.removeEventListener(event, handleUserEvent);
      });
    };
  }, [userEvent, handleUserEvent]);
};

export const useChat = ({ tracking, theme }: ChatProps) => {
  useEffect(() => {
    bootZendesk({
      tracking,
      theme,
    });
  }, []);

  return {
    hide: () => {
      window.zE('messenger', 'close');
    },
    show: () => {
      const path = window.location.href.split('?')[0];
      tracking(path, true);
      localStorage.setItem(ZD_CHAT_MANUAL_OPENED, 'true');

      window.zE('messenger', 'open');
    },
  };
};
