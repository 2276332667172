import { Theme } from '@mui/material';

const MuiInputBase = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      '.MuiIconButton-root': {
        '&:disabled': {
          backgroundColor: 'inherit',
        },
        '&:hover': {
          backgroundColor: theme.designTokens.colorSecondaryLighter,
        },
      },
    }),
    input: {
      fontSize: '1.125rem',
    },
  },
};

export default MuiInputBase;
