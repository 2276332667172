import { FC, ReactElement } from 'react';
import { runningListOfUiTokens } from 'shared/ui/tokenNames';
import CoverageCard from 'containers/CoverageCardContainer';
import { CoverageGroup, Product } from 'features/quotes/quotes.types';
import { COVERAGE_DISPLAY_ORDER, getLayoutFor } from 'shared/ui/layoutUtils';
import EmptyCard from 'components/molecules/cards/CoverageCard/variants/EmptyCard';
import { isEmpty } from 'lodash';
import { providedOnRequest } from 'Pages/quote/quote-helpers';
import { Box, Grid, Typography } from '@vouch/ui';

type ProductSectionProps = {
  products: Record<string, Product>;
  originalProducts: Record<string, Product>;
  market?: string | undefined;
};

const RecommendedProducts: FC<ProductSectionProps> = (props): ReactElement => {
  const { products, originalProducts, market } = props;
  const cards: ReactElement[] = [];

  const cardData: CardData[] = [];
  type CardData = {
    groupToken: string;
    currentGroup: CoverageGroup;
    currentProduct: Product;
    offered: boolean;
    providedOnRequest: boolean;
    accepted: boolean;
    originallyAccepted: boolean;
  };

  getLayoutFor(products).forEach(({ productToken, coverageGroupsLayout }) => {
    const currentProduct = products[productToken];
    const originalProduct = originalProducts[productToken];
    coverageGroupsLayout.map((groupToken) => {
      const currentGroup = currentProduct?.coverageGroups[groupToken];
      const currentGroupOriginal = originalProduct?.coverageGroups[groupToken];

      cardData.push({
        groupToken,
        currentGroup,
        currentProduct,
        offered: currentProduct && !isEmpty(currentProduct.coverageGroups),
        providedOnRequest: providedOnRequest(productToken, market),
        accepted: currentGroup && Object.values(currentGroup.elements)[0].accepted,
        originallyAccepted:
          currentGroupOriginal && Object.values(currentGroupOriginal.elements)[0].accepted,
      });
    });
  });

  // Order: GL, HNOA, EBL, Cyber, E&O, D&O, EPL, Fiduciary, Crime
  // 'originally accepted' takes precedence
  // see 'automatically_accept?' in backend acceptance strategies
  cardData.sort((a: CardData, b: CardData) => {
    if (isEmpty(originalProducts) && a.accepted !== b.accepted) {
      return a.accepted ? -1 : 1;
    }

    if (a.originallyAccepted !== b.originallyAccepted) {
      return a.originallyAccepted ? -1 : 1;
    }
    return (
      COVERAGE_DISPLAY_ORDER.indexOf(a.groupToken) - COVERAGE_DISPLAY_ORDER.indexOf(b.groupToken)
    );
  });

  cardData.forEach((data: CardData) => {
    cards.push(
      data.offered && !data.providedOnRequest ? (
        <Grid item key={data.groupToken}>
          <CoverageCard
            key={data.groupToken}
            group={data.currentGroup}
            product={data.currentProduct}
          />
        </Grid>
      ) : (
        <Grid item key={data.groupToken}>
          <EmptyCard
            key={data.groupToken}
            onRequest={data.providedOnRequest}
            cardTitle={runningListOfUiTokens[data.groupToken]}
          />
        </Grid>
      )
    );
  });

  const originallyAcceptedCount = cardData.filter((data) => data.originallyAccepted).length;
  const acceptedCount = cardData.filter((data) => data.accepted).length;
  const aboveFoldCount = originallyAcceptedCount || acceptedCount;

  return (
    <Box>
      <Grid container>{cards.slice(0, aboveFoldCount)}</Grid>
      {aboveFoldCount < cards.length && (
        <>
          <Grid container>
            <Grid item pt={2} pb={4}>
              <Typography
                variant="h6"
                sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
              >
                Add additional coverages below
              </Typography>
              <Typography variant="body1">
                Expand your protection and unlock up to 10% savings on your total insurance costs.
              </Typography>
            </Grid>
          </Grid>
          <Grid container>{cards.slice(aboveFoldCount)}</Grid>
        </>
      )}
    </Box>
  );
};
export default RecommendedProducts;
