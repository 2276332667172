import { FC, MouseEventHandler, ReactElement } from 'react';
import { Switch } from '@vouch/ui';

export type ToggleProps = {
  checked?: boolean;
  handleClick: MouseEventHandler;
};
export const Toggle: FC<ToggleProps> = (props): ReactElement => {
  const { checked, handleClick } = props;
  return <Switch data-testid="switch" disableRipple checked={checked} onClick={handleClick} />;
};
