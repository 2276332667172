import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, Close, Link, Typography } from '@vouch/ui';
import { getSessionInfo } from 'features/session/sessionSlice';
import { CloseButton } from 'components/atoms/buttons/icon';
import TextBubble from 'components/atoms/icons/TextBubble';
import DiscountTag from 'components/atoms/icons/DiscountTag';

export type EPLLearnMoreCardProps = {
  closeModal?: () => void;
};

const EPLLearnMoreCard: FC<EPLLearnMoreCardProps> = ({
  closeModal,
}: EPLLearnMoreCardProps): ReactElement => {
  const { market } = useSelector(getSessionInfo);
  const eplDiscount = market == 'CA' ? 35 : 25;

  return (
    <Card
      data-testid="default-expanded-card"
      className="learn-more-card"
      onClick={(event) => event.stopPropagation()}
    >
      <Box display="flex" flexDirection="column">
        {closeModal && (
          <Box display="flex" justifyContent="flex-end">
            <CloseButton
              className="close-button-header"
              icon={<Close className="close-icon" data-testid="coverage-card-close-btn" />}
              onClick={(event) => {
                event.stopPropagation();
                closeModal();
              }}
            />
          </Box>
        )}
        <Box pl="60px" pr="60px">
          <Typography
            mt="40px"
            variant="h5"
            fontSize="13px"
            letterSpacing="1.6px"
            textTransform="uppercase"
            sx={(theme) => ({
              color: theme.designTokens.colorPrimaryDarker,
              fontFamily: theme.typography.button.fontFamily,
            })}
          >
            24/7 VOUCH HOTLINE
          </Typography>
          <Typography mt="20px" mb="20px" variant="h3" fontWeight="600" letterSpacing="-.056px">
            Employee Problems Are The #1 Source of Startup Claims
          </Typography>
          <Box pb="20px" display="flex" alignItems="baseline" flexWrap="wrap">
            <Typography variant="body1">
              Solve employee issues before they escalate with{' '}
              <Link
                href="tel:+18444886728"
                variant="body1"
                sx={(theme) => ({
                  color: theme.designTokens.colorPrimaryDarker,
                  textDecoration: 'underline',
                })}
              >
                Vouch's Hotline
              </Link>
              , available 24/7. Get urgent support for contentious departures, claims of harassment
              or discrimination, and more.
            </Typography>
          </Box>
          <Box pb="20px">
            <Typography fontSize="16px" fontStyle="normal" fontWeight="700" lineHeight="normal">
              How it works
            </Typography>
          </Box>
          <Box display="flex">
            <TextBubble />
            <Box pb="20px" pl="10px" sx={{ flex: '1 0 0' }}>
              <Typography variant="h5">Get three free hours of legal advice</Typography>
              <Typography variant="body1">
                Vouch partners with top law firms like Gordon Rees to offer our clients valuable
                support.
              </Typography>
            </Box>
          </Box>
          <Box display="flex">
            <DiscountTag />
            <Box pb="20px" pl="10px" sx={{ flex: '1 0 0' }}>
              <Typography variant="h5">Save up to {eplDiscount}%* on potential claims</Typography>
              <Typography variant="body1">
                By using the hotline, Vouch will reduce your policy retention (out-of-pocket costs),
                saving you money, on claims.
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2">
            *Only if the retention reduction endorsement is active on your policy, where available
            and approved.
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};
export default EPLLearnMoreCard;
