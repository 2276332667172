import { quotesActions } from './quotesSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { convertQuotePricing, keyByUIToken } from 'features/helpers';
import { get } from 'lodash';
import { quoteViewed } from 'features/eventTracking/eventTracking.actions';

export const {
  clearLoadingState,
  clearQuotes,
  loadEffectiveDate,
  loadQuote,
  loadQuotePdf,
  setLoadingState,
} = quotesActions;
export const processQuote = createAsyncThunk(
  'quotes/processQuote',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (args: Record<string, any>, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const slug: string = args.slug;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const quoteData: Record<string, any> = args.quoteData;
    const quote = keyByUIToken(quoteData);
    quote.pricing = convertQuotePricing(quote.pricing);
    dispatch(
      quoteViewed({
        slug,
        quoteId: quote.id,
        quoteEffectiveDate: get(quote, ['availableEffectiveDate', 'defaultDate'], ''),
        quotePrice: get(quote, ['pricing', 'annual', 'totalPremiumCents'], ''),
      })
    );
    dispatch(loadQuote({ slug, quote, active: true }));
    return quote;
  }
);
