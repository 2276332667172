import MuiAppBar from './MuiAppBar';
import MuiAutocomplete from './MuiAutocomplete';
import MuiAvatar from './MuiAvatar';
import MuiBackdrop from './MuiBackdrop';
import MuiBreadcrumbs from './MuiBreadcrumbs';
import MuiButton from './MuiButton';
import MuiCheckbox from './MuiCheckbox';
import MuiChip from './MuiChip';
import MuiDayCalendar from './MuiDayCalendar';
import MuiDialogTitle from './MuiDialogTitle';
import MuiFilledInput from './MuiFilledInput';
import MuiFormControl from './MuiFormControl';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiFormHelperText from './MuiFormHelperText';
import MuiIcon, { MuiSvgIcon } from './MuiIcon';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiInputLabel from './MuiInputLabel';
import MuiLinearProgress from './MuiLinearProgress';
import MuiLink from './MuiLink';
import MuiLoadingButton from './MuiLoadingButton';
import MuiMenuItem from './MuiMenuItem';
import MuiPaper from './MuiPaper';
import MuiPickersCalendarHeader from './MuiPickersCalendarHeader';
import MuiPickersDay from './MuiPickersDay';
import MuiPickersLayout from './MuiPickersLayout';
import MuiPickersToolbar from './MuiPickersToolbar';
import MuiPickersYear from './MuiPickersYear';
import MuiPopover from './MuiPopover';
import MuiRadio from './MuiRadio';
import MuiRadioGroup from './MuiRadioGroup';
import MuiSelect from './MuiSelect';
import MuiSwitch from './MuiSwitch';
import MuiTab from './MuiTab';
import MuiTableCell from './MuiTableCell';
import MuiTabs from './MuiTabs';
import MuiTextField from './MuiTextField';
import MuiTypography from './MuiTypography';

const componentOverrides: Record<string, unknown> = {
  MuiAppBar,
  MuiAutocomplete,
  MuiAvatar,
  MuiBackdrop,
  MuiBreadcrumbs,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiDayCalendar,
  MuiDialogTitle,
  MuiFilledInput,
  MuiFormControl,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiIcon,
  MuiIconButton,
  MuiInputBase,
  MuiInputLabel,
  MuiLinearProgress,
  MuiLink,
  MuiLoadingButton,
  MuiMenuItem,
  MuiPaper,
  MuiPickersCalendarHeader,
  MuiPickersDay,
  MuiPickersLayout,
  MuiPickersToolbar,
  MuiPickersYear,
  MuiPopover,
  MuiRadio,
  MuiRadioGroup,
  MuiSelect,
  MuiSvgIcon,
  MuiSwitch,
  MuiTab,
  MuiTableCell,
  MuiTabs,
  MuiTextField,
  MuiTypography,
};

export default componentOverrides;
