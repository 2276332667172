import { createBreakpoints } from '@mui/system';

const BREAKPOINTS = {
  xs: 0,
  sm: 640,
  md: 900,
  lg: 1200,
  xl: 1536,
  mobile: 375,
  tablet: 640,
  desktop: 1200,
};

const breakpoints = createBreakpoints({
  keys: ['xs', 'sm', 'md', 'lg', 'xl', 'mobile', 'tablet', 'desktop'],
  values: { ...BREAKPOINTS },
});

export default breakpoints;
