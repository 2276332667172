import { FC } from 'react';
import { useUpdateEffectiveDateMutation } from 'generated/graphql';
import {
  clearLoadingState,
  loadEffectiveDate,
  setLoadingState,
} from 'features/quotes/quotes.actions';
import { get } from 'lodash';
import { emptyQuote } from 'features/quotes/quotesSlice';
import { changeEffectiveDate } from 'features/eventTracking/eventTracking.actions';
import { useDispatch, useSelector } from 'react-redux';
import { sessionApplicationId } from 'features/session/sessionSlice';
import { selectedPackage } from 'features/packages/packagesSlice';
import EffectiveDateField from 'components/atoms/form/DateField/DateField';
import { availableEffectiveDateType } from 'features/quotes/quotes.types';
import { catchError, LEVEL_CRITICAL } from 'features/errors/errors.actions';

type EffectiveDateFieldContainerProp = {
  availableEffectiveDate: availableEffectiveDateType;
};
const EffectiveDateFieldContainer: FC<EffectiveDateFieldContainerProp> = (props): JSX.Element => {
  const { availableEffectiveDate } = props;
  const dispatch = useDispatch();
  const packageSlug = useSelector(selectedPackage);
  const applicationId = useSelector(sessionApplicationId);

  const [effectiveDateMutation] = useUpdateEffectiveDateMutation({
    onError: (e) => {
      dispatch(clearLoadingState());
      dispatch(
        catchError({
          level: LEVEL_CRITICAL,
          message: `EffectiveDateFieldContainer MutationError: ${e.message}`,
        })
      );
    },
    onCompleted: (data) => {
      const newAvailableEffectiveDate = get(
        data,
        'updateEffectiveDate.availableEffectiveDate',
        emptyQuote.availableEffectiveDate
      );
      dispatch(
        loadEffectiveDate({ slug: packageSlug, availableEffectiveDate: newAvailableEffectiveDate })
      );
    },
  });
  const handleEffectDateChange = (requestedEffectiveDate: Date | null) => {
    dispatch(
      changeEffectiveDate({ requestedEffectiveDate: requestedEffectiveDate?.toISOString() })
    );
    dispatch(setLoadingState());
    effectiveDateMutation({
      variables: {
        applicationId,
        packageSlug,
        requestedEffectiveDate: requestedEffectiveDate?.toISOString(),
      },
    });
  };

  return (
    <EffectiveDateField
      availableEffectiveDate={availableEffectiveDate}
      changeHandler={handleEffectDateChange}
    />
  );
};
export default EffectiveDateFieldContainer;
