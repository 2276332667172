import { Card, CardContent } from '@vouch/ui';
import { noOp } from 'shared/utils/utility';
import CoverageCardContent from 'components/molecules/cards/CoverageCard/components/CoverageCardContent';

const EmptyCard = (props: { cardTitle: string; onRequest?: boolean }): JSX.Element => {
  return (
    <Card className="coverage-card">
      <CardContent>
        <CoverageCardContent
          missingDependencies={''}
          cardTitle={props.cardTitle}
          offered={false}
          accepted={false}
          hasActiveDiscretion={!!props.onRequest} // not available vs not included
          limits={{}}
          pricing={{ annual: { totalPremiumCents: 0 } }}
          toggleCoverage={noOp}
          groupToken=""
        />
      </CardContent>
    </Card>
  );
};
export default EmptyCard;
